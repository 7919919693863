import React from 'react';
import {
  useRouteMatch, Route, Switch, useParams,
} from 'react-router-dom';
import { CreateSiteProvider } from '../../contexts/CreateSiteContext';
import { useFetchOrg, useOrg } from '../../contexts/OrgContext';
import { SiteProvider } from '../../contexts/SiteContext';
import CreateSitePage from '../CreateSite/CreateSitePage';
import SiteRoute from '../SiteRoute';
import OrgPage from './OrgPage';

export default function OrgRoute() {
  const { path } = useRouteMatch();
  const { orgId } = useParams();
  useFetchOrg(orgId);
  const { org } = useOrg();
  return org ? (
    <Switch>
      <Route exact path={path}>
        <OrgPage />
      </Route>
      <Route exact path={`${path}/create-newsroom`}>
        <CreateSiteProvider>
          <CreateSitePage />
        </CreateSiteProvider>
      </Route>
      <Route path={`${path}/site/:siteId`}>
        <SiteProvider>
          <SiteRoute />
        </SiteProvider>
      </Route>
    </Switch>
  ) : null;
}
