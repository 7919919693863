import React from 'react';
import { Helmet } from 'react-helmet-async';
import RootRouter from './RootRouter';
import { AuthProvider } from '../contexts/AuthContext';

const DashboardApp = () => (
  <>
    <Helmet>
      <title>Newsroom</title>
    </Helmet>
    <AuthProvider>
      <RootRouter />
    </AuthProvider>
  </>
);

export default DashboardApp;
