import React from 'react';
import {
  Route, Switch, useParams, useRouteMatch,
} from 'react-router-dom';
import SitePage from './SitePage/SitePage';
import SiteMediaAssetsPage from './SiteAssetsPage/SiteAssetsPage';
import { MediaAssetsProvider } from '../contexts/MediaAssetsContext';
import { useFetchSite, useSite } from '../contexts/SiteContext';
import SitePageNavbar, { SiteRoutePages } from './SitePage/SitePageNavbar/SitePageNavbar';
import SiteCoveragePage from './SiteCoveragePage/SiteCoveragePage';
import { CoverageProvider } from '../contexts/CoverageContext';
import { BlogProvider } from '../contexts/BlogContext';
import SiteBlogPage from './SiteBlogPage/SiteBlogPage';
import SitePostPage from './SitePostPage.js/SitePostPage';
import { PostProvider } from '../contexts/PostContext';
import { EditSiteProvider } from '../contexts/EditSiteContext';
import EditSitePage from './EditSite/EditSitePage';
import { useOrg } from '../contexts/OrgContext';

export default function SiteRoute() {
  const { siteId } = useParams();
  useFetchSite(siteId);

  const { path, url } = useRouteMatch();
  const { site } = useSite();
  const { org } = useOrg();

  return site ? (
    <Switch>
      <Route exact path={`${path}/`}>
        <SitePageNavbar crumbs={[{ name: org.name, path: `/organization/${org.id}` }, { name: site.meta.title }]} page={SiteRoutePages.SITE} />
        <SitePage />
      </Route>
      <Route exact path={`${path}/media-assets`}>
        <MediaAssetsProvider>
          <SitePageNavbar
            crumbs={[
              { name: org.name, path: `/organization/${org.id}` },
              { name: site.meta.title, path: `${url}` },
              { name: 'Media Assets' },
            ]}
            page={SiteRoutePages.MEDIA_ASSETS}
          />
          <SiteMediaAssetsPage />
        </MediaAssetsProvider>
      </Route>
      <Route exact path={`${path}/media-assets/:assetId`}>
        <MediaAssetsProvider>
          <SiteMediaAssetsPage />
        </MediaAssetsProvider>
      </Route>
      <Route exact path={`${path}/clippings`}>
        <CoverageProvider>
          <SitePageNavbar
            crumbs={[
              { name: org.name, path: `/organization/${org.id}` },
              { name: site.meta.title, path: `${url}` },
              { name: 'Coverage' },
            ]}
            page={SiteRoutePages.COVERAGE}
          />
          <SiteCoveragePage />
        </CoverageProvider>
      </Route>
      <Route exact path={`${path}/press-releases`}>
        <BlogProvider>
          <SitePageNavbar
            crumbs={[
              { name: org.name, path: `/organization/${org.id}` },
              { name: site.meta.title, path: `${url}` },
              { name: 'Press Releases' },
            ]}
            page={SiteRoutePages.BLOG}
          />
          <SiteBlogPage />
        </BlogProvider>
      </Route>
      <Route exact path={`${path}/press-releases/:postId`}>
        <PostProvider>
          <SitePostPage />
        </PostProvider>
      </Route>
      <Route exact path={`${path}/edit`}>
        <EditSiteProvider>
          <EditSitePage />
        </EditSiteProvider>
      </Route>
    </Switch>
  ) : null;
}
