import React from 'react';
import PropTypes from 'prop-types';
import DefaultButton from '../../../DefaultButton';
import EditSiteEditorFormUISubtitle from './EditSiteEditorFormUI/EditSiteEditorFormUISubtitle';

const EditSiteEditorFormGroup = ({
  title, children, addButtonText, onAddClick,
}) => (
  <>
    <EditSiteEditorFormUISubtitle>{title}</EditSiteEditorFormUISubtitle>
    {children}

    {(addButtonText || onAddClick) && (
    <div className="mt-3">
      <DefaultButton onClick={onAddClick} variant="light" block>{addButtonText}</DefaultButton>
    </div>
    )}
  </>
);

EditSiteEditorFormGroup.defaultProps = {
  title: '',
  children: null,
  addButtonText: null,
  onAddClick: null,
};

EditSiteEditorFormGroup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  addButtonText: PropTypes.string,
  onAddClick: PropTypes.func,
};

export default EditSiteEditorFormGroup;
