import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { clippingsGetClippingsAPI } from '../../api/clippings';
import { useTemplate, useGetTemplateTheme } from '../../contexts/TemplateContext';
import { refsGetSiteRefByPath } from '../../api/refs';
import { getI18N } from '../../config/i18n';

export default function TemplateCoveragesPreview({ language }) {
  const i18n = getI18N(language);
  const { primaryColor } = useGetTemplateTheme();
  const [clippings, setClippings] = useState([]);
  const {
    template: {
      userId, orgId, siteId, coveragePageEnabled,
    },
  } = useTemplate();
  useEffect(() => {
    const siteRef = refsGetSiteRefByPath(userId, orgId, siteId);
    async function fetch() {
      if (coveragePageEnabled) {
        setClippings(await clippingsGetClippingsAPI(siteRef, 6));
      }
    }
    fetch();
  }, [coveragePageEnabled, userId, orgId, siteId]);

  return coveragePageEnabled && (
    <div className="py-20 bg-gray-50 radius-for-skewed">
      <div className="max-w-7xl mx-auto px-4">
        <div className="mb-16 flex flex-wrap justify-center md:justify-between items-center">
          <div className="text-center lg:text-left">
            <span style={{ color: primaryColor }} className="font-bold">{i18n.mostRecentNewsMentions}</span>
            <h2 className="text-4xl lg:text-5xl font-bold font-heading">
              {i18n.newsClippings}
            </h2>
          </div>
          <Link
            to={`${language}/clippings`}
            style={{ 'background-color': primaryColor }}
            className="hidden md:inline-block py-2 px-6 rounded-l-xl rounded-t-xl text-gray-100 font-bold leading-loose transition duration-200"
          >
            {i18n.viewMoreClippings}
          </Link>
        </div>
        <div className="flex flex-wrap -mx-4 mb-4">
          {clippings.map((clipping) => (
            <div className="mb-8 w-full md:w-1/2 lg:w-1/3 px-4">
              <div className="bg-white rounded">
                <img className="rounded-t mx-auto object-cover h-64" src={clipping.photo} alt="" />
                <div className="p-6">
                  <span className="text-gray-400">{clipping.date && moment(clipping.date).format('YYYY')}</span>
                  <h3 className="mb-4 text-2xl font-bold font-heading">{clipping.title}</h3>
                  <Link
                    style={{ color: primaryColor }}
                    className="flex font-bold"
                    to={{ pathname: clipping.externalURL }}
                    target="_blank"
                  >
                    <svg style={{ color: primaryColor }} className="mr-3 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
                    </svg>
                    <span>
                      {i18n.viewClipping}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

TemplateCoveragesPreview.defaultProps = {
  language: 'en',
};

TemplateCoveragesPreview.propTypes = {
  language: PropTypes.string,
};
