import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import PublishedNotFound from './PublishedNotFound';

import { usePublishedSite, useFetchSiteFromDomain } from '../../contexts/Published/PublishedSiteContext';
import PublishedRoute from './PublishedRoute';
import { useUpdateLang } from '../../contexts/LanguageContext';

const PublishedSiteApp = ({ subdomain, language }) => {
  const pageFound = useFetchSiteFromDomain(subdomain, language);
  const { site } = usePublishedSite();
  useUpdateLang(language);
  if (!pageFound) {
    return <PublishedNotFound />;
  }
  if (!site) {
    return null;
  }
  const { favicon } = site.meta;
  return (
    <>
      <Helmet>
        {favicon && <link rel="icon" href={favicon} />}
        <title>{site.meta.title}</title>
      </Helmet>
      <PublishedRoute site={site} />
    </>
  );
};

PublishedSiteApp.propTypes = {
  subdomain: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

export default PublishedSiteApp;
