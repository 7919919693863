import React, { useEffect } from 'react';
import {
  Route, Switch, Redirect, useLocation,
} from 'react-router-dom';
// import AuthPage from './AuthPage';
import MainApp from './MainApp';
import { useAuth } from '../contexts/AuthContext';
import { auth, presshuntAuth } from '../api/firebase';
// eslint-disable-next-line import/no-named-default
import { default as firebaseAuth } from '../hooks/useAuth';
import { refsGetUserRef } from '../api/refs';

export default function RootRouter() {
  const {
    user, setUser, setUserRef, checked, setChecked,
  } = useAuth();
  const { defaultLogout } = firebaseAuth();
  useEffect(() => {
    const unsubscribe = presshuntAuth.onAuthStateChanged(async (tmpUser) => {
      if (tmpUser) {
        await auth.signInAnonymously();
      } else {
        await auth.signOut();
      }
      setUser(tmpUser);
      setUserRef(refsGetUserRef(tmpUser?.uid));
      setChecked(true);
    });
    return unsubscribe;
  }, [setUser, setChecked, setUserRef]);
  const isAuthenticated = !!user;
  const { pathname } = useLocation();
  useEffect(() => {
    if (!isAuthenticated) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      if (token) {
        presshuntAuth
          .signInWithCustomToken(token)
          .then((user) => {
            window.location.href = '/';
          })
          .catch((error) => {
            window.location.href = '/';
          });
      } else {
        window.location.href = (`https://presshunt.co/api/exchangeAuthToken?redirectUrl=${window.location.href}`);
      }
    }
  }, [isAuthenticated]);

  return checked && (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route
        path="/logout"
        render={(p) => {
          if (isAuthenticated) {
            defaultLogout();
          }
        }}
      />
      <Route
        path="/login"
        // path="/(login|signup)"
        exact
        render={({ location }) => {
          if (isAuthenticated) {
            return (
              <Redirect
                to={
                    (location.state && location.state.nextPathName)
                      ? (location.state.nextPathName + location.state.nextSearch) : '/'
                  }
              />
            );
          }
          // return <AuthPage />;
        }}
      />
      <Route
        path="/"
        render={(p) => {
          if (isAuthenticated) {
            return (<MainApp />);
          }
          // return (<Redirect to={{ pathname: '/login', state: { nextPathName: p.location.pathname, nextSearch: p.location.search } }} />);
        }}
      />
    </Switch>
  );
}
