import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import SitePageTableSectionFormContainer from './SitePageTableSectionFormContainer';
import { sitesArchiveSiteSiteAPI } from '../../../../api/sites';
import { domainsUnpublishSiteAPI } from '../../../../api/domains';
import { useSite } from '../../../../contexts/SiteContext';
import { useOrg } from '../../../../contexts/OrgContext';

export default function SitePageTableSectionFormDeleteSite({ language }) {
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { siteRef } = useSite();
  const { org } = useOrg();
  const handleFormSubmit = async (values) => {
    const { confirmDeletion } = values;
    if (!confirmDeletion) {
      return;
    }
    try {
      setLoading(true);
      await sitesArchiveSiteSiteAPI(siteRef);
      await domainsUnpublishSiteAPI(org.domain, language);
      setErrMsg('');
      setLoading(false);
      history.push(`/organization/${org.id}`);
    } catch (e) {
      setErrMsg(e.message);
      setLoading(false);
    }
  };
  const {
    handleSubmit, register, watch,
  } = useForm();
  return (
    <SitePageTableSectionFormContainer
      onSubmit={handleSubmit(handleFormSubmit)}
      submitButtonText="Delete entire site"
      errMsg={errMsg}
      submitButtonVariant="danger"
      submitButtonDisabled={!watch('confirmDeletion') || loading}
      submitButtonLoading={loading}
    >
      <div className="grid grid-cols-10 pr-4 items-center">
        <label htmlFor="username" className="block text-sm font-medium text-gray-700 col-span-2">
          Delete
        </label>
        <div className="mt-1 col-span-3">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                name="confirmDeletion"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                ref={register()}
              />
            </div>
            <div className="ml-3 text-sm">
              <label className="font-medium text-gray-700">Confirm Deletion</label>
            </div>
          </div>
        </div>
        <div className="col-span-5 pl-4 flex items-center">
          <p className="text-gray-500 leading-5 text-sm font-light">By clicking the delete button below, your site will immediately become offline. The current version and any backups will be deleted.This can not be undone.</p>
        </div>
      </div>
    </SitePageTableSectionFormContainer>
  );
}

SitePageTableSectionFormDeleteSite.propTypes = {
  language: PropTypes.string.isRequired,
};
