import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProfilePicInput from '../../../../../ProfilePicInput';
import EditSiteEditorFormUIProfileDatePicker from './EditSiteEditorFormUIProfileDatePicker';

const profilePicDefaultClasses = 'col-span-1 rounded-none bg-gray-50 border border-r-0 border-gray-300 flex items-center justify-center';
const textInputDefaultClasses = 'focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300';
const textAreaDefaultClasses = 'form-input max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 resize-none';
const dateInputDefaultClasses = 'focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300';
const selectDefaultClasses = 'w-full bg-white border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 text-xs';

export default function EditSiteEditorFormUIProfile({
  fields, photo, onImageLoaded, onInputChange, onRemove, photoEnabled,
}) {
  return (
    <fieldset className="mt-2">
      <div className="mt-1 grid grid-cols-7">
        {photoEnabled && (
        <div className={classNames(profilePicDefaultClasses, photoEnabled && 'rounded-l-md')}>
          <ProfilePicInput
            onSelectedLoaded={onImageLoaded}
            controlled
            image={photo}
          />
        </div>
        )}
        <div className={classNames('flex', photoEnabled ? 'col-span-6' : 'col-span-7')}>
          <div className="bg-white shadow-sm -space-y-px flex-grow">
            {fields.map((field, idx) => {
              const shouldRoundLeftTop = !photoEnabled && idx === 0;
              const shouldRoundLeftBottom = !photoEnabled && idx === fields.length - 1;
              const roundClasses = classNames({
                'rounded-tl-md': shouldRoundLeftTop,
                'rounded-bl-md': shouldRoundLeftBottom,
              });
              switch (field.type) {
                case 'text':
                  return (
                    <input
                      key={field.name}
                      onChange={onInputChange(field.name)}
                      value={field.value || ''}
                      type="text"
                      className={classNames(textInputDefaultClasses, roundClasses)}
                      placeholder={field.placeholder}
                    />
                  );
                case 'textarea':
                  return (
                    <textarea
                      key={field.name}
                      onChange={onInputChange(field.name)}
                      value={field.value}
                      placeholder={field.placeholder}
                      rows="2"
                      className={classNames(textAreaDefaultClasses, roundClasses)}
                    />
                  );
                case 'date':
                  return (
                    <EditSiteEditorFormUIProfileDatePicker
                      key={field.name}
                      onChange={onInputChange(field.name)}
                      value={field.value}
                      placeholder={field.placeholder}
                      className={classNames(dateInputDefaultClasses, roundClasses)}
                    />
                  );
                case 'select':
                  return (
                    <select
                      key={field.name}
                      className={classNames(selectDefaultClasses, roundClasses)}
                      value={field.value}
                      onChange={onInputChange(field.name)}
                    >
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>{option.name}</option>
                      ))}
                    </select>
                  );
                default:
                  return null;
              }
            })}
          </div>
          <button onClick={onRemove} type="button" className="-ml-px relative inline-flex items-center space-x-2 px-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
        </div>
      </div>
    </fieldset>
  );
}

EditSiteEditorFormUIProfile.defaultProps = {
  fields: [],
  photo: '',
  onImageLoaded: null,
  onRemove: null,
  photoEnabled: true,
};

EditSiteEditorFormUIProfile.propTypes = {
  fields: PropTypes.array,
  photo: PropTypes.string,
  onImageLoaded: PropTypes.func,
  onInputChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  photoEnabled: PropTypes.bool,
};
