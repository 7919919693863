import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainNavbar from './MainNavbar';

export default function () {
  return (
    <Switch>
      <Route exact path="/organization/:orgId/site/:siteId/edit" />
      <Route exact path="/organization/:orgId/create-newsroom" />
      <Route
        exact
        path="/"
      >
        <MainNavbar />
      </Route>
      <Route path="/site/:siteId">
        <MainNavbar />
      </Route>
      <Route path="/organization/:orgId">
        <MainNavbar />
      </Route>
    </Switch>
  );
}
