import React from 'react';

export default function OrgCreateNewSiteButton() {
  return (
    <button
      type="button"
      className="relative block h-full w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-24 w-24 text-gray-400" fill="none" viewBox="0 0 48 48" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M24 12v12m0 0v12m0-12h12m-12 0H12" />
      </svg>
      <span className="mt-2 block text-sm font-medium text-gray-900">Add a new language</span>
    </button>
  );
}
