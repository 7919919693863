import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getLanguageByValue } from '../../config/language';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';

export default function TemplateNavbarLanguageSelector({ value, onChange, languageOptions }) {
  const { primaryColor } = useGetTemplateTheme();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const siteLanguage = getLanguageByValue(value);

  const handleOnClick = (langaugeValue) => {
    if (langaugeValue === value) {
      return;
    }
    setDropdownOpen(false);
    onChange(langaugeValue);
  };

  return (
    <div className="flex justify-center">
      <div className="relative">
        <button
          style={{ 'background-color': primaryColor }}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          {siteLanguage !== undefined && <p style={{ height: '24px', width: '24px' }}>{siteLanguage.value.toUpperCase()}</p>}
        </button>

        <div className={`${dropdownOpen ? '' : 'hidden'} absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20`}>
          {languageOptions.map((language) => (
            <Link
              onClick={() => handleOnClick(language.value)}
              className={`${value !== language.value ? 'hover:bg-blue-500 hover:text-white' : ''} block px-4 py-2 text-sm capitalize text-gray-700`}
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <p className="px-2">{language.value.toUpperCase()}</p>
                </div>
                {(value === language.value) && (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

TemplateNavbarLanguageSelector.defaultProps = {
  value: 'en',
};

TemplateNavbarLanguageSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  languageOptions: PropTypes.array.isRequired,
};
