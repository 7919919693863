import React from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import SitePageTableSection from './SitePageTableSection/SitePageTableSection';
import SitePageStatCell from './SitePageStatCell';
import SitePageTableSectionRow from './SitePageTableSection/SitePageTableSectionRow';
import SitePageTableSectionFormDeleteSite from './SitePageTableSection/SitePageTableSectionForm/SitePageTableSectionFormDeleteSite';
import SitePagePreview from './SitePagePreview';
import SitePageLanguage from './SitePageLanguage';

import { useSite } from '../../contexts/SiteContext';

export const siteAdditionalPages = {
  pressReleases: {
    code: 'pressReleases',
    url: '/press-releases',
    name: 'Press Releases',
  },
  coverage: {
    code: 'coverage',
    url: '/clippings',
    name: 'Coverage',
  },
  mediaAssets: {
    code: 'mediaAssets',
    url: '/media-assets',
    name: 'Media Assets',
  },
};

export default function SitePage() {
  const { site } = useSite();
  const history = useHistory();
  const { pathname } = useLocation();
  return (
    <div className="px-2 sm:px-6 lg:px-8">
      <div className="grid gap-5 grid-cols-5">
        <div className="col-span-3">
          <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <SitePageStatCell title="Status" content={site.published ? 'Published' : 'Not published'} />
            <SitePageStatCell title="Created" content={moment(site.creationTime).format('MM/DD/YY h:mm a')} />
            {site.lastPublished && <SitePageStatCell title="Last Published" content={moment(site.lastPublished).format('MM/DD/YY h:mm a')} />}
          </div>

          <SitePageTableSection title="Settings">
            <div className="w-full flex justify-center bg-gray-50">
              <div className="w-full max-w-screen-xl px-3 xl:px-0 py-6">
                <div className="grid grid-cols-7 gap-4">
                  <h6 className="ml-6 mt-2 font-medium">Language</h6>
                  <SitePageLanguage defaultValue={site.language} />
                </div>
              </div>
            </div>

            {/* <SitePageTableSectionRow title="Domain" content={`${site.domain}.presshuntnewsroom.com`}>
              <SitePageTableSectionFormDomain site={site} />
            </SitePageTableSectionRow> */}
          </SitePageTableSection>

          <SitePageTableSection title="Additional Pages">
            <SitePageTableSectionRow title="Media Assets" onClick={() => history.push(`${pathname}/media-assets`)} />
            <SitePageTableSectionRow title="Coverage" onClick={() => history.push(`${pathname}/clippings`)} />
            <SitePageTableSectionRow title="Press Releases" onClick={() => history.push(`${pathname}/press-releases`)} />
          </SitePageTableSection>

          <SitePageTableSection title="Danger Zone" titleColor="text-red-600">
            <SitePageTableSectionRow title="Delete Newsroom">
              <SitePageTableSectionFormDeleteSite siteId={site.id} language={site.language} />
            </SitePageTableSectionRow>
          </SitePageTableSection>
        </div>
        <div className="col-span-2">
          <SitePagePreview site={site} />
        </div>
      </div>
    </div>
  );
}
