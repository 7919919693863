import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { remoteConfig } from '../api/firebase';

const ConfigContext = React.createContext({
  config: null,
  setConfig() {},
});

export function useConfig() {
  return useContext(ConfigContext);
}

function getJson(key) {
  try {
    return JSON.parse(remoteConfig.getValue(key).asString());
  } catch {
    return null;
  }
}

export function useFetchConfig() {
  const { setConfig } = useConfig();
  useEffect(() => {
    async function fetch() {
      await remoteConfig.fetchAndActivate();
      const config = {
        hosts: getJson('hosts'),
        reservedDomains: new Set(getJson('reservedDomains')),
      };
      setConfig(config);
    }
    fetch();
  }, [setConfig]);
}

export function ConfigProvider({ children }) {
  const [config, setConfig] = useState();
  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  );
}

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
