import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { sitesEnableAdditionalPageAPI } from '../api/sites';
import { useSite, useUpdateSitePageWithKeyValue } from '../contexts/SiteContext';
import DefaultToggle from './DefaultToggle';
import { NAVBAR } from '../utils/templateTypes';

export default function SiteAdditionalPagesEnableToggle({ page, label }) {
  const { site, siteRef } = useSite();
  const [loading, setLoading] = useState(false);
  const updateSitePageWithKeyValue = useUpdateSitePageWithKeyValue();
  async function handleToggleChange(enabled) {
    setLoading(true);
    const navbarSection = site.pages.home.sections.find((section) => section.type === NAVBAR);
    const navbarSectionId = navbarSection && navbarSection.id;
    const { config: { links } } = navbarSection;
    const pageExistInNavbar = !!links.find((link) => link.url === page.url);
    let newLinks = [...links];
    if (enabled && !pageExistInNavbar) {
      newLinks = [...newLinks, {
        id: uuidv4(),
        title: page.name,
        url: page.url,
      }];
    } else if (!enabled && pageExistInNavbar) {
      newLinks = newLinks.filter((link) => link.url !== page.url);
    }
    updateSitePageWithKeyValue(page.code, 'enabled', enabled);
    await sitesEnableAdditionalPageAPI(
      siteRef,
      page.code,
      enabled,
      navbarSectionId,
      newLinks,
    );
    setLoading(false);
  }
  const checked = (site && site.pages && site.pages[page.code] && site.pages[page.code].enabled) || false;
  return (
    <DefaultToggle
      checked={checked}
      disabled={loading}
      onChange={handleToggleChange}
      label={label}
    />
  );
}

SiteAdditionalPagesEnableToggle.propTypes = {
  page: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};
