import React from 'react';
import PropTypes from 'prop-types';

export default function SitePostPageCell({ children }) {
  return (
    <div className="bg-white p-6 border rounded-lg shadow-sm border-gray-100">
      {children}
    </div>
  );
}

SitePostPageCell.propTypes = {
  children: PropTypes.any.isRequired,
};
