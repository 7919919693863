import React from 'react';
import PropTypes from 'prop-types';
// import { IFrame } from './IFrame';
import Preview from './Preview';

const PreviewFrame = ({
  site, scale, scrolling, mode,
}) => (
  <div style={{
    transform: `scale(${scale})`,
    width: `${100 / scale}%`,
    height: `${100 / scale}%`,
    transformOrigin: '0 0',
    overflowY: scrolling ? 'scroll' : 'hidden',
  }}
  >
    <Preview mode={mode} site={site} />
  </div>
);

PreviewFrame.defaultProps = {
  scale: 1,
  scrolling: true,
  site: null,
};

PreviewFrame.propTypes = {
  site: PropTypes.object,
  scale: PropTypes.number,
  scrolling: PropTypes.bool,
  mode: PropTypes.oneOf(['static', 'edit', 'create']).isRequired, // static, edit, create
};

export default PreviewFrame;
