import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { clippingsGetClippingsAPI } from '../../api/clippings';
import { usePublishedSite } from '../../contexts/Published/PublishedSiteContext';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';
import { siteAdditionalPages } from '../SitePage/SitePage';
import PublishedNotFound from './PublishedNotFound';
import Template from '../Template/Template';
import { refsGetSiteRefByPath } from '../../api/refs';
import { getI18N } from '../../config/i18n';
import { useLanguage } from '../../contexts/LanguageContext';
import PublishedCoveragePageTagSelector from './PublishedCoveragePageTagSelector';
import { tagsGetTagsAPI } from '../../api/clipping-tags';
import PublishedCoveragePageLinkModal from './PublishedCoveragePageLinkModal';

export default function PublishedCoveragePage() {
  const { primaryColor } = useGetTemplateTheme();
  const { site } = usePublishedSite();
  const { ownerId: uid, orgId: oid, siteId: sid } = site;
  const { language } = useLanguage();
  const i18n = getI18N(language);
  const [clippings, setClippings] = useState([]);
  const [tagFilter, setTagFilter] = useState('');
  const [tags, setTags] = useState([]);
  const [clippingItems, setClippingItems] = useState([]);
  const [clickedClippings, setClickedClippings] = useState({ externalURL: '' });
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

  function handleOnClickClippingLink(clipping) {
    setClickedClippings(clipping);
    setIsLinkModalOpen(true);
  }

  function filterPostsByTag(items, filterTag) {
    const filterClippingItems = items.filter((item) => {
      if (!item.tags || item.tags.length === 0) {
        return false;
      }
      let matchTag = false;
      item.tags.map((tag) => {
        if (tag.text === filterTag) {
          matchTag = true;
        }
        return tag;
      });
      return matchTag;
    });
    setClippingItems(filterClippingItems);
  }

  useEffect(() => {
    setClippingItems(clippings);
  }, [clippings]);

  useEffect(() => {
    const siteRef = refsGetSiteRefByPath(uid, oid, sid);
    async function fetch() {
      const tmpClippings = await clippingsGetClippingsAPI(siteRef);
      setClippings(tmpClippings);
    }
    fetch();
  }, [uid, oid, sid, setClippings]);

  useEffect(() => {
    const getTags = async () => {
      const allTags = await tagsGetTagsAPI(uid, oid, sid);
      setTags([{ id: '', text: 'All' }, ...allTags]);
    };
    getTags();
  }, [uid, sid, oid]);

  useEffect(() => {
    if (tagFilter) {
      filterPostsByTag(clippings, tagFilter);
    } else {
      setClippingItems(clippings);
    }
  }, [tagFilter]);

  const pageName = siteAdditionalPages.coverage.code;
  if (!(site.pages[pageName] && site.pages[pageName].enabled)) {
    return <PublishedNotFound />;
  }
  return (
    <Template>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="max-w-7xl mx-auto px-4">
          <div className="mb-16 flex flex-wrap justify-center md:justify-between items-center">
            <div className="text-center lg:text-left">
              <h2 className="text-3xl lg:text-5xl font-bold font-heading">{i18n.newsClippings}</h2>
            </div>
            <PublishedCoveragePageTagSelector onChange={setTagFilter} tagOptions={tags} />
          </div>
          <div className="flex flex-wrap -mx-4 mb-4">
            {clippingItems.map((clipping) => (
              <div className="mb-8 w-full md:w-1/2 lg:w-1/3 px-4">
                <div className="bg-white rounded">
                  <img className="rounded-t object-cover h-64 mx-auto" src={clipping.photo} alt="" />
                  <div className="p-6">
                    <span className="text-gray-400">{clipping.date && moment(clipping.date).format('MMMM Do, YYYY')}</span>
                    <h3 className="mb-4 text-2xl font-bold font-heading">{clipping.title}</h3>
                    <span
                      style={{ color: primaryColor }}
                      className="flex font-bold cursor-pointer"
                      onClick={() => handleOnClickClippingLink(clipping)}
                    >
                      <svg style={{ color: primaryColor }} className="mr-3 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
                      </svg>
                      <span>
                        {i18n.viewClipping}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <PublishedCoveragePageLinkModal
        clipping={clickedClippings}
        isOpen={isLinkModalOpen}
        closeModal={() => setIsLinkModalOpen(false)}
      />
    </Template>
  );
}
