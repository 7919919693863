import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function OrgCard({ org }) {
  return (
    <Link to={`/organization/${org.id}`}>
      <li className="col-span-1 flex shadow-sm rounded-md border border-gray-300 hover:border-gray-500 px-4 py-2 cursor-pointer">
        <h6 className="text-gray-900 font-medium">
          {org.name}
        </h6>
      </li>
    </Link>
  );
}

OrgCard.propTypes = {
  org: PropTypes.object.isRequired,
};
