import React from 'react';
import PropTypes from 'prop-types';
import TemplateContactSpokespeople from './TemplateContactSpokespeople';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';
import TemplateAboutSocialButton from './TemplateAboutSocialButton';
import { getI18N } from '../../config/i18n';

function sortMediaWebsiteToFirst(a) {
  if (a.media === 'officialWebsite') {
    return -1;
  }
  return 0;
}

export default function TemplateContact({ config, language }) {
  const i18n = getI18N(language);
  const socialMedias = config.socialMedias ? config.socialMedias.sort(sortMediaWebsiteToFirst) : [];
  const websiteMedias = (socialMedias.length > 0 && socialMedias[0].media === 'officialWebsite') ? [socialMedias[0]] : [];
  const { primaryColor } = useGetTemplateTheme();
  return (
    <div className="relative bg-white" id="contact">
      {config.spokespeople && config.spokespeople.length > 0 && <TemplateContactSpokespeople spokespeople={config.spokespeople} />}
      <div className="relative max-w-7xl mx-auto">
        <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg text-left">
            <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
              {i18n.getInTouch}
            </h2>
          </div>
          <div className="mt-8 max-w-7xl text-left md:flex">
            <div style={{ flex: '1' }} className="about-section">
              <p className="mt-3 text-lg leading-6 text-gray-500">
                {config && config.description}
              </p>
              <dl className="mt-8 text-base leading-6 text-gray-500">
                {config && config.address && (
                <div>
                  <dd>
                    <p>{config.address.street}</p>
                    <p>
                      {config.address.city}
                      {(config.address.city !== '' && config.address.state !== '') && ','}
                      {' '}
                      {config.address.state}
                      {' '}
                      {config.address.zip}
                    </p>
                  </dd>
                </div>
                )}
                {config && config.phone && (
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    <span className="ml-3">
                      {config.phone}
                    </span>
                  </dd>
                </div>
                )}
                {config.email && (
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <span className="ml-3">
                      {config.email}
                    </span>
                  </dd>
                </div>
                )}
              </dl>
            </div>
            {socialMedias.length > 0 && (
            <div className="mb-10 md:max-w-xs sm:max-w-full lg:ml-4">
              {websiteMedias.length > 0 && (
                <ul className="mb-8">
                  {websiteMedias.map((websiteMedia) => (
                    <li>
                      <div className="mx-2" key={websiteMedia.id}>
                        <TemplateAboutSocialButton
                          color={primaryColor}
                          media={websiteMedia.media}
                          url={websiteMedia.url}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              <ul>
                {socialMedias.map((socialMedia, i) => {
                  if (socialMedia.media === 'officialWebsite') {
                    return null;
                  }
                  return (
                    <li>
                      <div className="mx-2" key={socialMedia.id}>
                        <TemplateAboutSocialButton
                          color={primaryColor}
                          media={socialMedia.media}
                          url={socialMedia.url}
                          noBottomBorder={i !== socialMedias.length - 1}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

TemplateContact.defaultProps = {
  config: null,
  language: 'en',
};

TemplateContact.propTypes = {
  config: PropTypes.object,
  language: PropTypes.string,
};
