import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TemplateCTA from './TemplateCTA';
import TemplateNavbar from './TemplateNavbar';
import TemplateAbout from './TemplateAbout';
import TemplateFeature from './TemplateFeature';
import TemplateTestimonials from './TemplateTestimonials';
import TemplateTeam from './TemplateTeam';
import TemplateFAQ from './TemplateFAQ';
import TemplateContact from './TemplateContact';
import TemplateGallery from './TemplateGallery';
import TemplateEmailCapture from './TemplateEmailCapture';
import TemplateFooter from './TemplateFooter';
import {
  CONTACT, ABOUT, NAVBAR, TESTIMONIALS, CTA, FEATURE, TEAM, FAQ, GALLERY, EMAIL_CAPTURE, FOOTER, COVERAGES_PREVIEW, PRESS_RELEASES_PREVIEW,
} from '../../utils/templateTypes';
import { useTemplate } from '../../contexts/TemplateContext';
import TemplateCoveragesPreview from './TemplateCoveragesPreview';
import TemplateBlogPreview from './TemplateBlogPreview';

export default function TemplateItem({ sectionId, disabled }) {
  const {
    template: {
      sectionsDict, meta, language, contactSectionId,
    },
  } = useTemplate();
  const [section, setSection] = useState({});
  const tmpSection = sectionsDict[sectionId];
  useEffect(() => {
    setSection(tmpSection);
  }, [tmpSection]);
  let socialMedias = [];
  if (contactSectionId && sectionsDict[contactSectionId] && sectionsDict[contactSectionId].config && sectionsDict[contactSectionId].config.socialMedias) {
    socialMedias = sectionsDict[contactSectionId].config.socialMedias;
  }
  const showContactLink = contactSectionId !== undefined;
  switch (section.type) {
    case NAVBAR:
      return <TemplateNavbar config={section.config} disabled={disabled} socialMedias={socialMedias} showContactLink={showContactLink} language={language} meta={meta} />;
    case CTA:
      return <TemplateCTA config={section.config} />;
    case ABOUT:
      return <TemplateAbout config={section.config} />;
    case PRESS_RELEASES_PREVIEW:
      return <TemplateBlogPreview language={language} disabled={disabled} />;
    case FEATURE:
      return <TemplateFeature config={section.config} />;
    case TESTIMONIALS:
      return <TemplateTestimonials config={section.config} />;
    case TEAM:
      return <TemplateTeam config={section.config} language={language} />;
    case FAQ:
      return <TemplateFAQ config={section.config} />;
    case CONTACT:
      return <TemplateContact config={section.config} language={language} />;
    case COVERAGES_PREVIEW:
      return <TemplateCoveragesPreview language={language} />;
    case GALLERY:
      return <TemplateGallery config={section.config} />;
    case EMAIL_CAPTURE:
      return <TemplateEmailCapture config={section.config} />;
    case FOOTER:
      return <TemplateFooter config={section.config} socialMedias={socialMedias} meta={meta} disabled={disabled} language={language} />;
    default:
      return null;
  }
}

TemplateItem.defaultProps = {
  disabled: false,
};

TemplateItem.propTypes = {
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
