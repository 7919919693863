import React from 'react';
import PropTypes from 'prop-types';
import CreateSitePreview from './CreateSite/CreateSitePreview';
import EditSitePreview from './EditSite/EditSitePreview';
import StaticSitePreview from './StaticSitePreview';
import { TemplateProvider } from '../contexts/TemplateContext';

function getPreview(mode, site) {
  switch (mode) {
    case 'create':
      return <CreateSitePreview />;
    case 'edit':
      return <EditSitePreview />;
    case 'static':
      return <StaticSitePreview site={site} />;
    default:
      return null;
  }
}

export default function Preview({ mode, site }) {
  const preview = getPreview(mode, site);
  return (
    <TemplateProvider>
      {preview}
    </TemplateProvider>
  );
}

Preview.defaultProps = {
  site: null,
};

Preview.propTypes = {
  mode: PropTypes.string.isRequired,
  site: PropTypes.object,
};
