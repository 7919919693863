import React from 'react';
import PropTypes from 'prop-types';
import { getAllLanguage } from '../../config/language';
import { usePublishedSite } from '../../contexts/Published/PublishedSiteContext';

const languages = getAllLanguage();

export default function TemplatePageLanguage({ value, onChange }) {
  const publishedSite = usePublishedSite();
  const publishSitesValue = (publishedSite.site) ? publishedSite.site.publishedSites : [];
  const selectAbleLanguages = languages.filter((language) => Object.keys(publishSitesValue).indexOf(language.value) !== -1);

  return (
    <select
      className="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    >
      {selectAbleLanguages.map((language) => <option key={language.value} value={language.value}>{language.name}</option>)}
    </select>
  );
}

TemplatePageLanguage.defaultProps = {
  value: null,
};

TemplatePageLanguage.propTypes = {
  value: PropTypes.oneOf(languages.map((language) => language.value)),
  onChange: PropTypes.func.isRequired,
};
