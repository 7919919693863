import React from 'react';
import PropTypes from 'prop-types';
import { useAddSection } from '../../../contexts/AddSectionContext';

export default function EditSiteEditorAddSectionButton({ index }) {
  const { setIsModalOpen, setSectionIndex } = useAddSection();
  function handleAddSectionClicked() {
    setSectionIndex(index);
    setIsModalOpen(true);
  }
  return (
    <div className="py-5 flex justify-center">
      <button
        className="rounded transition ease-in-out duration-150 flex items-center p-2 text-gray-800 bg-gray-50 border border-gray-300 hover:bg-gray-100 focus:outline-none active:bg-gray-200"
        onClick={() => handleAddSectionClicked(index + 1)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
      </button>
    </div>
  );
}

EditSiteEditorAddSectionButton.propTypes = {
  index: PropTypes.number.isRequired,
};
