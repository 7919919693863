import React from 'react';

const EditSiteEditorFormUITextArea = ({ label, value, onChange }) => (
  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
    <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
      {label}
    </label>
    <div className="mt-1 sm:mt-0 sm:col-span-2">
      <textarea
        id="about"
        rows="3"
        className="form-input max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
        value={value}
        onChange={onChange}
      />
    </div>
  </div>
);

export default EditSiteEditorFormUITextArea;
