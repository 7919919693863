import React from 'react';
import CreateSiteEditorColumn from './CreateSiteEditorColumn';
import CreateSitePreviewColumn from './CreateSitePreviewColumn';

const CreateSitePage = () => (
  <div className="flex px-5 py-3 -mt-12">
    <CreateSiteEditorColumn />
    <CreateSitePreviewColumn />
  </div>
);

export default CreateSitePage;
