import React from 'react';
import PropTypes from 'prop-types';

export default function CreateSitePageLanguage({
  defaultValue, onChange, languages, leftLanguages,
}) {
  return (
    <select
      className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      onChange={({ target: { value } }) => onChange(value.toLowerCase())}
    >
      {languages.map((language) => {
        const isUsed = leftLanguages.some(
          (item) => item === language.value,
        );
        if (isUsed) {
          return <option key={language.value} value={language.value} selected={defaultValue === language.value ? 'selected' : ''}>{language.name}</option>;
        }
        return <option key={language.value} value={language.value} disabled="disabled">{language.name}</option>;
      })}
    </select>
  );
}

CreateSitePageLanguage.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  leftLanguages: PropTypes.array.isRequired,
};
