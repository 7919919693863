import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sitesGetSiteAPI } from '../api/sites';
import { useSite } from './SiteContext';

const EditSiteContext = React.createContext({
  editSite: null,
  setEditSite: () => {},
});

export function useEditSite() {
  return useContext(EditSiteContext);
}

/**
 *
 * @param {Array} sections sections array
 */
function processEditSite(sections) {
  const sectionIds = sections.map((section) => section.id);
  const sectionsDict = {};
  sections.forEach((section) => {
    sectionsDict[section.id] = section;
  });
  return {
    sectionIds,
    sectionsDict,
  };
}

/**
 *
 * @param {String} uid User ID
 * @param {String} sid Site ID
 */
export function useFetchEditSite(uid, sid) {
  const { setEditSite } = useContext(EditSiteContext);
  const { siteRef } = useSite();
  useEffect(() => {
    async function fetch() {
      const site = await sitesGetSiteAPI(siteRef);
      setEditSite({
        ...site,
        pages: {
          ...site.pages,
          home: {
            ...site.pages.home,
            sections: processEditSite(site.pages.home.sections),
          },
        },
      });
    }
    fetch();
  }, [uid, sid, setEditSite, siteRef]);
}

export function useSetEditSiteSectionProperty() {
  const { setEditSite } = useContext(EditSiteContext);
  return function setEditSiteSectionProperty(key, value, sectionId) {
    setEditSite((prevEditSite) => ({
      ...prevEditSite,
      pages: {
        ...prevEditSite.pages,
        home: {
          ...prevEditSite.pages.home,
          sections: {
            ...prevEditSite.pages.home.sections,
            sectionsDict: {
              ...prevEditSite.pages.home.sections.sectionsDict,
              [sectionId]: {
                ...prevEditSite.pages.home.sections.sectionsDict[sectionId],
                config: {
                  ...prevEditSite.pages.home.sections.sectionsDict[sectionId].config,
                  [key]: value,
                },
              },
            },
          },
        },
      },
    }));
  };
}

export function useAddEditSiteSection() {
  const { setEditSite } = useContext(EditSiteContext);
  return function addEditSiteSection(section, index) {
    setEditSite((prevEditSite) => ({
      ...prevEditSite,
      pages: {
        ...prevEditSite.pages,
        home: {
          ...prevEditSite.pages.home,
          sections: {
            ...prevEditSite.pages.home.sections,
            sectionIds: [...prevEditSite.pages.home.sections.sectionIds.slice(0, index), section.id, ...prevEditSite.pages.home.sections.sectionIds.slice(index)],
            sectionsDict: {
              ...prevEditSite.pages.home.sections.sectionsDict,
              [section.id]: section,
            },
          },
        },
      },
    }));
  };
}

export function useRemoveEditSiteSection() {
  const { setEditSite } = useContext(EditSiteContext);
  return function addEditSiteSection(sectionId) {
    setEditSite((prevEditSite) => ({
      ...prevEditSite,
      pages: {
        ...prevEditSite.pages,
        home: {
          ...prevEditSite.pages.home,
          sections: {
            ...prevEditSite.pages.home.sections,
            sectionIds: [...prevEditSite.pages.home.sections.sectionIds].filter((id) => id !== sectionId),
          },
        },
      },
    }));
  };
}

export function useSetEditSiteMetaProperty() {
  const { setEditSite } = useContext(EditSiteContext);
  return function setEditSiteMetaProperty(key, value) {
    return setEditSite((prevEditSite) => ({
      ...prevEditSite,
      meta: {
        ...prevEditSite.meta,
        [key]: value,
      },
    }));
  };
}

export function useSetEditSiteThemeProperty() {
  const { setEditSite } = useContext(EditSiteContext);
  return function setEditSiteMetaProperty(key, value) {
    return setEditSite((prevEditSite) => ({
      ...prevEditSite,
      theme: {
        ...prevEditSite.theme,
        [key]: value,
      },
    }));
  };
}

export function EditSiteProvider({ children }) {
  const [editSite, setEditSite] = useState();
  return (
    <EditSiteContext.Provider value={{ editSite, setEditSite }}>
      {children}
    </EditSiteContext.Provider>
  );
}

EditSiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
