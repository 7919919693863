import React from 'react';
import DashboardApp from './components/DashboardApp';
import PublishedSiteApp from './components/Published/PublishedSiteApp';
import { PublishedSiteProvider } from './contexts/Published/PublishedSiteContext';
import { TemplateProvider } from './contexts/TemplateContext';
import useGetDomains from './hooks/useGetDomains';
import { LanguageProvider } from './contexts/LanguageContext';

export default function App() {
  const { subdomain, checked, lang } = useGetDomains();
  if (!checked) {
    return null;
  }
  if (subdomain) {
    return (
      <LanguageProvider>
        <PublishedSiteProvider>
          <TemplateProvider>
            <PublishedSiteApp subdomain={subdomain} language={lang} />
          </TemplateProvider>
        </PublishedSiteProvider>
      </LanguageProvider>
    );
  }
  return <DashboardApp />;
}
