import React from 'react';
import PropTypes from 'prop-types';

export default function SiteBlogPageSelector({ value, onChange, tagOptions }) {
  return (
    <select
      className="flex-none block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    >
      {tagOptions.map((tag) => <option key={tag.id} value={tag.id}>{tag.text}</option>)}
    </select>
  );
}

SiteBlogPageSelector.defaultProps = {
  value: null,
  tagOptions: [],
};

SiteBlogPageSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tagOptions: PropTypes.array,
};
