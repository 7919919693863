export function getI18N(language) {
  const i18n = {
    en: {
      mediaAssets: 'Media Assets',
      newsClippings: 'News Clippings',
      pressReleases: 'Press Releases',
      contact: 'Contact',
      recentPressReleases: 'Most recent press releases',
      newsroom: 'Newsroom',
      viewMorePressReleases: 'View More Press Releases',
      getToKnowUs: 'get to know us',
      mostRecentNewsMentions: 'Most recent news mentions',
      viewMoreClippings: 'View More Clippings',
      getInTouch: 'Get in touch',
      viewClipping: 'View clipping',
      newsArchive: 'News archive',
      tags: 'Tags',
      all: 'All',
      learnMore: 'Learn More',
      home: 'Home',
      poweredBy: 'powered by',
      allRightsReserved: 'All rights reserved',
    },
    ru: {
      mediaAssets: 'Медиа Активы',
      pressReleases: 'Пресс-релизы',
      contact: 'Контакт',
      recentPressReleases: 'Последние пресс-релизы',
      newsroom: 'Новость',
      viewMorePressReleases: 'Посмотреть больше пресс-релизов',
      getToKnowUs: 'узнать нас',
      mostRecentNewsMentions: 'Последние упоминания в новостях',
      newsClippings: 'Вырезки новостей',
      viewMoreClippings: 'Посмотреть больше вырезок',
      getInTouch: 'Связаться',
      viewClipping: 'Посмотреть вырезку',
      newsArchive: 'Архив новостей',
      tags: 'Теги',
      all: 'Все',
      learnMore: 'Выучить больше',
      home: 'титульная страница',
      poweredBy: 'питаться от',
      allRightsReserved: 'Все права защищены',
    },
    ar: {
      mediaAssets: 'أصول الوسائط',
      pressReleases: 'تصريحات صحفيه',
      contact: 'اتصل',
      recentPressReleases: 'أحدث البيانات الصحفية',
      newsroom: 'غرفة الأخبار',
      viewMorePressReleases: 'عرض المزيد من البيانات الصحفية',
      getToKnowUs: 'تعرف علينا',
      mostRecentNewsMentions: 'يذكر أحدث الأخبار',
      newsClippings: 'قصاصات الأخبار',
      viewMoreClippings: 'عرض المزيد من قصاصات',
      getInTouch: 'ابقى على تواصل',
      viewClipping: 'مشاهدة لقطة',
      newsArchive: 'أرشيف الأخبار',
      tags: 'العلامات',
      all: 'الجميع',
      learnMore: 'يتعلم أكثر',
      home: 'الصفحة الأمامية',
      poweredBy: 'مشغل بواسطة',
      allRightsReserved: 'كل الحقوق محفوظة',
    },
    fr: {
      mediaAssets: 'Actifs médias',
      pressReleases: 'Communiqués de presse',
      contact: 'Contact',
      recentPressReleases: 'Communiqués de presse les plus récents',
      newsroom: 'Rédaction',
      viewMorePressReleases: 'Voir plus de communiqués de presse',
      getToKnowUs: 'apprendre a nous connaitre',
      mostRecentNewsMentions: 'Mentions de nouvelles les plus récentes',
      newsClippings: 'Coupures de presse',
      viewMoreClippings: 'Voir plus de coupures de presse',
      getInTouch: 'Entrer en contact',
      viewClipping: 'Voir le découpage',
      newsArchive: 'Nouvelles archives',
      tags: 'Mots clés',
      all: 'Tout',
      learnMore: 'Apprendre encore plus',
      home: 'page de garde',
      poweredBy: 'Alimenté par',
      allRightsReserved: 'Tous les droits sont réservés',
    },
    sw: {
      mediaAssets: 'Vipengee vya Media',
      pressReleases: 'Matoleo kwa Vyombo vya Habari',
      contact: 'Wasiliana',
      recentPressReleases: 'Matoleo ya hivi majuzi kwa vyombo vya habari',
      newsroom: 'Chumba cha habari',
      viewMorePressReleases: 'Tazama Matoleo Zaidi kwa Vyombo vya Habari',
      getToKnowUs: 'tujue',
      mostRecentNewsMentions: 'Habari za hivi punde zimetajwa',
      newsClippings: 'Vipu vya Habari',
      viewMoreClippings: 'Tazama Klipu Zaidi',
      getInTouch: 'Wasiliana',
      viewClipping: 'Tazama kunakili',
      newsArchive: 'Kumbukumbu ya habari',
      tags: 'vitambulisho',
      all: 'zote',
      learnMore: 'Jifunze zaidi',
      home: 'ukurasa wa mbele',
      poweredBy: 'kinatumia',
      allRightsReserved: 'Haki zote zimehifadhiwa',
    },
    vi: {
      mediaAssets: 'Nội dung phương tiện',
      pressReleases: 'Thông cáo báo chí',
      contact: 'Tiếp xúc',
      recentPressReleases: 'Thông cáo báo chí gần đây nhất',
      newsroom: 'Tòa soạn',
      viewMorePressReleases: 'Xem thêm thông cáo báo chí',
      getToKnowUs: 'làm để hiểu họ',
      mostRecentNewsMentions: 'Đề cập tin tức gần đây nhất',
      newsClippings: 'Đoạn tin tức',
      viewMoreClippings: 'Xem thêm clip',
      getInTouch: 'Liên lạc',
      viewClipping: 'Xem đoạn trích',
      newsArchive: 'Tin tức lưu trữ',
      tags: 'Thẻ',
      all: 'Tất cả',
      learnMore: 'Tìm hiểu thêm',
      home: 'trang đầu',
      poweredBy: 'cung cấp bởi',
      allRightsReserved: 'Đã đăng ký Bản quyền',
    },
    id: {
      mediaAssets: 'Aset Media',
      pressReleases: 'Siaran Pers',
      contact: 'Kontak',
      recentPressReleases: 'Siaran pers terbaru',
      newsroom: 'Ruang wartawan',
      viewMorePressReleases: 'Lihat Lebih Banyak Siaran Pers',
      getToKnowUs: 'kenali kami',
      mostRecentNewsMentions: 'Sebutan berita terbaru',
      newsClippings: 'Kliping Berita',
      viewMoreClippings: 'Lihat Kliping Lainnya',
      getInTouch: 'Berhubungan',
      viewClipping: 'Lihat kliping',
      newsArchive: 'Arsip Berita',
      tags: 'Tags',
      all: 'Semua',
      learnMore: 'Belajarlah lagi',
      home: 'halaman Depan',
      poweredBy: 'dipersembahkan oleh',
      allRightsReserved: 'Seluruh hak cipta',
    },
    hi: {
      mediaAssets: 'मीडिया संपत्ति',
      pressReleases: 'प्रेस प्रकाशनी',
      contact: 'संपर्क',
      recentPressReleases: 'नवीनतम प्रेस विज्ञप्ति',
      newsroom: 'न्यूज़रूम',
      viewMorePressReleases: 'और देखें प्रेस विज्ञप्ति',
      getToKnowUs: 'हम के बारे में जानें',
      mostRecentNewsMentions: 'नवीनतम समाचारों का उल्लेख',
      newsClippings: 'समाचार कतरन',
      viewMoreClippings: 'और कतरनें देखें',
      getInTouch: 'संपर्क में रहो',
      viewClipping: 'क्लिपिंग देखें',
      newsArchive: 'समाचार संग्रह',
      tags: 'टैग',
      all: 'सभी',
      learnMore: 'और अधिक जानें',
      home: 'मुखपृष्ठ',
      poweredBy: 'द्वारा संचालित',
      allRightsReserved: 'सर्वाधिकार सुरक्षित',
    },
    tr: {
      mediaAssets: 'Medya Varlıkları',
      pressReleases: 'Basın yayınları',
      contact: 'Temas',
      recentPressReleases: 'En son basın bültenleri',
      newsroom: 'haber odası',
      viewMorePressReleases: 'Daha Fazla Basın Bülteni Görüntüle',
      getToKnowUs: 'bizi tanı',
      mostRecentNewsMentions: 'En son haberlerde bahsedilenler',
      newsClippings: 'haber kupürleri',
      viewMoreClippings: 'Daha Fazla Kırpma Görüntüle',
      getInTouch: 'Temasta olmak',
      viewClipping: 'Kırpmayı görüntüle',
      newsArchive: 'Haber Arşivi',
      tags: 'Etiketler',
      all: 'Tümü',
      learnMore: 'Daha fazla bilgi edin',
      home: 'ön Sayfa',
      poweredBy: 'tarafından desteklenmektedir',
      allRightsReserved: 'Her hakkı saklıdır',
    },
  };

  return i18n[language];
}
