import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import EditSiteEditorAddSectionModalButton from './EditSiteEditorAddSectionModalButton';
import { getDefaultSections } from '../../../../contexts/CreateSiteContext';
import DefaultButton from '../../../DefaultButton';
import { sitesAddSectionAPI } from '../../../../api/sites';
import { useAddEditSiteSection } from '../../../../contexts/EditSiteContext';
import { useAddSection } from '../../../../contexts/AddSectionContext';
import { useSite } from '../../../../contexts/SiteContext';

export default function EditSiteEditorAddSectionModal() {
  const { isModalOpen, setIsModalOpen, sectionIndex } = useAddSection();
  function closeModal() {
    setIsModalOpen(false);
  }
  const [sections, setSections] = useState([]);
  const addEditSiteSection = useAddEditSiteSection();
  const { siteRef } = useSite();
  async function handleButtonClicked(section) {
    const newSection = await sitesAddSectionAPI(siteRef, section, sectionIndex);
    addEditSiteSection(newSection, sectionIndex);
    closeModal();
  }

  useEffect(() => {
    const defaultSections = getDefaultSections();
    const filteredSections = Object.keys(defaultSections)
      .map((key) => defaultSections[key])
      .filter((section) => !section.fixed && section.enabled);
    setSections(filteredSections);
  }, []);
  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={isModalOpen} onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div>
                <div className="">
                  <Dialog.Title as="h3" className="text-center text-lg leading-6 font-medium text-gray-900">
                    Add Section
                  </Dialog.Title>
                  <div className="mt-4">
                    <div className="grid-cols-4 grid gap-5">
                      {sections.map((section) => (
                        <EditSiteEditorAddSectionModalButton
                          key={section.type}
                          onClick={() => handleButtonClicked(section)}
                        >
                          {section.title}
                        </EditSiteEditorAddSectionModalButton>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 content-end w-full flex justify-end">
                <DefaultButton onClick={closeModal} variant="light" size="small">Close</DefaultButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
