import React from 'react';
import PropTypes from 'prop-types';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';
import { getI18N } from '../../config/i18n';

const TemplateTeam = ({ config, language }) => {
  const i18n = getI18N(language);
  const members = config.members || [];
  const { primaryColor } = useGetTemplateTheme();
  return (
    <div className="py-20 bg-gray-50 radius-for-skewed">
      <div className="max-w-6xl mx-auto px-4">
        <div className="mb-8 lg:mb-16 text-center mx-auto max-w-xl">
          <span style={{ color: primaryColor }} className="font-bold">
            {i18n.getToKnowUs}
          </span>
          <h2 className="text-4xl lg:text-5xl font-bold font-heading">{config.title}</h2>
        </div>
        <div className="flex flex-wrap -mx-4">
          {members.map((member) => (
            <div key={member.id} className="mb-6 w-full lg:w-1/2 px-4">
              <div className="flex flex-wrap items-center bg-white rounded shadow overflow-hidden">
                <img
                  className="w-full lg:w-1/3 h-80 object-cover"
                  src={member.photo || 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80'}
                  alt=""
                />
                <div className="w-full lg:w-2/3 lg:pl-6 p-4">
                  <h4 className="mb-2 text-2xl font-bold font-heading">{member.name}</h4>
                  <h4 style={{ color: primaryColor }} className="mb-2 text-xl font-bold font-heading">{member.title}</h4>
                  <p className="mb-4 text-gray-500 leading-loose">{member.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

TemplateTeam.defaultProps = {
  config: {},
  language: 'en',
};

TemplateTeam.propTypes = {
  config: PropTypes.object,
  language: PropTypes.string,
};

export default TemplateTeam;
