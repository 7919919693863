import React from 'react';
import DefaultButton from '../DefaultButton';

const SiteBlogPagination = ({
  page, pageLimit, count, onPrevious, onNext, style = '', navClass = '',
}) => {
  const start = ((page - 1) * pageLimit) + 1;
  let end = page * pageLimit;
  if (end >= count) {
    end = count;
  }
  const navClassName = navClass.length === 0 ? 'flex-auto flex items-center justify-between border-gray-200' : navClass;
  return (
    <nav className={navClassName} aria-label="Pagination" style={style}>
      <div className="hidden sm:block px-4">
        <p className="text-sm font-medium text-gray-900 mr-1">
          Showing
          <span className="font-medium m-1">{start}</span>
          to
          <span className="font-medium m-1">{end}</span>
          of
          <span className="font-medium m-1">{count}</span>
          results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end space-x-3">
        <DefaultButton variant="outline-light" onClick={onPrevious}>Previous</DefaultButton>
        <DefaultButton variant="outline-light" onClick={onNext}>Next</DefaultButton>
      </div>
    </nav>
  );
};

export default SiteBlogPagination;
