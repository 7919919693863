import React from 'react';
import { useCoverage } from '../../../contexts/CoverageContext';

import DefaultButton from '../../DefaultButton';

export default function SitePageNavbarCoverageButtons() {
  const { setIsModalOpen } = useCoverage();
  return (
    <>
      <DefaultButton
        onClick={() => setIsModalOpen(true)}
      >
        Add Clipping +
      </DefaultButton>
    </>
  );
}
