import React, { useState, useEffect } from 'react';
import Template from '../Template/Template';
import TemplatePageLanguageModal from '../Template/TemplatePageLanguageModal';

export default function PublishedHomePage() {
  const [isLocalStorageLanguageModalOn, setIsLocalStorageLanguageModalOn] = useState(false);
  const localStorageLanguage = localStorage.getItem('newsroomLanguage');

  useEffect(() => {
    if (localStorageLanguage == null) {
      setIsLocalStorageLanguageModalOn(true);
    } else {
      window.location.href = `/${localStorageLanguage}`;
    }
  }, [setIsLocalStorageLanguageModalOn, localStorageLanguage]);

  function languageModalSubmitCallback(language) {
    localStorage.setItem('newsroomLanguage', language);
    window.location.href = `/${language}`;
  }

  return (
    <Template dependTemplate>
      <TemplatePageLanguageModal
        isOpen={isLocalStorageLanguageModalOn}
        closeModal={() => setIsLocalStorageLanguageModalOn(false)}
        submitCallback={languageModalSubmitCallback}
      />
    </Template>

  );
}

PublishedHomePage.defaultProps = {
};

PublishedHomePage.propTypes = {
};
