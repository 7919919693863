import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sitesGetOrgSitesAPI } from '../api/sites';
import { orgGetOrgAPI } from '../api/organizations';
import { useAuth } from './AuthContext';
import { refsGetOrgRef } from '../api/refs';

const OrgContext = React.createContext({
  sites: [],
  setSites() {},
  org: null,
  setOrg() {},
  orgRef: null,
  setOrgRef() {},
});

export function useOrg() {
  return useContext(OrgContext);
}

/**
 * Fetch organization data, including sites belong to this org.
 * @param {String} uid User ID
 * @param {String} oid Organization ID
 */
export function useFetchOrg(oid) {
  const { setOrg, setOrgRef } = useOrg();
  const { userRef } = useAuth();
  useEffect(() => {
    const orgRef = refsGetOrgRef(userRef, oid);
    setOrgRef(orgRef);
    async function fetch() {
      const org = await orgGetOrgAPI(orgRef);
      setOrg(org);
    }
    fetch();
  }, [oid, setOrg, setOrgRef, userRef]);
}

export function useFetchOrgSites(oid) {
  const { setSites } = useOrg();
  const { userRef } = useAuth();
  useEffect(() => {
    async function fetch() {
      const orgRef = refsGetOrgRef(userRef, oid);
      const sites = await sitesGetOrgSitesAPI(orgRef);
      setSites(sites);
    }
    fetch();
  }, [oid, setSites, userRef]);
}

export function OrgProvider({ children }) {
  const [sites, setSites] = useState([]);
  const [org, setOrg] = useState();
  const [orgRef, setOrgRef] = useState();
  return (
    <OrgContext.Provider value={{
      sites, setSites, org, setOrg, orgRef, setOrgRef,
    }}
    >
      {children}
    </OrgContext.Provider>
  );
}

export function useUpdateOrgDomain() {
  const { setOrg, setOrgRef } = useOrg();
  const { userRef } = useAuth();
  return function updateSiteDomain(oid) {
    const orgRef = refsGetOrgRef(userRef, oid);
    setOrgRef(orgRef);
    async function fetch() {
      const org = await orgGetOrgAPI(orgRef);
      setOrg(org);
    }
    fetch();
  };
}

OrgProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
