import { v4 as uuidv4 } from 'uuid';
import { storage } from './firebase';

/**
 *
 * @param {String} storageRoute Firebase Storage route
 * @param {String} image Image data url
 * @returns
 */
export async function fileUploadWithDataURL(storageRoute, image) {
  const fileName = uuidv4();
  const ref = storage.ref().child(`${storageRoute}/${fileName}`);
  await ref.putString(image, 'data_url');
  const url = await ref.getDownloadURL();
  return url;
}
