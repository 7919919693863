import { db } from './firebase';

/** @typedef { import('firebase').default.firestore.DocumentReference } DocumentReference */

/**
 *
 * @param {String} uid User ID
 * @returns {DocumentReference} User Doc Ref
 */
export function refsGetUserRef(uid) {
  return db.collection('users').doc(uid);
}

/**
 *
 * @param {DocumentReference} userRef User Doc Ref
 * @param {String} oid Organization ID
 * @returns {DocumentReference} Organization Doc Ref
 */
export function refsGetOrgRef(userRef, oid) {
  return userRef.collection('organizations').doc(oid);
}

/**
 *
 * @param {DocumentReference} orgRef Organization Doc Ref
 * @param {String} sid Site ID
 * @returns {DocumentReference} Site Doc Ref
 */
export function refsGetSiteRef(orgRef, sid) {
  return orgRef.collection('sites').doc(sid);
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} pid Post ID
 * @returns {DocumentReference} Post Doc Ref
 */
export function refsGetPostRef(siteRef, pid) {
  return siteRef.collection('posts').doc(pid);
}

export function refsGetOrgRefByPath(uid, oid) {
  const userRef = refsGetUserRef(uid);
  const orgRef = refsGetOrgRef(userRef, oid);
  return orgRef;
}

export function refsGetSiteRefByPath(uid, oid, sid) {
  const orgRef = refsGetOrgRefByPath(uid, oid);
  return refsGetSiteRef(orgRef, sid);
}

export function refsGetPostRefByPath(uid, oid, sid, pid) {
  const siteRef = refsGetSiteRefByPath(uid, oid, sid);
  return refsGetPostRef(siteRef, pid);
}
