import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PreviewFrame from './PreviewFrame';
import { getLanguageByValue } from '../config/language';

const SitePreviewCard = ({ site }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  function handleCardClicked() {
    history.push(`${url}/site/${site.id}`);
  }
  const siteLanguage = getLanguageByValue(site.language);
  return (
    <div
      style={{ overflow: 'hidden' }}
      onClick={handleCardClicked}
      className="flex flex-col border rounded hover:shadow-md transition ease-in-out duration-150"
      role="button"
      tabIndex="0"
      aria-hidden
    >
      <div className="flex-shrink-0 border-b border-gray-200" style={{ pointerEvents: 'none' }}>
        <div className="w-full" style={{ height: 300 }}>
          <PreviewFrame site={site} scale={0.5} scrolling={false} mode="static" />
        </div>
      </div>
      <div className="flex-1 bg-white p-3 flex justify-between">
        <div className="flex-1">
          <div className="block">
            {(siteLanguage !== undefined && siteLanguage.name !== undefined) && (
              <h3 className="text-lg leading-7 font-semibold text-gray-900">
                {siteLanguage.name}
              </h3>
            )}
            <p className="text-base leading-6 text-gray-500 flex">
              Publish Status:
              <div className="pl-2">
                {site.published ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                  </svg>
                )}
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

SitePreviewCard.propTypes = {
  site: PropTypes.object.isRequired,
};

export default SitePreviewCard;
