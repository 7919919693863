import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import { storage } from './firebase';

/** @typedef { import('firebase').default.firestore.DocumentSnapshot } DocumentSnapshot */
/** @typedef { import('firebase').default.firestore.DocumentReference } DocumentReference */

/**
 *
 * @param {DocumentSnapshot} assetDoc
 */
async function processAsset(assetDoc) {
  const assetData = assetDoc.data();
  const { fullPath } = assetData;
  let meta;
  if (fullPath) {
    const fileRef = storage.ref().child(fullPath);
    meta = await fileRef.getMetadata();
  }
  return {
    ...assetData,
    id: assetDoc.id,
    createTime: assetData.createTime.toDate(),
    lastUpdate: assetData.lastUpdate.toDate(),
    fileMeta: meta,
  };
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} image Image data url
 * @param {String} fileName File name
 * @param {String} folderId Folder Asset ID
 * @return {Object} New media asset
 */
export async function mediaAssetsUploadAssetAPI(siteRef, image, fileName, folderId) {
  const fileId = uuidv4();
  const fileRef = storage.ref().child(`media-assets/${siteRef.id}/${fileId}`);
  await fileRef.putString(image, 'data_url');
  const url = await fileRef.getDownloadURL();
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  const siteRefDoc = (folderId === undefined) ? siteRef : siteRef.collection('assets').doc(folderId);
  await siteRefDoc.collection('assets').doc(fileId).set({
    originalURL: url,
    fileName: fileName || fileId,
    createTime: serverTimestamp,
    lastUpdate: serverTimestamp,
    fullPath: fileRef.fullPath,
  });
  const newAssetDoc = await siteRefDoc.collection('assets').doc(fileId).get();
  return processAsset(newAssetDoc);
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} folderId folder asset ID
 * @return {Array} Assets
 */
export async function mediaAssetsGetAssetsAPI(siteRef, folderId) {
  const siteRefDoc = (folderId === undefined) ? siteRef : siteRef.collection('assets').doc(folderId);
  const assetsQuerySnapshot = await siteRefDoc.collection('assets').get();
  return Promise.all(assetsQuerySnapshot.docs.map(processAsset));
}

/**
 * Removes a media asset from a site.
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} aid Asset ID
 * @returns {Boolean} Boolean value of whether the asset ref is successfully deleted.
 */
export async function mediaAssetsRemoveAssetAPI(siteRef, aid) {
  const assetRef = siteRef.collection('assets').doc(aid);
  const assetDoc = await assetRef.get();
  if (!assetDoc.exists) {
    throw new Error('Asset doesn\' exist.');
  }
  try {
    await assetRef.delete();
    return true;
  } catch {
    return false;
  }
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} aid Asset ID
 * @param {String} name New name
 * @param {Boolean} isFoler is renaming folder type asset
 * @param {String} folderId asset in folder asset ID
 */
export async function mediaAssetsRenameAssetAPI(siteRef, aid, name, isFolder, folderId) {
  if (!name) {
    throw new Error('Asset name cannot be empty.');
  }
  const assetRefDoc = (folderId === undefined) ? siteRef.collection('assets').doc(aid) : siteRef.collection('assets').doc(folderId).collection('assets').doc(aid);
  const assetDoc = await assetRefDoc.get();
  if (!assetDoc.exists) {
    throw new Error('Asset doesn\' exist.');
  }
  const updateAttribute = isFolder ? { folderName: name } : { fileName: name };
  await assetRefDoc.update(updateAttribute);
}

/**
 *
 * @param {String} uid User ID
 * @param {String} sid Site ID
 * @param {String} assetId Asset ID
 */
export async function mediaAssetsGetSingleAssetAPI(siteRef, assetId) {
  const assetDoc = await siteRef.collection('assets')
    .doc(assetId)
    .get();
  return processAsset(assetDoc);
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} folderNAme folder name
 */
export async function mediaAssetsFolderUploadFolderAPI(siteRef, folderName) {
  const folderId = uuidv4();
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  await siteRef.collection('assets').doc(folderId).set({
    isFolder: true,
    folderName,
    createTime: serverTimestamp,
    lastUpdate: serverTimestamp,
  });
  const newAssetDoc = await siteRef.collection('assets').doc(folderId).get();
  return processAsset(newAssetDoc);
}
