import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EditSiteEditorFormUIDraft from './EditSiteEditorFormUI/EditSiteEditorFormUIDraft';
import EditSiteEditorFormUIInput from './EditSiteEditorFormUI/EditSiteEditorFormUIInput';
import EditSiteEditorFormGroup from './EditSiteEditorFormGroup';

const EditSiteEditorFormAbout = ({ section, onInputChange }) => {
  const { config } = section;

  const [content, setContent] = useState();
  const [contentChecked, setContentChecked] = useState(false);
  useEffect(() => {
    if (typeof config.content === 'string') {
      try {
        setContent(JSON.parse(config.content));
      } catch {
        setContent(null);
      } finally {
        setContentChecked(true);
      }
    } else {
      setContent(config.content);
      setContentChecked(true);
    }
  }, [config.content]);
  return (
    <>
      <div className="mb-5">
        <EditSiteEditorFormUIInput
          label="Title"
          value={config && config.title}
          onChange={onInputChange('title')}
        />
      </div>
      <EditSiteEditorFormGroup title="Content">
        {contentChecked && <EditSiteEditorFormUIDraft onChange={onInputChange('content')} initialContentState={content} />}
      </EditSiteEditorFormGroup>
    </>
  );
};

EditSiteEditorFormAbout.propTypes = {
  section: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default EditSiteEditorFormAbout;
