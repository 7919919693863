import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

const AddSectionContext = React.createContext({
  isModalOpen: false,
  setIsModalOpen: () => {},
  sectionIndex: null,
  setSectionIndex: () => {},
});

export function useAddSection() {
  return useContext(AddSectionContext);
}

export function AddSectionProvider({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sectionIndex, setSectionIndex] = useState();
  return (
    <AddSectionContext.Provider value={{
      isModalOpen, setIsModalOpen, sectionIndex, setSectionIndex,
    }}
    >
      {children}
    </AddSectionContext.Provider>
  );
}

AddSectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
