import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

const AuthContext = React.createContext({
  user: {},
  setUser: () => {},
  checked: false,
  setChecked: () => {},
  userRef: {},
  setUserRef() {},
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const [userRef, setUserRef] = useState({});
  const [checked, setChecked] = useState(false);
  return (
    <AuthContext.Provider value={{
      user, setUser, checked, setChecked, userRef, setUserRef,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
