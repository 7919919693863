import { useEffect, useState } from 'react';
import { postsGetPostAPI } from '../api/posts';
import { refsGetPostRefByPath } from '../api/refs';

export default function useFetchPost(userId, orgId, siteId, postId) {
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetch() {
      setLoading(true);
      try {
        const postRef = refsGetPostRefByPath(userId, orgId, siteId, postId);
        const tmpPost = await postsGetPostAPI(postRef);
        setPost(tmpPost);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    fetch();
  }, [userId, orgId, siteId, postId]);
  return { post, loading };
}
