import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SiteAssetsPageAssetCardDropDown from './SiteAssetsPageAssetCardDropDown';
import { useAsset } from '../../contexts/AssetContext';

export default function SiteAssetsPageFolderCard({ asset }) {
  const { setAsset } = useAsset();
  const { pathname } = useLocation();
  useEffect(() => {
    setAsset(asset);
  }, [asset, setAsset]);
  const preview = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-full h-48 text-blue-300">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
    </svg>
  );
  return (
    <div className="relative">
      <div className="w-full border rounded overflow-hidden">
        <Link to={`${pathname}/${asset.id}`} className="p-2 bg-gray-50 grid place-items-center" rel="noopener noreferrer">
          {preview}
        </Link>
        <div className="p-2 flex items-center bg-white">
          <h6 className="overflow-ellipsis whitespace-nowrap overflow-hidden flex-grow">{asset.folderName}</h6>
          <SiteAssetsPageAssetCardDropDown />
        </div>
      </div>
    </div>
  );
}

SiteAssetsPageFolderCard.propTypes = {
  asset: PropTypes.object.isRequired,
};
