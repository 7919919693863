import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { sitesPublishSiteAPI } from '../../../api/sites';
import { useSite } from '../../../contexts/SiteContext';
import DefaultButton from '../../DefaultButton';
import SitePageNavbarDropdown from '../../SitePageNavbarDropdown';

export default function SitePageNavbarSiteButtons() {
  const history = useHistory();
  const { location: { pathname } } = history;
  const [publishing, setPublishing] = useState(false);
  const { siteRef } = useSite();
  const { site, setSite } = useSite();
  const handlePublishClicked = async () => {
    setPublishing(true);
    try {
      await sitesPublishSiteAPI(siteRef);
      setSite({
        ...site,
        published: true,
      });
    } finally {
      setPublishing(false);
    }
  };
  return (
    <>
      <DefaultButton onClick={() => history.push(`${pathname}/edit`)} variant="light">Edit Newsroom</DefaultButton>
      <DefaultButton
        onClick={handlePublishClicked}
        loading={publishing}
        disabled={publishing}
      >
        Publish
      </DefaultButton>
      <SitePageNavbarDropdown site={site} />
    </>
  );
}
