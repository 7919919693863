import React from 'react';
import PropTypes from 'prop-types';

const SitePageStatCell = ({ title, content }) => (
  <div className="bg-white overflow-hidden shadow rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <dl>
        <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
          {title}
        </dt>
        <dd className="mt-1 text-md leading-9 font-semibold text-gray-900">
          {content}
        </dd>
      </dl>
    </div>
  </div>
);

SitePageStatCell.defaultProps = {
  title: 'Title',
  content: 'Content',
};

SitePageStatCell.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default SitePageStatCell;
