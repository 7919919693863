import React from 'react';
import PropTypes from 'prop-types';
import EditSiteEditorCellHeading from './EditSiteEditorCellHeading';

export default function EditSiteEditorCell({
  children, title, onSave, onRemove, changeNotSave,
}) {
  return (
    <div className={`${changeNotSave ? 'border-red-300 border-2' : ''} rounded-md bg-white overflow-hidden`}>
      <EditSiteEditorCellHeading title={title} onSave={onSave} onRemove={onRemove} changeNotSave={changeNotSave} />
      <div className="px-5 py-5">
        {children}
      </div>
    </div>
  );
}

EditSiteEditorCell.defaultProps = {
  onSave: null,
  onRemove: null,
  children: null,
  title: '',
  changeNotSave: false,
};

EditSiteEditorCell.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  changeNotSave: PropTypes.bool,
};
