import React from 'react';
import CreateSiteSectionSelector from './CreateSiteSectionPicker';

const CreateSiteEditorColumn = () => (
  <div className="px-10" style={{ width: 650 }}>
    <CreateSiteSectionSelector />
  </div>
);

export default CreateSiteEditorColumn;
