import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { clippingsGetClippingsAPI } from '../api/clippings';
import { useSite } from './SiteContext';

const CoverageContext = React.createContext({
  clippings: [],
  setClippings: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  modalClipping: null,
  setModalClipping: () => {},
});

export function useCoverage() {
  return useContext(CoverageContext);
}

/**
 *
 * @param {String} uid User ID
 * @param {String} sid Site ID
 */
export function useFetchClippings() {
  const { setClippings } = useContext(CoverageContext);
  const { siteRef } = useSite();
  useEffect(() => {
    async function fetch() {
      const clippings = await clippingsGetClippingsAPI(siteRef);
      setClippings(clippings);
    }
    fetch();
  }, [siteRef, setClippings]);
}

export function useUpdateClipping() {
  const { clippings, setClippings } = useContext(CoverageContext);
  return function updateClipping(cid, updates) {
    const values = [...clippings];
    const index = values.map((asset) => asset.id).indexOf(cid);
    Object.keys(updates).forEach((key) => {
      values[index][key] = updates[key];
    });
    setClippings(values);
  };
}

export function useAppendClipping() {
  const { setClippings } = useContext(CoverageContext);
  return function appendClipping(clipping) {
    setClippings((prevClippings) => [...prevClippings, clipping]);
  };
}

export function useRemoveClipping() {
  const { clippings, setClippings } = useContext(CoverageContext);
  return function removeClipping(cid) {
    const values = [...clippings].filter((clipping) => clipping.id !== cid);
    setClippings(values);
  };
}

export function CoverageProvider({ children }) {
  const [clippings, setClippings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalClipping, setModalClipping] = useState(null);
  useEffect(() => {
    if (!isModalOpen) {
      setTimeout(() => {
        setModalClipping(null);
      }, 200);
    }
  }, [isModalOpen, setModalClipping]);
  return (
    <CoverageContext.Provider value={{
      clippings, setClippings, isModalOpen, setIsModalOpen, modalClipping, setModalClipping,
    }}
    >
      {children}
    </CoverageContext.Provider>
  );
}

CoverageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
