import React from 'react';
import App from './App';
import { useConfig, useFetchConfig } from './contexts/ConfigContext';

export default function ConfigWrapperApp() {
  const { config } = useConfig();
  useFetchConfig();
  if (!config) {
    return null;
  }
  return <App />;
}
