import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useConvertFromEditorRawToHTML from '../../hooks/useConvertFromEditorRawToHTML';
import './TemplateHero.scss';

export default function TemplateAbout({ config }) {
  const [content, setContent] = useState();
  useEffect(() => {
    if (typeof config.content === 'string') {
      try {
        setContent(JSON.parse(config.content));
      } catch {
        setContent(null);
      }
    } else {
      setContent(config.content);
    }
  }, [config.content]);

  const contentMarkup = useConvertFromEditorRawToHTML(content);
  return (
    <main className="mt-16 mx-auto max-w-7xl px-8 sm:mt-24">
      <div className="max-w-7xl mx-auto text-left">
        <h2 className="text-3xl leading-9 font-extrabold text-gray-900">{config && config.title}</h2>
      </div>
      <div className="mt-8 max-w-7xl mx-auto text-left md:flex">
        <div style={{ flex: '1' }} className="about-container" dangerouslySetInnerHTML={contentMarkup} />
      </div>
    </main>
  );
}

TemplateAbout.defaultProps = {
  config: null,
};

TemplateAbout.propTypes = {
  config: PropTypes.object,
};
