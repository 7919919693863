import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import './EditSiteEditorFormUIDraft.scss';

export default function EditSiteEditorFormUIDraft({ onChange, initialContentState }) {
  function handleContentStateChange(contentState) {
    onChange({ target: { value: JSON.stringify(contentState) } });
  }
  return (
    <div className="border border-gray-200 p-2 rounded my-2">
      <Editor
        initialContentState={initialContentState}
        editorClassName="about-editor"
        toolbarClassName="about-toolbar"
        toolbar={{
          options: ['inline', 'blockType', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          blockType: {
            inDropdown: false,
            options: ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
            h1: { className: 'font-bold' },
          },
        }}
        onContentStateChange={handleContentStateChange}
      />
    </div>
  );
}

EditSiteEditorFormUIDraft.defaultProps = {
  initialContentState: null,
};

EditSiteEditorFormUIDraft.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialContentState: PropTypes.object,
};
