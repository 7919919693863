export const NAVBAR = 'navbar';
export const ABOUT = 'about';
export const PRESS_RELEASES_PREVIEW = 'pressReleasesPreview';
export const TESTIMONIALS = 'testimonials';
export const CONTACT = 'contact';
export const COVERAGES_PREVIEW = 'coveragesPreview';
export const TEAM = 'team';
export const FOOTER = 'footer';
export const CTA = 'cta';
export const FEATURE = 'feature';
export const FAQ = 'faq';
export const GALLERY = 'gallery';
export const EMAIL_CAPTURE = 'emailCapture';
