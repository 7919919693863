import React from 'react';
import PropTypes from 'prop-types';
// import EditSiteEditorFormNavbarLinks from './EditSiteEditorFormNavbarLinks';
import EditSiteEditorFormNavbarLogo from './EditSiteEditorFormNavbarLogo';

export default function EditSiteEditorFormNavbar({ section, onInputChange }) {
  const { config, id } = section;
  function handleLogoChange(logo) {
    onInputChange('logo')({ target: { value: logo } });
  }
  return (
    <>
      <EditSiteEditorFormNavbarLogo logo={config.logo} onLogoChange={handleLogoChange} />

      {/* <div className="mt-4"> */}
      {/* <EditSiteEditorFormNavbarLinks links={config && config.links} sectionId={id} /> */}
      {/* </div> */}
    </>
  );
}

EditSiteEditorFormNavbar.propTypes = {
  section: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
};
