import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

export default function SitePostPageCoverImage({ uploadImage, currentUploadedImage }) {
  const [localImage, setLocalImage] = useState('');
  const fileInputRef = useRef();
  function handleUploadCoverImage() {
    fileInputRef.current.click();
  }

  const handleFileChanged = (e) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      setLocalImage(reader.result);
      await uploadImage(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const image = localImage || currentUploadedImage;
  return (
    <>
      {image ? (
        <div role="button" aria-hidden onClick={handleUploadCoverImage} className="overflow-hidden rounded rounded-md">
          <img src={image} alt="cover" />
        </div>
      ) : (
        <div
          className="w-full py-16 bg-gray-100 rounded rounded-md border border-gray-200 text-gray-500 flex justify-center"
          role="button"
          aria-hidden
          onClick={handleUploadCoverImage}
          aria-label="cover photo"
        >
          <i className="fas fa-cloud-upload-alt fa-lg" />
        </div>
      )}
      <input type="file" accept="image/*" className="hidden" ref={(fileInput) => { fileInputRef.current = fileInput; }} onChange={handleFileChanged} />
    </>
  );
}

SitePostPageCoverImage.defaultProps = {
  currentUploadedImage: '',
};

SitePostPageCoverImage.propTypes = {
  uploadImage: PropTypes.func.isRequired,
  currentUploadedImage: PropTypes.string,
};
