import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import { domainsPublishSiteAPI, domainsUnpublishSiteAPI } from './domains';
import { storage } from './firebase';
import { orgGetOrgAPI } from './organizations';
import { getSectionsWithIds, sectionsAddSectionAPI } from './sections';

/** @typedef { import('firebase').default.firestore.DocumentSnapshot } DocumentSnapshot */
/** @typedef { import('firebase').default.firestore.DocumentReference } DocumentReference */

/**
 *
 * @param {DocumentSnapshot} siteDoc
 */
async function processSite(siteDoc) {
  const siteData = siteDoc.data();
  const [homeSectinos] = await Promise.all([
    getSectionsWithIds(siteDoc.ref, siteData.pages.home.sections),
  ]);
  return {
    ...siteData,
    id: siteDoc.id,
    creationTime: siteData.creationTime.toDate(),
    lastPublished: siteData.lastPublished && siteData.lastPublished.toDate(),
    pages: {
      ...siteData.pages,
      home: {
        sections: homeSectinos,
      },
    },
  };
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} sid Site ID
 */
export async function sitesGetPublishedSitesAPI(orgRef) {
  const orgDoc = await orgRef.get();
  if (!orgDoc.exists) {
    throw new Error('Organization doesn\'t exist.');
  }

  const sitesQuerySnapshot = await orgRef.collection('sites').where('archived', '==', false).get();
  return Promise.all(sitesQuerySnapshot.docs.map(processSite));
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} sid Site ID
 */
export async function sitesGetSiteAPI(siteRef) {
  const siteDoc = await siteRef.get();
  if (!siteDoc.exists) {
    throw new Error('Site doesn\' exist.');
  }
  const site = await processSite(siteDoc);
  return site;
}

/**
 * @param {DocumentReference} siteRef Site Doc Ref
 */
export async function sitesPublishSiteAPI(siteRef) {
  const site = await sitesGetSiteAPI(siteRef);
  const language = site.language || 'en';
  const { pages, meta, theme } = site;

  const publishedSite = {
    pages,
    meta,
    theme,
    language,
  };

  const orgRef = siteRef.parent.parent;
  const org = await orgGetOrgAPI(orgRef);

  await Promise.all([
    domainsPublishSiteAPI(org.domain, siteRef, publishedSite),
    siteRef.update({
      lastPublished: firebase.firestore.FieldValue.serverTimestamp(),
      published: true,
    }),
  ]);
}

/**
 *
 * @param {DocumentReference} siteRef Site doc ref
 */
export async function siteUnpublishSiteAPI(siteRef) {
  const siteDoc = await siteRef.get();
  const siteData = siteDoc.data();
  const language = siteData.language || 'en';

  const orgRef = siteRef.parent.parent;
  const org = await orgGetOrgAPI(orgRef);

  await Promise.all([
    domainsUnpublishSiteAPI(org.domain, language),
    siteRef.update({ published: false }),
  ]);
}

// export const sitesDeleteUserSingleSiteAPI = async (uid, sid) => {
//   const siteRef = db.collection('users').doc(uid).collection('sites').doc(sid);
//   const deleteFn = functions.httpsCallable('recursiveDelete');
//   await Promise.all([
//     (async () => {
//       const siteDoc = await siteRef.get();
//       const { domain } = siteDoc.data();
//       return deleteDomain(domain);
//     })(),
//     deleteFn({ path: siteRef.path }),
//   ]);
// };

/**
 *
 * @param {DocumentReference} siteRef Site doc ref
 */
export async function sitesArchiveSiteSiteAPI(siteRef) {
  await siteRef.update({ archived: true, published: false });
}

/**
 *
 * @param {String} siteId Site ID
 * @param {String} sectionId Section ID
 * @param {String} itemId Item ID
 * @param {String} image Image Data Url
 * @returns
 */
export const sitesUploadConfigGroupItemImageAPI = async (siteId, sectionId, itemId, image) => {
  const ref = storage.ref().child(`sites/${siteId}/${sectionId}/${itemId}.jpg`);
  await ref.putString(image, 'data_url');
  return ref.getDownloadURL();
};

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} sectionId Section ID
 * @param {Object} config Config
 */
export const sitesSaveSectionConfigAPI = async (siteRef, sectionId, config) => {
  const sectionRef = siteRef.collection('sections').doc(sectionId);
  await sectionRef.update({
    config,
  });
};

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {Object} meta Site meta
 */
export async function sitesSaveMetaAPI(siteRef, meta) {
  const siteDoc = await siteRef.get();
  if (!siteDoc.exists) {
    throw new Error('Site doesn\'t exist.');
  }
  await siteRef.update({ meta });
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {Object} theme Site theme
 */
export async function sitesSaveThemeAPI(siteRef, theme) {
  const siteDoc = await siteRef.get();
  if (!siteDoc.exists) {
    throw new Error('Site doesn\'t exist.');
  }
  await siteRef.update({ theme });
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} page Page
 * @param {String} key Key to be updated in page
 * @param {String} value Updated value in page
 */
export async function sitesUpdateAdditionalPageAPI(siteRef, page, key, value) {
  const siteDoc = await siteRef.get();
  if (!siteDoc.exists) {
    throw new Error('Site doesn\'t exist.');
  }
  const path = `pages.${page}.${key}`;
  const updates = {};
  updates[path] = value;
  await siteRef.update(updates);
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} page Page
 * @param {Boolean} enabled Whether the page is enabled
 */
export async function sitesEnableAdditionalPageAPI(siteRef, page, enabled, navbarSectionId, navbarNewLinks) {
  const siteDoc = await siteRef.get();
  if (!siteDoc.exists) {
    throw new Error('Site doesn\'t exist.');
  }
  await sitesUpdateAdditionalPageAPI(siteRef, page, 'enabled', enabled);
  const navbarSectionRef = siteRef.collection('sections').doc(navbarSectionId);
  const navbarSectionDoc = await navbarSectionRef.get();
  if (!navbarSectionDoc.exists) {
    throw new Error('Navbar section doesn\'t exist.');
  }
  await navbarSectionRef.update({ 'config.links': navbarNewLinks });
}

// /**
//  *
//  * @param {String} oldDomain Old Domain
//  * @param {String} newDomain New Domain
//  */
// export const sitesUpdateSiteDomainAPI = async (oldDomain, newDomain) => {
//   const newDomainDoc = await db.collection('domains').doc(newDomain).get();
//   if (newDomainDoc.exists) {
//     throw new Error('This subdomain is unavailable.');
//   }
//   const oldDomainDoc = await db.collection('domains').doc(oldDomain).get();
//   if (!oldDomainDoc.exists) {
//     throw new Error('Old domain doesn\'t exist');
//   }
//   const oldDomainData = oldDomainDoc.data();
//   const { siteRef } = oldDomainData;
//   await Promise.all([
//     newDomainDoc.ref.set(oldDomainData),
//     siteRef.update({ domain: newDomain }),
//     oldDomainDoc.ref.delete(),
//   ]);
// };

/**
 * Create a new site under an organization.
 * @param {DocumentReference} orgRef Organization Doc
 * @param {Array} sections Sections
 * @returns {Object} Newly created site
 */
export const sitesCreateSiteAPI = async (orgRef, sections, language) => {
  const sid = uuidv4().split('-').pop();
  const newSiteRef = await orgRef.collection('sites').doc(sid);
  const newSections = await Promise.all(sections.map((section) => sectionsAddSectionAPI(newSiteRef, section)));

  const siteData = {
    meta: {
      title: 'My Newsroom',
    },
    theme: {
      primaryColor: '#5a67d8',
      primaryFont: 'roboto',
    },
    creationTime: firebase.firestore.FieldValue.serverTimestamp(),
    published: false,
    language,
    archived: false,
    pages: {
      home: {
        exists: true,
        sections: newSections.map((section) => section.id),
      },
      coverage: {
        exists: false,
      },
    },
  };
  await newSiteRef.set(siteData);
  const newSiteDoc = await newSiteRef.get();
  return processSite(newSiteDoc);
};

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {Object} section New section
 * @param {Number} index Index
 * @return {Object} Newly added section.
 */
export async function sitesAddSectionAPI(siteRef, section, index) {
  const siteDoc = await siteRef.get();
  if (!siteDoc.exists) {
    throw new Error('Site Doesn\'t exist.');
  }
  const newSection = await sectionsAddSectionAPI(siteRef, section);
  const newSectionId = newSection.id;
  const siteData = siteDoc.data();
  const { sections } = siteData.pages.home;
  const newSectionIds = [...sections.slice(0, index), newSectionId, ...sections.slice(index)];
  await siteRef.update({ 'pages.home.sections': newSectionIds });
  return newSection;
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} siteId Site ID
 * @param {String} sectionId Section ID
 */
export async function sitesRemoveSectionAPI(siteRef, sectionId) {
  const sectionRef = siteRef.collection('sections').doc(sectionId);
  const [siteDoc, sectionDoc] = await Promise.all([siteRef.get(), sectionRef.get()]);
  if (!siteDoc.exists) {
    throw new Error('Site doesn\'t exist.');
  }
  if (!sectionDoc.exists) {
    throw new Error('Section doesn\' exist.');
  }
  const siteData = siteDoc.data();
  const sectionIds = siteData.pages.home.sections;
  const newSectionIds = [...sectionIds].filter((id) => id !== sectionId);
  await Promise.all([siteRef.update({ 'pages.home.sections': newSectionIds }), sectionRef.delete()]);
}

/**
 * Returns a list of sites under an organization.
 * @param {DocumentReference} orgRef Organization Doc Ref
 * @returns {Array<Object>} List of sites.
 */
export async function sitesGetOrgSitesAPI(orgRef) {
  const orgDoc = await orgRef.get();
  if (!orgDoc.exists) {
    throw new Error('Organization doesn\'t exist.');
  }

  const sitesQuerySnapshot = await orgRef.collection('sites').where('archived', '==', false).get();
  return Promise.all(sitesQuerySnapshot.docs.map(processSite));
}
