import React from 'react';
import PropTypes from 'prop-types';
import { useEditSite, useSetEditSiteThemeProperty } from '../../../../contexts/EditSiteContext';
import { supportedFonts } from '../../../../utils/supportedFonts';
import EditSiteEditorFormUIColorPicker from './EditSiteEditorFormUI/EditSiteEditorFormUIColorPicker';
import EditSiteEditorFormUISelect from './EditSiteEditorFormUI/EditSiteEditorFormUISelect';

export default function EditSiteEditorFormTheme({ onInputChange }) {
  const { editSite: { theme } } = useEditSite();
  const setEditSiteThemeProperty = useSetEditSiteThemeProperty();
  const fontOptions = Object.keys(supportedFonts).map((key) => ({
    name: supportedFonts[key].displayName,
    value: key,
  }));
  function handlePrimaryColorChange(color) {
    setEditSiteThemeProperty('primaryColor', color);
    onInputChange();
  }
  function handlePrimaryFontChange(font) {
    setEditSiteThemeProperty('primaryFont', font);
    onInputChange();
  }
  return (
    <div className="space-y-5">
      <EditSiteEditorFormUIColorPicker
        label="Primary Color"
        onChange={handlePrimaryColorChange}
        value={theme && theme.primaryColor}
      />
      <EditSiteEditorFormUISelect
        label="Primary Font"
        onChange={handlePrimaryFontChange}
        defaultValue={theme && theme.primaryColor}
        options={fontOptions}
      />
    </div>
  );
}

EditSiteEditorFormTheme.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};
