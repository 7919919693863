import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { find } from 'lodash';
import { Disclosure } from '@headlessui/react';
import { NavHashLink } from 'react-router-hash-link';
import { TEXT_KEY } from '../EditSite/EditSiteEditor/EditSiteEditorForm/EditSiteEditorFormNavbarLogo';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';
import { usePublishedSite } from '../../contexts/Published/PublishedSiteContext';
import TemplateAboutSocialButton from './TemplateAboutSocialButton';
import TemplateNavbarLanguageSelector from './TemplateNavbarLanguageSelector';
import { getI18N } from '../../config/i18n';
import { getAllLanguage } from '../../config/language';

const defaultAnchorClasses = 'text-sm text-gray-400 hover:text-gray-500';
const defaultLiClasses = 'text-gray-300';

export default function TemplateNavbar({
  config, disabled, socialMedias, showContactLink, language, meta,
}) {
  const title = meta.title || 'My Newsroom';
  const now = new Date();
  const nowYear = now.getFullYear();
  const { primaryColor } = useGetTemplateTheme();
  const i18n = getI18N(language);
  const website = find(socialMedias, { media: 'officialWebsite' });
  const languages = getAllLanguage();
  const publishedSite = usePublishedSite();
  const publishSitesValue = (publishedSite.site) ? publishedSite.site.publishedSites : [];
  const selectAbleLanguages = languages.filter((language) => Object.keys(publishSitesValue).indexOf(language.value) !== -1);
  const mappingKey = {
    '/media-assets': 'mediaAssets',
    '/clippings': 'newsClippings',
    '/press-releases': 'pressReleases',
  };
  let links = config.links || [];
  const logo = config.logo || {
    text: 'Your Title',
    image: '',
    display: 'text',
    link: '',
  };

  let logoContent;
  if (logo.display === TEXT_KEY) {
    logoContent = logo.text;
  } else if (logo.image) {
    logoContent = <img className="h-12 w-auto" src={logo.image} alt="" />;
  }

  if (showContactLink) {
    links = [...links, {
      title: 'Contact',
      url: '/#contact',
    }];
  }

  let websiteContent;
  let mobileWebsiteContent;
  if (website && website !== undefined) {
    websiteContent = (
      <Link
        style={{ 'background-color': primaryColor }}
        className="hidden lg:inline-block py-2 px-6 text-sm text-white font-bold rounded-l-xl rounded-t-xl transition duration-200"
        to={{ pathname: website.url }}
        target="_blank"
      >
        Website
      </Link>
    );

    mobileWebsiteContent = (
      <Link
        style={{ 'background-color': primaryColor }}
        className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-green-600 hover:bg-green-700 rounded-l-xl rounded-t-xl"
        to={{ pathname: website.url }}
        target="_blank"
      >
        Website
      </Link>
    );
  }

  const separateContent = <li className={defaultLiClasses}><svg className="w-4 h-4 current-fill" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" /></svg></li>;
  const languageSelectorOnChange = (lang) => {
    if (language !== lang) {
      window.location.href = (`/${lang}`);
    }
  };

  let logoLinkContent;
  if (disabled) {
    logoLinkContent = (
      <Link to="#" className="text-3xl font bold leading-none">
        {logoContent}
      </Link>
    );
  } else if (logo.link === '' || logo.link === undefined) {
    logoLinkContent = (
      <Link to={`/${language}`} className="text-3xl font bold leading-none">
        {logoContent}
      </Link>
    );
  } else {
    logoLinkContent = (
      <Link to={{ pathname: logo.link }} target="blank" className="text-3xl font bold leading-none">
        {logoContent}
      </Link>
    );
  }

  return (
    <Disclosure as="nav" className="relative py-6 bg-white">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 flex justify-between items-center">
            {logoLinkContent}
            <div className="lg:hidden">
              <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md focus:outline-none">
                <svg className="block h-4 w-4 fill-current" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <title>Mobile menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </Disclosure.Button>
            </div>
            <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
              {!(logo.link === '' || logo.link === undefined) && (
                <>
                  {separateContent}
                  <li className={defaultLiClasses}>
                    <NavLink
                      to={disabled ? '#' : `/${language}`}
                      className={defaultAnchorClasses}
                      activeStyle={{ color: primaryColor }}
                      exact
                    >
                      { i18n.home }
                    </NavLink>
                  </li>
                </>
              )}
              {links.map((link, index) => (
                <>
                  {separateContent}
                  <li className={defaultLiClasses}>
                    {link.title === 'Contact'
                      && (
                      <NavHashLink
                        key={link.id}
                        to={disabled ? '#' : `/${language}${link.url}`}
                        className={defaultAnchorClasses}
                        activeStyle={{ color: primaryColor }}
                      >
                        {i18n.contact}
                      </NavHashLink>
                      )}
                    { link.title !== 'Contact'
                      && (
                      <NavLink
                        key={link.id}
                        to={disabled ? '#' : `/${language}${link.url}`}
                        className={defaultAnchorClasses}
                        activeStyle={{ color: primaryColor }}
                      >
                        { i18n[mappingKey[link.url]] }
                      </NavLink>
                      )}
                  </li>
                  {index === links.length ? separateContent : ''}
                </>
              ))}
            </ul>
            <div className="hidden lg:flex">
              {selectAbleLanguages.length >= 2
                && (
                <div className="pr-3">
                  <TemplateNavbarLanguageSelector value={language} onChange={languageSelectorOnChange} languageOptions={selectAbleLanguages} />
                </div>
                )}
              {selectAbleLanguages.length <= 1 && website && website !== undefined ? websiteContent : ''}
            </div>
            <div className={`${open ? '' : 'hidden'} navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50`}>
              <Disclosure.Button className="fixed">
                <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" />
              </Disclosure.Button>
              <nav className="relative flex flex-col py-6 px-6 h-full w-full bg-white border-r overflow-y-auto">
                <div className="flex justify-between items-center mb-8">
                  {logoLinkContent}
                  <div className="pr-3">
                    <TemplateNavbarLanguageSelector value={language} onChange={languageSelectorOnChange} languageOptions={selectAbleLanguages} />
                  </div>
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md focus:outline-none">
                    <svg className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </Disclosure.Button>
                </div>
                <div>
                  <ul>
                    {!(logo.link === '' || logo.link === undefined) && (
                      <li className="mb-1">
                        <NavLink
                          to={disabled ? '#' : `/${language}`}
                          className="block p-4 text-sm font-semibold text-gray-400 rounded"
                          activeStyle={{ color: primaryColor }}
                          exact
                        >
                          {i18n.home}
                        </NavLink>
                      </li>
                    )}
                    {links.map((link) => (
                      <>
                        <li className="mb-1">
                          {link.title === 'Contact'
                            && (
                            <Disclosure.Button>
                              <NavHashLink
                                key={link.id}
                                to={disabled ? '#' : `/${language}${link.url}`}
                                className="block p-4 text-sm font-semibold text-gray-400 rounded"
                                activeStyle={{ color: primaryColor }}
                              >
                                {i18n.contact}
                              </NavHashLink>
                            </Disclosure.Button>
                            )}
                          { link.title !== 'Contact'
                            && (
                            <NavLink
                              key={link.id}
                              to={disabled ? '#' : `/${language}${link.url}`}
                              className="block p-4 text-sm font-semibold text-gray-400 rounded"
                              activeStyle={{ color: primaryColor }}
                            >
                              { i18n[mappingKey[link.url]] }
                            </NavLink>
                            )}
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
                <div className="mt-auto">
                  <div className="pt-6">
                    {selectAbleLanguages.length <= 1 && website && website !== undefined ? mobileWebsiteContent : ''}
                  </div>
                  <p className="my-4 text-xs text-center text-gray-400">
                    <span>
                      &copy;
                      {` ${nowYear} ${title} ${i18n.poweredBy} Press Hunt. ${i18n.allRightsReserved}.`}
                    </span>
                  </p>
                  <div className="text-center justify-between">
                    {socialMedias.map((socialMedia) => (
                      <TemplateAboutSocialButton
                        color={primaryColor}
                        key={socialMedia.id}
                        media={socialMedia.media}
                        url={socialMedia.url}
                        size="sm"
                        bordered={false}
                        isNavBar
                      />
                    ))}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}

TemplateNavbar.defaultProps = {
  config: {},
  socialMedias: [],
  showContactLink: false,
  language: 'en',
  meta: {},
};

TemplateNavbar.propTypes = {
  config: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  socialMedias: PropTypes.array,
  showContactLink: PropTypes.bool,
  language: PropTypes.string,
  meta: PropTypes.object,
};
