import React from 'react';
import { Route } from 'react-router-dom';
import Navbar from './Navbar';
import HomePage from './HomePage';
import { OrgProvider } from '../contexts/OrgContext';
import OrgRoute from './Org/OrgRoute';

export default function () {
  const loaded = true;
  return loaded && (
    <>
      <Navbar />
      <div className="mt-12">
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/organization/:orgId">
          <OrgProvider>
            <OrgRoute />
          </OrgProvider>
        </Route>
      </div>
    </>
  );
}
