import firebase from 'firebase/app';
import { storage } from './firebase';

/** @typedef { import('firebase').default.firestore.DocumentSnapshot } DocumentSnapshot */
/** @typedef { import('firebase').default.firestore.DocumentReference } DocumentReference */

/**
 *
 * @param {DocumentSnapshot} clippingDoc
 */
function processClipping(clippingDoc) {
  const clippingData = clippingDoc.data();
  return {
    ...clippingData,
    id: clippingDoc.id,
    date: clippingData.date.toDate(),
  };
}

async function uploadClippingPhoto(cid, photo) {
  const ref = storage.ref().child(`clippings/${cid}`);
  await ref.putString(photo, 'data_url');
  const photoURL = await ref.getDownloadURL();
  return photoURL;
}

/**
 * Add new clipping to users.sites.clippings
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {Object} clipping New clipping to be added
 * @return {String} New clipping ID
 */
export async function clippingsAddClippingAPI(siteRef, clipping) {
  const {
    title, externalURL, date, photo, outlet, tags,
  } = clipping;

  const newClippingRef = await siteRef.collection('clippings').add({
    title,
    externalURL,
    date: firebase.firestore.Timestamp.fromDate(new Date(date)),
    outlet,
    tags,
  });
  const photoURL = await uploadClippingPhoto(newClippingRef.id, photo);
  await newClippingRef.update({ photo: photoURL });
  const newClippingDoc = await newClippingRef.get();
  return processClipping(newClippingDoc);
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @returns {Array} Clippings
 */
export async function clippingsGetClippingsAPI(siteRef, limit) {
  const clippingsQuerySnapshot = await siteRef.collection('clippings').orderBy('date', 'desc').limit(limit).get();
  return clippingsQuerySnapshot.docs
    .map(processClipping)
    .sort((b, a) => a.date - b.date);
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} cid Clipping ID
 * @param {Object} updates Updated fields
 */
export async function clippingsUpdateClippingAPI(siteRef, cid, updates) {
  const clippingRef = siteRef.collection('clippings').doc(cid);
  const clippingDoc = await clippingRef.get();
  if (!clippingDoc.exists) {
    throw new Error('Clipping doesn\' exists.');
  }
  await clippingRef.update(updates);
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {String} cid Clipping ID
 */
export async function clippingsRemoveClippingAPI(siteRef, cid) {
  const clippingRef = siteRef.collection('clippings').doc(cid);
  const clippingDoc = await clippingRef.get();
  if (!clippingDoc.exists) {
    throw new Error('Clipping doesn\' exists.');
  }
  await clippingRef.delete();
}
