import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { authSignInAPI, authSignOutAPI } from '../api/auth';

const useAuth = () => {
  const [errMsg, setErrMsg] = useState('');
  const location = useLocation();
  useEffect(() => {
    setErrMsg('');
  }, [location.pathname]);
  const login = async (email, password) => {
    try {
      await authSignInAPI(email, password);
    } catch (e) {
      setErrMsg(e.message);
    }
  };
  // const signup = async (email, password) => {
  //   try {
  //     const userCred = await presshuntAuth.createUserWithEmailAndPassword(email, password);
  //     await userCred.user.sendEmailVerification();
  //   } catch (e) {
  //     setErrMsg(e.message);
  //   }
  // };
  function logout() {
    window.location.href = (`https://presshunt.co/api/logout?redirectUrl=${window.location.href}`);
    // authSignOutAPI();
  }

  const defaultLogout = async () => {
    authSignOutAPI();
  };

  return {
    login, errMsg, logout, defaultLogout,
  };
};

export default useAuth;
