import { db } from './firebase';

/**
 *
 * @param {String} domain Domain
 * @param {String} language Language code. e.g. "en"
 * @returns {Object} Published site
 */
export const landingPageFetchSiteFromDomainAPI = async (domain, language) => {
  const domainDoc = await db.collection('domains').doc(domain).get();
  if (!domainDoc.exists) {
    throw new Error('Domain doesn\'t exist.');
  }
  const domainData = domainDoc.data();
  const domainSites = domainData.publishedSites;
  const publishedSites = Object.keys(domainSites).reduce((accumulator, key) => {
    if (domainSites[key].published) {
      return { ...accumulator, [key]: domainSites[key] };
    }

    return accumulator;
  }, {});
  const site = domainData.publishedSites?.[language];
  if (!site || !site.published || !site.publishedSite) {
    throw new Error('Site is not published.');
  }
  return {
    ownerId: domainData.ownerRef.id,
    orgId: domainData.orgRef.id,
    siteId: site.siteRef.id,
    publishedSites,
    ...site.publishedSite,
  };
};
