import React from 'react';
import PropTypes from 'prop-types';
import TemplateAboutSocialButton from './TemplateAboutSocialButton';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';
import { getI18N } from '../../config/i18n';

export default function TemplateFooter({ socialMedias, meta, language }) {
  const title = meta.title || 'My Newsroom';
  const now = new Date();
  const nowYear = now.getFullYear();
  const { primaryColor } = useGetTemplateTheme();
  const i18n = getI18N(language);

  return (
    <footer className="bg-white">
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="max-w-md mx-auto text-center">
          <p className="mb-6 text-sm font-semibold text-gray-400">
            &copy;
            {` ${nowYear} ${title} ${i18n.poweredBy} Press Hunt. ${i18n.allRightsReserved}.`}
          </p>
          <div className="flex space-x-2 lg:space-x-4 justify-center">
            {socialMedias.map((socialMedia) => (
              <TemplateAboutSocialButton color={primaryColor} key={socialMedia.id} media={socialMedia.media} url={socialMedia.url} size="sm" bordered={false} isFooter />
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}

TemplateFooter.defaultProps = {
  meta: {},
  language: 'en',
};

TemplateFooter.propTypes = {
  socialMedias: PropTypes.array.isRequired,
  meta: PropTypes.object,
  language: PropTypes.string,
};
