import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/remote-config';

const firebaseConfig = {
  apiKey: 'AIzaSyA88nsEc2kPgBGSM4u9u4CjdYE1hY8NmUs',
  authDomain: 'masonic-1e5a6.firebaseapp.com',
  databaseURL: 'https://masonic-1e5a6.firebaseio.com',
  projectId: 'masonic-1e5a6',
  storageBucket: 'gs://masonic-1e5a6.appspot.com',
  appId: '1:36887463759:web:6f24c0c706b1c30f3aa73a',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const auth = firebaseApp.auth();
export const db = firebaseApp.firestore();
export const storage = firebaseApp.storage();
export const remoteConfig = firebaseApp.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 360000;

export const functions = firebaseApp.functions();
if (process.env.NODE_ENV === 'development') {
  functions.useEmulator('localhost', 5001);
  auth.useEmulator('http://localhost:9099', { disableWarnings: true });
  db.useEmulator('localhost', 8080);
  storage.useEmulator('localhost', 9199);
}

const presshuntFirebaseConfig = {
  apiKey: 'AIzaSyBmk3xkVkYw4HjBhinH4K4enUdLqVCnVQs',
  authDomain: 'presshunt2.firebaseapp.com',
  projectId: 'presshunt2',
};

const presshuntFirebaseApp = firebase.initializeApp(presshuntFirebaseConfig, 'presshunt');

export const presshuntAuth = presshuntFirebaseApp.auth();
