import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import OrgPageTableSectionFormContainer from './OrgPageTableSectionFormContainer';
import { orgUpdateOrgDomainAPI } from '../../api/organizations';
import { useOrg, useUpdateOrgDomain } from '../../contexts/OrgContext';
import { useConfig } from '../../contexts/ConfigContext';

const OrgPageTableSectionFormDomain = ({ orgDomain }) => {
  const { orgRef, org } = useOrg();
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const updateOrgDomain = useUpdateOrgDomain();
  const { config: { reservedDomains } } = useConfig();
  const handleFormSubmit = async (values) => {
    const { domain: newDomain } = values;
    try {
      const newDomainLowerCase = newDomain.toLowerCase();
      await orgUpdateOrgDomainAPI(orgDomain, newDomainLowerCase, orgRef, reservedDomains);
      updateOrgDomain(org.id);
      setErrMsg('');
      setLoading(false);
    } catch (e) {
      setErrMsg(e.message);
      setLoading(false);
    }
  };
  const {
    handleSubmit, register,
  } = useForm();
  return (
    <OrgPageTableSectionFormContainer
      onSubmit={handleSubmit(handleFormSubmit)}
      submitButtonText="Update Domain"
      errMsg={errMsg}
      submitButtonDisabled={loading}
      submitButtonLoading={loading}
    >
      <div className="grid grid-cols-10 items-center">
        <label htmlFor="username" className="block text-sm font-medium text-gray-700 col-span-2">
          Subdomain
        </label>
        <div className="mt-1 col-span-8">
          <div className="max-w-lg flex rounded-md shadow-sm">
            <input
              type="text"
              className="text-right flex-1 block w-full outline-none min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300"
              name="domain"
              ref={register({
                required: 'Required',
              })}
              defaultValue={orgDomain}
            />
            <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              .presshuntnewsroom.com
            </span>
          </div>
        </div>
        {/* <div className="col-span-5 pl-4 flex items-center">
          <p className="text-gray-500 leading-5 text-sm font-light">Your subdomain. blah blah blah blah blah blah</p>
        </div> */}
      </div>
    </OrgPageTableSectionFormContainer>
  );
};

OrgPageTableSectionFormDomain.propTypes = {
  orgDomain: PropTypes.string.isRequired,
};

export default OrgPageTableSectionFormDomain;
