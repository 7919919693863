import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CONTACT, ABOUT, NAVBAR, TEAM, TESTIMONIALS,
} from '../../../utils/templateTypes';
import EditSiteEditorFormNavbar from './EditSiteEditorForm/EditSiteEditorFormNavbar';
import EditSiteEditorFormAbout from './EditSiteEditorForm/EditSiteEditorFormAbout';
import EditSiteEditorFormContact from './EditSiteEditorForm/EditSiteEditorFormContact';
import EditSiteEditorFormTestimonials from './EditSiteEditorForm/EditSiteEditorFormTestimonials';
import EditSiteEditorCell from './EditSiteEditorCell';
import EditSiteEditorFormTeam from './EditSiteEditorForm/EditSiteEditorFormTeam';
import { useRemoveEditSiteSection, useSetEditSiteSectionProperty } from '../../../contexts/EditSiteContext';
import { getDefaultSections } from '../../../contexts/CreateSiteContext';
import { sitesRemoveSectionAPI, sitesSaveSectionConfigAPI } from '../../../api/sites';
import { useSite } from '../../../contexts/SiteContext';

export default function EditSiteEditorItem({ section }) {
  const [changeNotSave, setChangeNotSave] = useState(false);
  const removeEditSiteSection = useRemoveEditSiteSection();
  const { siteRef } = useSite();
  async function handleSectionSaved() {
    await sitesSaveSectionConfigAPI(siteRef, section.id, section.config);
    setChangeNotSave(false);
  }
  async function handleSectionRemoved() {
    await sitesRemoveSectionAPI(siteRef, section.id);
    removeEditSiteSection(section.id);
  }
  const setEditSiteSectionProperty = useSetEditSiteSectionProperty();
  const handleInputChanged = (key) => ({ target: { value } }) => {
    setEditSiteSectionProperty(key, value, section.id);
    setChangeNotSave(true);
  };
  let content;
  const allSections = getDefaultSections();
  const { title, fixed } = allSections[section.type];
  switch (section.type) {
    case NAVBAR:
      content = <EditSiteEditorFormNavbar section={section} onInputChange={handleInputChanged} />;
      break;
    case ABOUT:
      content = <EditSiteEditorFormAbout section={section} onInputChange={handleInputChanged} />;
      break;
    case CONTACT:
      content = <EditSiteEditorFormContact section={section} onInputChange={handleInputChanged} />;
      break;
    case TESTIMONIALS:
      content = <EditSiteEditorFormTestimonials section={section} onInputChange={handleInputChanged} />;
      break;
    case TEAM:
      content = <EditSiteEditorFormTeam section={section} onInputChange={handleInputChanged} />;
      break;
    default: break;
  }
  return (
    <EditSiteEditorCell
      title={title}
      onSave={handleSectionSaved}
      onRemove={fixed ? null : handleSectionRemoved}
      changeNotSave={changeNotSave}
    >
      {content}
    </EditSiteEditorCell>
  );
}

EditSiteEditorItem.propTypes = {
  section: PropTypes.object.isRequired,
};
