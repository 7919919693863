import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';
import { getI18N } from '../../config/i18n';

export default function PublishedBlogTagsSidebar({
  posts, value, onChange, language,
}) {
  const i18n = getI18N(language);
  const { primaryColor } = useGetTemplateTheme();
  const [selectedTag, setSelectedTag] = useState(value);
  const tagsList = [{ id: '', text: 'All' }];
  posts.map((post) => post.tags?.map((tag) => {
    if (!tagsList.find((o) => o.id === tag.id && o.text === tag.text)) {
      tagsList.push({
        id: tag.id,
        text: tag.text,
      });
    }
    return tag;
  }));

  useEffect(() => {
    onChange(selectedTag);
  }, [selectedTag]);

  return (
    <>
      <h4 className="mb-4 text-gray-500 font-bold uppercase">
        {i18n.tags}
      </h4>
      <ul>
        {tagsList.map((tag) => {
          const style = ((selectedTag === null && tag.id === '') || tag.id === selectedTag) ? { color: primaryColor } : null;
          return (
            <li key={tag.id}>
              <span
                style={style}
                onClick={() => { setSelectedTag(tag.id); }}
                aria-hidden="true"
                className="block py-2 px-3 mb-4 rounded font-bold bg-gray-50 cursor-pointer"
              >
                {tag.text}
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
}

PublishedBlogTagsSidebar.defaultProps = {
  value: null,
  language: 'en',
};

PublishedBlogTagsSidebar.propTypes = {
  posts: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  language: PropTypes.string,
};
