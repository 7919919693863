import React from 'react';
import PropTypes from 'prop-types';

const languages = [
  {
    name: 'All languages',
    value: '',
  },
  {
    name: 'English',
    value: 'english',
  },
  {
    name: 'Russian',
    value: 'russian',
  },
  {
    name: 'Arabic',
    value: 'arabic',
  },
  {
    name: 'French',
    value: 'french',
  },
  {
    name: 'Swahili',
    value: 'swahili',
  },
  {
    name: 'Vietnamese',
    value: 'vietnamese',
  },
  {
    name: 'Indonesian',
    value: 'indonesian',
  },
  {
    name: 'Hindi',
    value: 'hindi',
  },
  {
    name: 'Turkish',
    value: 'turkish',
  },
  {
    name: 'Other',
    value: 'other',
  },
];

export default function PublishedBlogPageLanguage({ value, onChange }) {
  return (
    <select
      className="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    >
      {languages.map((language) => <option key={language.value} value={language.value}>{language.name}</option>)}
    </select>
  );
}

PublishedBlogPageLanguage.defaultProps = {
  value: null,
};

PublishedBlogPageLanguage.propTypes = {
  value: PropTypes.oneOf(languages.map((language) => language.value)),
  onChange: PropTypes.func.isRequired,
};
