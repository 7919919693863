import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DefaultButton from '../../DefaultButton';
import EditSiteEditorCellAlert from './EditSiteEditorCellAlert';

export default function EditSiteEditorCellHeading({
  title, onSave, onRemove, changeNotSave,
}) {
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const handleSectionSave = async () => {
    try {
      await onSave();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (e) {
      setFail(true);
      setTimeout(() => {
        setFail(false);
      }, 2000);
    }
  };
  return (
    <div className="bg-white px-5 py-3 border-b border-gray-200">
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0 flex">
          <div className="inline-flex">
            {success && <EditSiteEditorCellAlert variant="success" text="Section saved!" />}
            {fail && <EditSiteEditorCellAlert variant="danger" text="Failed to save section." />}
          </div>
          {onSave && !changeNotSave && <DefaultButton size="small" variant="outline-light" onClick={handleSectionSave}>Save</DefaultButton>}
          {onSave && changeNotSave && <DefaultButton size="small" variant="primary" onClick={handleSectionSave}>Save</DefaultButton>}
          {onRemove && (
          <div className="ml-3">
            <DefaultButton size="small" variant="outline-danger" onClick={onRemove}>Remove</DefaultButton>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

EditSiteEditorCellHeading.defaultProps = {
  onSave: null,
  onRemove: null,
  title: '',
  changeNotSave: false,
};

EditSiteEditorCellHeading.propTypes = {
  title: PropTypes.string,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  changeNotSave: PropTypes.bool,
};
