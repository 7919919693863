import { useEffect, useState } from 'react';

export default (ref) => {
  const [scale, setScale] = useState(0);
  useEffect(() => {
    const refWidth = ref.current ? ref.current.offsetWidth : 0;
    const screenWidth = window.innerWidth;
    setScale(refWidth / screenWidth);
  }, [ref]);

  return scale;
};
