import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useConvertFromEditorRawToHTML from '../../../hooks/useConvertFromEditorRawToHTML';
import { useGetTemplateTheme } from '../../../contexts/TemplateContext';
import './TemplateBlogPreviewMainPost.scss';
import { getI18N } from '../../../config/i18n';

export default function TemplateBlogPreviewMainPost({
  post, showOtherPosts, language, disabled,
}) {
  const [editor, setEditor] = useState();
  const { primaryColor } = useGetTemplateTheme();
  const i18n = getI18N(language);
  useEffect(() => {
    try {
      setEditor(post && (post.editorStringified ? JSON.parse(post.editorStringified) : post.editor));
    } catch {
      setEditor(null);
    }
  }, [post]);
  const markup = useConvertFromEditorRawToHTML(editor);
  return (
    <div
      className={`${showOtherPosts ? 'lg:w-1/2' : 'lg:w-3/4'} mb-6 lg:mb-0 w-full px-3`}
      style={{
        'max-height': '760px',
      }}
    >
      <Link to={disabled ? '#' : `/${language}/press-releases/${post.id}`}>
        <div className="h-full flex flex-col rounded shadow">
          {post.cover && (
            <img className="rounded-t object-cover h-80 lg:h-full w-full" src={post.cover} alt="" />
          )}
          <div className="mt-auto p-6 rounded-b bg-white">
            <span className="text-sm text-gray-400">{moment(post.scheduledReleaseTime).format('DD MMMM, YYYY')}</span>
            <h2 className="my-2 text-2xl font-bold">{post.title}</h2>
            <div
              className="relative max-h-24 overflow-hidden"
              style={{
                overflow: 'hidden',
                display: '-webkit-box',
                'text-overflow': 'ellipsis',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                'white-space': 'normal',
                'min-height': '64px',
              }}
            >
              <div className="main-post-container mb-6 text-gray-400 leading-loose" dangerouslySetInnerHTML={markup} />
            </div>
            <Link style={{ color: primaryColor }} className="font-bold" to={disabled ? '#' : `/${language}/press-releases/${post.id}`}>
              {i18n.learnMore}
            </Link>
          </div>
        </div>
      </Link>
    </div>
  );
}

TemplateBlogPreviewMainPost.propTypes = {
  post: PropTypes.object.isRequired,
  showOtherPosts: PropTypes.bool,
  language: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

TemplateBlogPreviewMainPost.defaultProps = {
  showOtherPosts: false,
  language: 'en',
};
