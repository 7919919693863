import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import SitePageBreadCrumbs from '../SitePageBreadCrumbs';
import SitePageNavbarSiteButtons from './SitePageNavbarSiteButtons';
import SitePageNavbarMediaAssetsButtons from './SitePageNavbarMediaAssetsButtons';
import SitePageNavbarCoverageButtons from './SitePageNavbarCoverageButtons';
import SitePageNavbarPostButtons from './SitePageNavbarPostButtons';
import SitePageNavbarBlogButtons from './SitePageNavbarBlogButtons';

export const SiteRoutePages = {
  SITE: 'SITE',
  MEDIA_ASSETS: 'MEDIA_ASSETS',
  COVERAGE: 'COVERAGE',
  BLOG: 'BLOG',
  POST: 'POST',
};

export default function SitePageNavbar({ crumbs, page }) {
  let buttonGroup = null;
  switch (page) {
    case SiteRoutePages.SITE:
      buttonGroup = <SitePageNavbarSiteButtons />;
      break;
    case SiteRoutePages.MEDIA_ASSETS:
      buttonGroup = <SitePageNavbarMediaAssetsButtons />;
      break;
    case SiteRoutePages.COVERAGE:
      buttonGroup = <SitePageNavbarCoverageButtons />;
      break;
    case SiteRoutePages.POST:
      buttonGroup = <SitePageNavbarPostButtons />;
      break;
    case SiteRoutePages.BLOG:
      buttonGroup = <SitePageNavbarBlogButtons />;
      break;
    default:
      break;
  }
  return (
    <nav className="bg-white border-b border-gray-200" aria-label="Breadcrumb">
      <Helmet>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="relative h-12 items-center flex justify-between px-4 space-x-4 sm:px-6 lg:px-8">
        <SitePageBreadCrumbs crumbs={crumbs} />
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 space-x-2">
          {buttonGroup}
        </div>
      </div>
    </nav>
  );
}

SitePageNavbar.defaultProps = {
  crumbs: [],
};

SitePageNavbar.propTypes = {
  crumbs: PropTypes.array,
  page: PropTypes.string.isRequired,
};
