import { db } from './firebase';

/** @typedef { import('firebase').default.firestore.DocumentReference } DocumentReference */

/**
 *
 * @param {DocumentReference} orgRef Organization Doc Ref
 */
export async function domainsCreateDomainAPI(orgRef) {
  const userRef = orgRef.parent.parent;
  await db.collection('domains').doc(orgRef.id).set({
    ownerRef: userRef,
    orgRef,
  });
}

/**
 *
 * @param {String} domainName Domain name
 * @param {Object} publishedSite Site object to be published
 */
export async function domainsPublishSiteAPI(domainName, siteRef, publishedSite) {
  const domainRef = db.collection('domains').doc(domainName);
  const domainDoc = await domainRef.get();
  if (!domainDoc.exists) {
    throw new Error('Domain doesn\'t exist.');
  }

  const domainData = domainDoc.data();
  const domainPublishedSites = (domainData.publishedSites) ? domainData.publishedSites : {};
  const { language } = publishedSite;

  domainPublishedSites[language] = {
    siteRef,
    publishedSite,
    published: true,
  };
  await domainRef.update({
    publishedSites: domainPublishedSites,
  });
}

/**
 *
 * @param {String} domainName Domain name
 * @param {String} language Language code
 */
export async function domainsUnpublishSiteAPI(domainName, language) {
  const domainRef = db.collection('domains').doc(domainName);
  const domainDoc = await domainRef.get();
  if (!domainDoc.exists) {
    throw new Error('Domain doesn\'t exist.');
  }
  const key = `publishedSites.${language}.published`;
  await domainRef.update(key, false);
}
