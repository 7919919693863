import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { isValidHttpUrl } from '../../utils';

const buttonDefaultClasses = 'content-center items-center bg-white text-sm font-medium hover:bg-gray-50 focus:outline-none';

const mediaIconMap = {
  facebook: 'fab fa-facebook-f',
  twitter: 'fab fa-twitter',
  youtube: 'fab fa-youtube',
  linkedin: 'fab fa-linkedin-in',
  instagram: 'fab fa-instagram',
  officialWebsite: 'fa fa-window-maximize',
};

export default function TemplateAboutSocialButton({
  color, url, media, size, bordered, noBottomBorder, isFooter, isNavBar,
}) {
  function handleClick() {
    if (isValidHttpUrl(url)) {
      window.open(url, '_blank');
    }
  }
  let buttonSizeClasses = '';
  let iconSizeClasses = '';
  let customIconSizeClasses = '';
  if (size === 'sm') {
    buttonSizeClasses = 'w-6 h-6';
    iconSizeClasses = 'fa-lg px-4';
    customIconSizeClasses = 'w-6 h-6';
  } else if (size === 'md') {
    buttonSizeClasses = 'w-full h-12';
    iconSizeClasses = 'fa-2x px-4';
    customIconSizeClasses = 'w-8 h-8';
  }
  let borderClass = '';
  if (bordered) {
    borderClass = 'border border-gray-300';
  }

  if (noBottomBorder) {
    borderClass += ' border-b-0';
  }

  if (isNavBar) {
    return (
      <Link onClick={handleClick} style={{ color }} className="inline-block px-1">

        {media === 'officialWebsite' ? (
          null
        ) : (
          <i style={{ display: 'flex', alignItems: 'center', color }} className={classNames(iconSizeClasses, mediaIconMap[media])} />
        )}
      </Link>
    );
  }

  if (isFooter) {
    return (
      <button onClick={handleClick} style={{ color }} className={classNames(buttonDefaultClasses, buttonSizeClasses, borderClass)}>

        {media === 'officialWebsite' ? (
          <svg xmlns="http://www.w3.org/2000/svg" className={classNames(customIconSizeClasses)} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
          </svg>
        ) : (
          <i style={{ display: 'flex', alignItems: 'center' }} className={classNames(iconSizeClasses, mediaIconMap[media])} />
        )}
      </button>
    );
  }

  if (media === 'officialWebsite') {
    return (
      <button onClick={handleClick} style={{ position: 'relative', color }} className={classNames(buttonDefaultClasses, buttonSizeClasses, borderClass, 'flex justify-between')}>
        <span className="text-left px-4">
          Website
        </span>
        <i style={{ display: 'flex', alignItems: 'center', 'font-size': '16px' }} className={classNames(iconSizeClasses, 'fa fa-external-link-alt')} />
      </button>
    );
  }

  return (
    <button onClick={handleClick} style={{ position: 'relative', color }} className={classNames(buttonDefaultClasses, buttonSizeClasses, borderClass)}>
      <span className="grid grid-cols-5 gap-4">
        <i style={{ display: 'flex', alignItems: 'center', 'font-size': '16px' }} className={classNames(iconSizeClasses, mediaIconMap[media])} />
        <span className="text-left px-4">
          {media}
        </span>
      </span>
    </button>
  );
}

TemplateAboutSocialButton.defaultProps = {
  media: 'facebook',
  size: 'md',
  bordered: true,
  noBottomBorder: false,
  isNavBar: false,
  isFooter: false,
};

TemplateAboutSocialButton.propTypes = {
  color: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  media: PropTypes.oneOf(['facebook', 'twitter', 'youtube', 'linkedin', 'instagram', 'officialWebsite']),
  bordered: PropTypes.bool,
  noBottomBorder: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md']),
  isNavBar: PropTypes.bool,
  isFooter: PropTypes.bool,
};
