import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
  }
`;

function Input({
  onClick, value, customPlaceholder, className,
}) {
  return (
    <input
      value={value}
      onClick={onClick}
      onChange={() => {}}
      type="text"
      placeholder={customPlaceholder}
      className={className}
    />
  );
}

Input.defaultProps = {
  onClick: null,
  customPlaceholder: 'Date',
  className: '',
};

Input.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string.isRequired,
  customPlaceholder: PropTypes.string,
  className: PropTypes.string,
};

export default function EditSiteEditorFormUIProfileDatePicker({
  onChange, value, placeholder, className,
}) {
  return (
    <DatePickerStyled>
      <DatePicker
        selected={Date.parse(value) ? new Date(value) : null}
        onChange={(date) => onChange({ target: { value: moment(date).format('L') } })}
        customInput={<Input value={value} customPlaceholder={placeholder} className={className} />}
        placeholder="Date"
      />
    </DatePickerStyled>
  );
}

EditSiteEditorFormUIProfileDatePicker.defaultProps = {
  value: null,
  className: '',
};

EditSiteEditorFormUIProfileDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
};
