import React from 'react';
import PropTypes from 'prop-types';
import { useSetTemplateWithArray } from '../contexts/TemplateContext';
import Template from './Template/Template';
import { useAuth } from '../contexts/AuthContext';
import { useOrg } from '../contexts/OrgContext';

export default function StaticSitePreview({ site }) {
  const { sections } = site.pages.home;
  const { user: { uid } } = useAuth();
  const { org: { id: oid } } = useOrg();
  const coveragePageEnabled = !!(site.pages.coverage && site.pages.coverage.enabled);
  const pressReleasesPageEnabled = !!(site.pages.pressReleases && site.pages.pressReleases.enabled);
  useSetTemplateWithArray(sections, site.meta, site.theme, uid, oid, site.id, coveragePageEnabled, pressReleasesPageEnabled, site.language);
  return (
    <Template disabled />
  );
}

StaticSitePreview.propTypes = {
  site: PropTypes.object.isRequired,
};
