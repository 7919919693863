import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const defaultClasses = 'border py-10 text-md font-medium rounded text-gray-700 focus:outline-none transition ease-in-out duration-150';

const getBorderClasses = (selected) => (selected ? 'border-indigo-700' : 'border-gray-300 hover:border-gray-400');

export default function EditorBoxButton({ title, onClick, selected }) {
  const classes = classNames(defaultClasses, getBorderClasses(selected));
  return (
    <button
      type="button"
      className={classes}
      style={{ width: 150 }}
      onMouseDown={(e) => e.preventDefault()}
      onClick={onClick}
    >
      {title}
    </button>
  );
}

EditorBoxButton.defaultProps = {
  onClick: null,
  selected: false,
};

EditorBoxButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};
