import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { postsGetPostsAPI } from '../../api/posts';
import { useTemplate, useGetTemplateTheme } from '../../contexts/TemplateContext';
import TemplateBlogPreviewMainPost from './TemplateBlogPreviewMainPost/TemplateBlogPreviewMainPost';
import { useOrg } from '../../contexts/OrgContext';
import { refsGetSiteRefByPath } from '../../api/refs';
import { getI18N } from '../../config/i18n';

const TitleStyled = styled.div`
  min-height:64px;

  @media (max-width: 640px) {
    min-height: 48px;
  }
`;

export default function TemplateBlogPreview({ language, disabled }) {
  const i18n = getI18N(language);
  const [posts, setPosts] = useState([]);
  const [mainPost, setMainPost] = useState();
  const [otherPosts, setOtherPosts] = useState([]);
  const {
    template: {
      userId, orgId, siteId, pressReleasesPageEnabled,
    },
  } = useTemplate();
  const { primaryColor } = useGetTemplateTheme();
  const { orgRef } = useOrg();
  useEffect(() => {
    const siteRef = refsGetSiteRefByPath(userId, orgId, siteId);
    async function fetch() {
      if (pressReleasesPageEnabled) {
        const tmpPosts = await postsGetPostsAPI(siteRef);
        setPosts(tmpPosts.filter((post) => post.published).filter((post) => (post.scheduledReleaseTime || post.createTime) <= new Date()));
      }
    }
    fetch();
  }, [userId, orgId, siteId, pressReleasesPageEnabled, orgRef]);
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  useEffect(() => {
    const tmpPosts = posts.filter((post) => !(post?.archived));
    if (!isMobile) {
      setMainPost(tmpPosts.shift());
    }
    setOtherPosts(tmpPosts.slice(0, 4));
  }, [isMobile, posts, setMainPost]);

  const showOtherPosts = (isMobile || otherPosts.length === 4);

  return pressReleasesPageEnabled ? (
    <div className="lg:py-20 py-10 bg-gray-50 radius-for-skewed">
      <div className="max-w-7xl mx-auto px-4">
        <div className="mb-6 flex flex-wrap justify-center">
          <div className="lg:mb-16 mb-10 w-full text-center">
            <span style={{ color: primaryColor }} className="font-bold">{i18n.recentPressReleases}</span>
            <h2 className="text-4xl lg:text-5xl font-bold font-heading">{i18n.newsroom}</h2>
          </div>
          <div className="flex flex-wrap -mx-3 mb-16 w-full justify-center">
            {mainPost && (
              <TemplateBlogPreviewMainPost post={mainPost} showOtherPosts={showOtherPosts} language={language} disabled={disabled} />
            )}
            {showOtherPosts
              && (
              <div className="flex flex-wrap w-full lg:w-1/2 w-2/5">
                {otherPosts.map((post) => (
                  <div className="mb-6 w-full lg:w-1/2 w-2/5 px-3">
                    <Link to={disabled ? '#' : `/${language}/press-releases/${post.id}`}>
                      <div className="rounded overflow-hidden shadow">
                        <div style={{
                          height: '192px',
                          width: '288px',
                          position: 'relative',
                          margin: 'auto',
                        }}
                        >
                          <img
                            className="lg:h-48 rounded-t mx-auto"
                            style={{
                              'max-height': '100%',
                              'max-width': '100%',
                              width: 'auto',
                              height: 'auto',
                              position: 'absolute',
                              top: '0',
                              bottom: '0',
                              left: '0',
                              right: '0',
                              margin: 'auto',
                            }}
                            src={post.cover}
                            alt=""
                          />

                        </div>
                        <div className="lg:p-6 px-6 py-3 rounded-b bg-white">
                          <span className="text-sm text-gray-400">{moment(post.scheduledReleaseTime).format('DD MMMM, YYYY')}</span>
                          <h2
                            className="my-2 lg:text-2xl text-lg font-bold"
                            style={{
                              overflow: 'hidden',
                              display: '-webkit-box',
                              'text-overflow': 'ellipsis',
                              '-webkit-line-clamp': '2',
                              '-webkit-box-orient': 'vertical',
                              'white-space': 'normal',
                            }}
                          >
                            <TitleStyled>
                              {post.title}
                            </TitleStyled>
                          </h2>
                          <Link style={{ color: primaryColor }} className="font-bold" to={disabled ? '#' : `/${language}/press-releases/${post.id}`}>
                            {i18n.learnMore}
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
              )}
          </div>
          <div>
            <Link
              style={{ 'background-color': primaryColor }}
              to={disabled ? '#' : `${language}/press-releases`}
              className="inline-block py-2 px-6 rounded-l-xl rounded-t-xl text-gray-100 font-bold leading-loose outline-none transition duration-200"
            >
              {i18n.viewMorePressReleases}
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

TemplateBlogPreview.defaultProps = {
  language: 'en',
};

TemplateBlogPreview.propTypes = {
  language: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};
