import React, { useRef } from 'react';
import useGetPreviewScale from '../../hooks/useGetPreviewScale';

import PreviewFrame from '../PreviewFrame';

const EditSitePreviewColumn = () => {
  const ref = useRef(null);
  const scale = useGetPreviewScale(ref);
  return (
    <div
      className="border border-gray-300 rounded"
      style={{
        width: 'calc(100vw - 650px)',
        height: 'calc(100vh - 72px)',
        overflow: 'hidden',
        position: 'fixed',
        right: 0,
        top: 60,
      }}
    >
      <div ref={ref} className="bg-white" style={{ height: '100%' }}>
        {ref && scale > 0 && <PreviewFrame mode="edit" scale={scale} />}
      </div>
    </div>
  );
};

export default EditSitePreviewColumn;
