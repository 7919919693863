import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useGetPreviewScale from '../../hooks/useGetPreviewScale';
import PreviewFrame from '../PreviewFrame';

export default function SitePagePreview({ site }) {
  const previewColumnRef = useRef(null);
  const scale = useGetPreviewScale(previewColumnRef);

  return (
    <div className="shadow mt-5" style={{ height: 600 }} ref={previewColumnRef}>
      <PreviewFrame site={site} scale={scale} mode="static" />
    </div>
  );
}

SitePagePreview.propTypes = {
  site: PropTypes.object.isRequired,
};
