import React, { useEffect, useState } from 'react';
import { orgGetUserOrgsAPI } from '../api/organizations';
import { useAuth } from '../contexts/AuthContext';
import DefaultButton from './DefaultButton';
import OrgCreateDialog from './Org/OrgCreateDialog';
import OrgsList from './Org/OrgList';

export default function HomePage() {
  const { userRef } = useAuth();
  const [orgs, setOrgs] = useState([]);
  const [orgDialogOpen, setOrgDialogOpen] = useState(false);
  useEffect(() => {
    async function fetch() {
      const tmpOrgs = await orgGetUserOrgsAPI(userRef);
      setOrgs(tmpOrgs);
    }
    fetch();
  }, [userRef]);
  return (
    <div className="py-6 px-8">
      <DefaultButton onClick={() => setOrgDialogOpen(true)}>Create Organization</DefaultButton>
      <OrgCreateDialog open={orgDialogOpen} onClose={() => setOrgDialogOpen(false)} orgs={orgs} />

      <OrgsList orgs={orgs} />
    </div>
  );
}
