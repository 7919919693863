import { useEffect, useState } from 'react';
import { getAllLanguage } from '../config/language';
import { useConfig } from '../contexts/ConfigContext';

const useGetDomains = () => {
  const { host } = window.location;
  const [subdomain, setSubdomain] = useState('');
  const [mainDomain, setMainDomain] = useState('');
  const [checked, setChecked] = useState(false);
  const [lang, setLang] = useState('en');
  const { config } = useConfig();
  const { hosts } = config;

  useEffect(() => {
    for (let i = 0; i < hosts.length; i += 1) {
      const idx = host.lastIndexOf(hosts[i]);
      if (idx >= 0) {
        setSubdomain(host.substring(0, idx).split('.')[0]);
        setMainDomain(hosts[i]);
        setChecked(true);
        break;
      }
    }
  }, [host, hosts]);

  useEffect(() => {
    const urlLang = typeof document === 'undefined'
      ? ''
      : window.location.pathname.split('/')?.[1];
    const languages = getAllLanguage();
    const isVaildLang = languages.some((item) => item.value === urlLang);
    const currentLang = (isVaildLang) ? urlLang : 'en';
    setLang(currentLang);
  }, []);

  return {
    subdomain, checked, mainDomain, lang,
  };
};

export default useGetDomains;
