import React from 'react';
import PropTypes from 'prop-types';

export default function EditSiteEditorFormUISubtitle({ children }) {
  return (
    <h3 className="text-md leading-6 font-medium text-gray-900">
      {children}
    </h3>
  );
}

EditSiteEditorFormUISubtitle.propTypes = {
  children: PropTypes.string.isRequired,
};
