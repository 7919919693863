import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

export default function PublishedAssetFolderCard({ asset }) {
  const { pathname } = useLocation();
  const preview = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-full h-48 text-blue-300">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
    </svg>
  );
  return (
    <div className="mb-8 w-full md:w-1/2 lg:w-1/4 px-4">
      <div className="bg-white rounded">
        <Link to={`${pathname}/${asset.id}`} rel="noopener noreferrer">
          {preview}
        </Link>
        <div className="p-6">
          <h3 className="overflow-ellipsis whitespace-nowrap overflow-hidden flex-grow mb-4 font-bold font-heading">{asset.folderName}</h3>
        </div>
      </div>
    </div>
  );
}

PublishedAssetFolderCard.propTypes = {
  asset: PropTypes.object.isRequired,
};
