import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import { db } from './firebase';
import { domainsCreateDomainAPI } from './domains';

/** @typedef { import('firebase').default.firestore.DocumentSnapshot } DocumentSnapshot */
/** @typedef { import('firebase').default.firestore.DocumentReference } DocumentReference */

/**
 *
 * @param {DocumentSnapshot} orgDoc Organization document snapshot
 */
function processOrg(orgDoc) {
  const orgData = orgDoc.data();
  return {
    id: orgDoc.id,
    domain: orgData.domain,
    creationDate: orgData.creationDate.toDate(),
    name: orgData.name,
  };
}

/**
 * Creates a new organization.
 * @param {DocumentReference} userRef User Doc Ref
 * @param {String} name Organization name
 * @returns {Object} Organization
 */
export async function orgCreateOrgAPI(userRef, name) {
  const oid = uuidv4().split('-').pop();
  const newOrgRef = userRef.collection('organizations').doc(oid);
  const newOrgData = {
    domain: oid,
    creationDate: firebase.firestore.FieldValue.serverTimestamp(),
    name,
  };
  await Promise.all([
    newOrgRef.set(newOrgData),
    domainsCreateDomainAPI(newOrgRef),
  ]);
  const newOrgDoc = await newOrgRef.get();
  return processOrg(newOrgDoc);
}

/**
 * Returns a list of organizations owned by an user.
 * @param {DocumentReference} userRef User Doc Ref
 * @returns {Array<Object>} List of organizations
 */
export async function orgGetUserOrgsAPI(userRef) {
  const orgsQuerySnapshot = await userRef.collection('organizations').orderBy('creationDate', 'desc').get();
  return orgsQuerySnapshot.docs.map(processOrg);
}

/**
 * Returns an organization.
 * @param {DocumentReference} orgRef Organization Doc Ref
 * @returns Organization
 */
export async function orgGetOrgAPI(orgRef) {
  const orgDoc = await orgRef.get();
  if (!orgDoc.exists) {
    throw new Error('Organization doesn\'t exist.');
  }
  return processOrg(orgDoc);
}

/**
 *
 * @param {String} oldDomain Old Domain
 * @param {String} newDomain New Domain
 * @param {orgRef} orgRef Organization Doc Ref
 * @param {Set<String>} reservedDomains Set of reserved domains
 */
export const orgUpdateOrgDomainAPI = async (oldDomain, newDomain, orgRef, reservedDomains) => {
  if (reservedDomains.has(newDomain)) {
    throw new Error('This subdomain is unavailable.');
  }
  const newDomainDoc = await db.collection('domains').doc(newDomain).get();
  if (newDomainDoc.exists) {
    throw new Error('This subdomain is unavailable.');
  }
  const oldDomainDoc = await db.collection('domains').doc(oldDomain).get();
  if (!oldDomainDoc.exists) {
    throw new Error('Old domain doesn\'t exist');
  }
  const oldDomainData = oldDomainDoc.data();
  await Promise.all([
    newDomainDoc.ref.set(oldDomainData),
    orgRef.update({ domain: newDomain }),
    oldDomainDoc.ref.delete(),
  ]);
};
