import React from 'react';
import PropTypes from 'prop-types';

const languages = [
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Russian',
    value: 'ru',
  },
  {
    name: 'Arabic',
    value: 'ar',
  },
  {
    name: 'French',
    value: 'fr',
  },
  {
    name: 'Swahili',
    value: 'sw',
  },
  {
    name: 'Vietnamese',
    value: 'vi',
  },
  {
    name: 'Indonesian',
    value: 'id',
  },
  {
    name: 'Hindi',
    value: 'hi',
  },
  {
    name: 'Turkish',
    value: 'tr',
  },
];

export default function SitePageLanguage({ defaultValue, onChange }) {
  return (
    <select
      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      value={defaultValue}
      onChange={({ target: { value } }) => onChange(value.toLowerCase())}
      disabled="disabled"
    >
      {languages.map((language) => <option key={language.value} value={language.value}>{language.name}</option>)}
    </select>
  );
}

SitePageLanguage.propTypes = {
  defaultValue: PropTypes.oneOf(languages.map((language) => language.value)).isRequired,
  onChange: PropTypes.func.isRequired,
};
