import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { mediaAssetsUploadAssetAPI } from '../../../api/media-assets';
import { useAppendMediaAsset } from '../../../contexts/MediaAssetsContext';
import { useSite } from '../../../contexts/SiteContext';
import SiteAssetsFolderNameModal from '../../SiteAssetsPage/SiteAssetsFolderNameModal';
import DefaultButton from '../../DefaultButton';

export default function SitePageNavbarMediaAssetsButtons() {
  const [isFolderNameModalOpen, setIsFolderNameModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { siteRef } = useSite();
  const { params: { assetId } } = useRouteMatch();
  const fileInputRef = useRef();
  function handleUploadFileClicked() {
    fileInputRef.current.click();
  }
  const appendMediaAsset = useAppendMediaAsset();
  const handleFileChanged = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = async () => {
      setLoading(true);
      try {
        const newAsset = await mediaAssetsUploadAssetAPI(siteRef, reader.result, file.name, assetId);
        appendMediaAsset(newAsset);
      } finally {
        setLoading(false);
      }
    };
    reader.readAsDataURL(file);
  };
  const handleAddFolderClicked = () => {
    setIsFolderNameModalOpen(true);
  };
  return (
    <>
      <DefaultButton
        onClick={handleAddFolderClicked}
        disabled={loading || assetId !== undefined}
      >
        Add Folder
      </DefaultButton>
      <DefaultButton
        loading={loading}
        disabled={loading}
        onClick={handleUploadFileClicked}
      >
        Upload File
      </DefaultButton>
      <input
        type="file"
        accept="image/*,video/*,.pdf,.ppt,.pptx"
        className="hidden"
        ref={(fileInput) => { fileInputRef.current = fileInput; }}
        onChange={handleFileChanged}
      />
      <SiteAssetsFolderNameModal
        isOpen={isFolderNameModalOpen}
        closeModal={() => setIsFolderNameModalOpen(false)}
      />
    </>
  );
}
