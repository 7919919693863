import React from 'react';
import PropTypes from 'prop-types';
import OrgCard from './OrgCard';

export default function OrgList({ orgs }) {
  return (
    <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {orgs.map((org) => (
        <OrgCard key={org.id} org={org} />
      ))}
    </ul>
  );
}

OrgList.propTypes = {
  orgs: PropTypes.array.isRequired,
};
