import React, { useEffect, useState } from 'react';
import { mediaAssetsGetAssetsAPI } from '../../api/media-assets';
import { refsGetSiteRefByPath } from '../../api/refs';
import { usePublishedSite } from '../../contexts/Published/PublishedSiteContext';
import { siteAdditionalPages } from '../SitePage/SitePage';
import Template from '../Template/Template';
import PublishedAssetCard from './PublishedAssetCard';
import PublishedAssetFolderCard from './PublishedAssetFolderCard';
import PublishedNotFound from './PublishedNotFound';
import { getI18N } from '../../config/i18n';
import { useLanguage } from '../../contexts/LanguageContext';

export default function PublishedAssetsPage() {
  const { language } = useLanguage();
  const i18n = getI18N(language);
  const { site } = usePublishedSite();
  const [assets, setAssets] = useState([]);
  useEffect(() => {
    const siteRef = refsGetSiteRefByPath(site.ownerId, site.orgId, site.siteId);
    async function fetch() {
      const tmpAssets = await mediaAssetsGetAssetsAPI(siteRef);
      setAssets(tmpAssets);
    }
    fetch();
  }, [setAssets, site.ownerId, site.orgId, site.siteId]);
  const pageName = siteAdditionalPages.mediaAssets.code;
  if (!(site.pages[pageName] && site.pages[pageName].enabled)) {
    return <PublishedNotFound />;
  }
  return (
    <Template>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="max-w-7xl mx-auto px-4">
          <div className="mb-16 flex flex-wrap justify-center md:justify-between items-center">
            <div className="text-center lg:text-left">
              <h2 className="text-3xl lg:text-5xl font-bold font-heading">
                {i18n.mediaAssets}
              </h2>
            </div>
          </div>
          <div className="flex flex-wrap -mx-4 mb-4">
            {assets.map((asset) => {
              if (asset && asset.isFolder !== undefined && asset.isFolder) {
                return (
                  <PublishedAssetFolderCard key={asset.id} asset={asset} />
                );
              }
              return (
                <PublishedAssetCard key={asset.id} asset={asset} />
              );
            })}
          </div>
        </div>
      </div>
    </Template>
  );
}
