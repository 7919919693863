import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { postsUpdatePostAPI, postUploadCoverPhotoAPI, postsArchivePostAPI } from '../../api/posts';
import {
  tagsCreateTagAPI, tagsGetTagAPI, tagsUpdateTagAPI, tagsDeleteTagAPI,
} from '../../api/tags';
import { useAuth } from '../../contexts/AuthContext';
import { useFetchPost, usePost, useUpdatePost } from '../../contexts/PostContext';
import { useSite } from '../../contexts/SiteContext';
import SitePostPageCell from './SitePostPageCell';
import SitePostPageDraft from './SitePostPageDraft';
import SitePostPageCoverImage from './SitePostPageCoverImage';
import SitePostPageTitle from './SitePostPageTitle';
import SitePageNavbar, { SiteRoutePages } from '../SitePage/SitePageNavbar/SitePageNavbar';
import SitePostPageLanguage from './SitePostPageLanguage';
import SitePostPageDateTimePicker from './SitePostPageDateTimePicker';
import SitePostPageTagInput from './SitePostPageTagInput';
import SitePostPageArchiveModal from './SitePostPageArchiveModal';
import { useOrg } from '../../contexts/OrgContext';

export default function SitePostPage() {
  const [saving, setSaving] = useState({});
  const { params: { postId } } = useRouteMatch();
  const { user: { uid } } = useAuth();
  const { site, site: { id: sid } } = useSite();
  const { postRef, setIsArchiveModalOpen } = usePost();
  const { org } = useOrg();
  const history = useHistory();
  const oid = org.id;
  useFetchPost(postId);
  const updatePostContext = useUpdatePost();
  const { post } = usePost();
  const setSavingWithKey = (key, value) => {
    setSaving((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updatePost = (key) => async (fields) => {
    if (postRef) {
      await postsUpdatePostAPI(postRef, fields);
      setSavingWithKey(key, false);
      updatePostContext(fields);
    }
  };

  const isAnySaving = !!Object.keys(saving).map((key) => saving[key]).find((value) => value);

  async function uploadImage(image) {
    const coverPhotoUrl = await postUploadCoverPhotoAPI(postId, image);
    await updatePost('cover')({ cover: coverPhotoUrl });
  }

  async function updateLanguage(language) {
    updatePost('language')({ language });
  }

  async function updateScheduledReleaseTime(scheduledReleaseTime) {
    updatePost('scheduledReleaseTime')({ scheduledReleaseTime });
  }

  async function addTag(tag, existTag) {
    if (existTag.exists) {
      await tagsUpdateTagAPI(uid, oid, sid, tag.id, { count: existTag.count + 1 });
    } else {
      await tagsCreateTagAPI(uid, oid, sid, tag.id);
    }
  }

  async function removeTag(tag, existTag) {
    if (existTag.exists) {
      if (existTag.count > 1) {
        await tagsUpdateTagAPI(uid, oid, sid, tag.id, { count: existTag.count - 1 });
      } else {
        await tagsDeleteTagAPI(uid, oid, sid, tag.id);
      }
    }
  }

  /**
 *
 * @param {boolean} method addition is true | remove is false
 */
  async function updateTags(tags, tag, method) {
    await updatePost('tags')({ tags });
    const existTag = await tagsGetTagAPI(uid, oid, sid, tag.id);
    if (method) {
      await addTag(tag, existTag);
    } else {
      await removeTag(tag, existTag);
    }
  }

  const [editor, setEditor] = useState();
  const [editorChecked, setEditorChecked] = useState(false);
  useEffect(() => {
    if (post) {
      try {
        setEditor(post && (post.editorStringified ? JSON.parse(post.editorStringified) : post.editor));
      } catch {
        setEditor(null);
      } finally {
        setEditorChecked(true);
      }
    }
  }, [post]);

  async function handlePostArchive() {
    setIsArchiveModalOpen(false);
    await postsArchivePostAPI(postRef, true);
    history.push(`/organization/${org.id}/site/${sid}/press-releases`);
  }

  return (
    <>
      <SitePageNavbar
        crumbs={[
          { name: org.name, path: `/organization/${org.id}` },
          { name: site.meta.title, path: `/organization/${org.id}/site/${sid}` },
          { name: 'Press Releases', path: `/organization/${org.id}/site/${sid}/press-releases` },
          { name: post && post.title },
        ]}
        page={SiteRoutePages.POST}
      />
      {(!!post && !(post?.archived)) && (
      <div className="w-full flex justify-center bg-gray-50">
        <div className="w-full max-w-screen-xl px-3 xl:px-0 py-6">
          <div className="grid grid-cols-7 gap-4">
            <div className="col-span-5">
              <SitePostPageCell>
                <div className="mb-4">
                  <SitePostPageTitle title={post.title} updatePost={updatePost('title')} setSaving={() => setSavingWithKey('title', true)} />
                </div>
                {editorChecked && <SitePostPageDraft contentState={editor} updatePost={updatePost('draft')} setSaving={() => setSavingWithKey('draft', true)} />}
              </SitePostPageCell>
            </div>
            <div className="col-span-2">
              <SitePostPageCell>
                <h3 className="text-gray-400">
                  {isAnySaving ? 'Saving...' : 'Saved'}
                </h3>
              </SitePostPageCell>
              <div className="mt-4">
                <SitePostPageCell>
                  <div className="mb-4">
                    <h6 className="mb-2">Cover photo</h6>
                    <SitePostPageCoverImage uploadImage={uploadImage} currentUploadedImage={post.cover} />
                  </div>
                  <div className="mb-4">
                    <h6 className="mb-2">Language</h6>
                    <SitePostPageLanguage onChange={updateLanguage} defaultValue={post.language} />
                  </div>
                  <h6 className="mb-2">Post Active Date</h6>
                  <SitePostPageDateTimePicker onChange={updateScheduledReleaseTime} defaultValue={post.scheduledReleaseTime} />
                  <h6 className="mb-2">Tags</h6>
                  <SitePostPageTagInput onChange={updateTags} defaultValue={post.tags ?? []} uid={uid} sid={sid} oid={oid} />
                </SitePostPageCell>
              </div>
            </div>
          </div>

        </div>
        <SitePostPageArchiveModal postArchive={handlePostArchive} />
      </div>
      )}
    </>
  );
}
