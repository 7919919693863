import React from 'react';
import PropTypes from 'prop-types';
import DefaultButton from '../../../DefaultButton';

const SitePageTableSectionFormContainer = ({
  onSubmit, submitButtonText, children, errMsg, submitButtonVariant, submitButtonDisabled, submitButtonLoading,
}) => (
  <form onSubmit={onSubmit}>
    {children}
    {errMsg && (
      <div className="rounded-md bg-red-50 p-4 mt-5">
        <h3 className="text-sm font-medium text-red-800">
          {errMsg}
        </h3>
      </div>
    )}
    <div className="mt-5 flex justify-end">
      <DefaultButton
        type="submit"
        variant={submitButtonVariant}
        disabled={submitButtonDisabled}
        loading={submitButtonLoading}
      >
        {submitButtonText}

      </DefaultButton>
    </div>
  </form>
);

SitePageTableSectionFormContainer.defaultProps = {
  onSubmit: null,
  submitButtonText: '',
  children: null,
  errMsg: '',
  submitButtonVariant: null,
  submitButtonDisabled: false,
  submitButtonLoading: false,
};

SitePageTableSectionFormContainer.propTypes = {
  onSubmit: PropTypes.func,
  submitButtonText: PropTypes.string,
  children: PropTypes.any,
  errMsg: PropTypes.string,
  submitButtonVariant: PropTypes.string,
  submitButtonDisabled: PropTypes.bool,
  submitButtonLoading: PropTypes.bool,
};

export default SitePageTableSectionFormContainer;
