import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SitePageTableSectionRow = ({
  title, content, children, onClick,
}) => {
  const [expand, setExpand] = useState(false);
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      setExpand(!expand && children);
    }
  };
  let svgPath = '';
  if (onClick) {
    svgPath = 'M9 5l7 7-7 7';
  } else if (expand) {
    svgPath = 'M5 15l7-7 7 7';
  } else {
    svgPath = 'M19 9l-7 7-7-7';
  }
  const backgroundColor = classNames('w-full text-left focus:outline-none block hover:bg-gray-50', { 'bg-gray-50': expand });
  return (
    <div className={classNames({ 'shadow-lg': expand })}>
      <li>
        <button onClick={handleOnClick} className={backgroundColor}>
          <div className="flex items-center px-4 py-6">
            <div className="min-w-0 flex-1 flex items-center">
              <div className="flex-1 pr-4 grid grid-cols-5">
                <div className="col-span-1">
                  <h3 className="text-base leading-6 font-medium">{title}</h3>
                </div>
                <div className="flex col-span-4 items-center">
                  <h3 className="flex-grow text-base leading-6 font-normal text-gray-500 col-span-9">{content}</h3>
                  <div>
                    <svg className="h-4 w-4 text-gray-300 float-right " fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={svgPath} />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </button>
      </li>

      {expand && (
        <div className="w-full py-10 px-4 bg-white">
          {children}
        </div>
      )}
    </div>
  );
};

SitePageTableSectionRow.defaultProps = {
  title: 'Title',
  content: '',
  children: null,
  onClick: null,
};

SitePageTableSectionRow.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.element,
  onClick: PropTypes.func,
};

export default SitePageTableSectionRow;
