import React from 'react';

const TemplateEmailCapture = () => (
  <div className="bg-white">
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <form className="mt-8 sm:flex justify-center" aria-labelledby="newsletter-headline">
        <input aria-label="Email address" type="email" required className="appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:max-w-xs" placeholder="Enter your email" />
        <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:opacity-75 focus:outline-none transition ease-in-out duration-150">
            Get Started
          </button>
        </div>
      </form>
    </div>
  </div>
);

export default TemplateEmailCapture;
