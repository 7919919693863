import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import EditSiteEditorFormUIInput from './EditSiteEditorFormUI/EditSiteEditorFormUIInput';
import EditSiteEditorFormGroup from './EditSiteEditorFormGroup';
import { sitesUploadConfigGroupItemImageAPI } from '../../../../api/sites';
import EditSiteEditorFormUIProfile from './EditSiteEditorFormUI/EditSiteEditorFormUIProfile/EditSiteEditorFormUIProfile';
import { useEditSite } from '../../../../contexts/EditSiteContext';

const EditSiteEditorFormTestimonials = ({ section, onInputChange }) => {
  const { config, id: sectionId } = section;
  const { editSite: { id: siteId } } = useEditSite();
  const testimonials = (config && config.testimonials) || [];
  const handleAddClicked = () => {
    const value = [...testimonials, {
      id: uuidv4(),
      quote: '',
      name: '',
      about: '',
    }];
    onInputChange('testimonials')({ target: { value } });
  };
  const removeLink = (testimonialId) => () => {
    const value = [...testimonials].filter((testimonial) => testimonial.id !== testimonialId);
    onInputChange('testimonials')({ target: { value } });
  };
  const handleUpdateTestimonialField = (testimonialId) => (fieldKey) => ({ target: { value } }) => {
    const configTestimonialValue = [...testimonials];
    const index = configTestimonialValue.map((testimonial) => testimonial.id).indexOf(testimonialId);
    configTestimonialValue[index][fieldKey] = value;
    onInputChange('testimonials')({ target: { value: configTestimonialValue } });
  };
  const handleTestimonialImageLoaded = (testimonialId) => async (image) => {
    const url = await sitesUploadConfigGroupItemImageAPI(siteId, sectionId, testimonialId, image);
    handleUpdateTestimonialField(testimonialId)('photo')({ target: { value: url } });
  };
  return (
    <>
      <div className="mb-5">
        <EditSiteEditorFormUIInput
          label="Title"
          value={config && config.title}
          onChange={onInputChange('title')}
        />
      </div>
      <div className="mb-5">
        <EditSiteEditorFormUIInput
          label="Subtitle"
          value={config && config.subtitle}
          onChange={onInputChange('subtitle')}
        />
      </div>
      <EditSiteEditorFormGroup title="Testimonials" addButtonText="+ Add Testimonial" onAddClick={handleAddClicked}>
        {testimonials.map((testimonial) => (
          <EditSiteEditorFormUIProfile
            key={testimonial.id}
            fields={[
              {
                name: 'quote',
                value: testimonial.quote,
                placeholder: 'Quote',
                type: 'textarea',
              },
              {
                name: 'name',
                value: testimonial.name,
                placeholder: 'Name',
                type: 'text',
              }, {
                name: 'about',
                value: testimonial.about,
                placeholder: 'Title/About',
                type: 'text',
              }, {
                name: 'url',
                value: testimonial.url,
                placeholder: 'URL',
                type: 'text',
              }]}
            onInputChange={handleUpdateTestimonialField(testimonial.id)}
            onImageLoaded={handleTestimonialImageLoaded(testimonial.id)}
            photo={testimonial.photo}
            onRemove={removeLink(testimonial.id)}
          />
        ))}
      </EditSiteEditorFormGroup>
    </>
  );
};

EditSiteEditorFormTestimonials.propTypes = {
  section: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default EditSiteEditorFormTestimonials;
