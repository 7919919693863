import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const OrgPageTableSection = ({ title, titleColor, children }) => {
  const titleClasses = classNames('text-lg leading-6 font-medium', titleColor);

  return (
    <div className="mt-10 space-y-6 divide-y divide-gray-200">
      <div className="space-y-1">
        <h3 className={titleClasses}>
          {title}
        </h3>
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {children}
        </ul>
      </div>
    </div>
  );
};

OrgPageTableSection.defaultProps = {
  titleColor: 'text-gray-900',
  children: null,
};

OrgPageTableSection.propTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  children: PropTypes.any,
};

export default OrgPageTableSection;
