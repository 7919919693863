import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SiteAssetsPageAssetCardDropDown from './SiteAssetsPageAssetCardDropDown';
import { useAsset } from '../../contexts/AssetContext';

export default function SiteAssetsPageAssetCard({ asset }) {
  const { setAsset } = useAsset();
  useEffect(() => {
    setAsset(asset);
  }, [asset, setAsset]);
  const contentType = asset.fileMeta && asset.fileMeta.contentType;
  const preview = (contentType && contentType.startsWith('image/')) ? (
    <img src={asset.thumbnailURL || asset.originalURL} alt="" className="w-full h-48 object-contain" />
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-full h-48">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
    </svg>
  );
  return (
    <div className="relative">
      <div className="w-full border rounded overflow-hidden">
        <a target="_blank" href={asset.originalURL} className="p-2 bg-gray-50 grid place-items-center" rel="noopener noreferrer">
          {preview}
        </a>
        <div className="p-2 flex items-center bg-white">
          <h6 className="overflow-ellipsis whitespace-nowrap overflow-hidden flex-grow">{asset.fileName}</h6>
          <SiteAssetsPageAssetCardDropDown />
        </div>
      </div>
    </div>
  );
}

SiteAssetsPageAssetCard.propTypes = {
  asset: PropTypes.object.isRequired,
};
