import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
  }
`;

export default function SitePostPageDateTimePicker({ defaultValue, onChange }) {
  return (
    <DatePickerStyled className="mb-4">
      <DatePicker
        onChange={onChange}
        showTimeSelect
        dateFormat="Pp"
        className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
        selected={defaultValue}
        timeIntervals={1}
      />
    </DatePickerStyled>
  );
}

SitePostPageDateTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.instanceOf(Date).isRequired,
};
