import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function ProfilePic({ src, additionalClasses }) {
  return src ? (
    <>
      <img
        className={classNames('rounded-full object-cover', additionalClasses)}
        src={src}
        alt=""
      />
    </>
  ) : (
    <div className={classNames('rounded-full flex items-center bg-gray-200 justify-center', additionalClasses)}>
      <svg className="h-2/5 w-2/5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
      </svg>
    </div>
  );
}

ProfilePic.defaultProps = {
  src: null,
  additionalClasses: '',
};

ProfilePic.propTypes = {
  src: PropTypes.string,
  additionalClasses: PropTypes.string,
};
