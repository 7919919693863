import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

const ProfilePicInput = ({ onSelectedLoaded, image, controlled }) => {
  const fileInputRef = useRef();
  const [localImage, setLocalImage] = useState('');
  const [loading, setLoading] = useState(false);
  const handleChooseFileClicked = () => fileInputRef.current.click();

  const handleFileChanged = (e) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      setLocalImage(reader.result);
      setLoading(true);
      if (onSelectedLoaded) {
        try {
          await onSelectedLoaded(reader.result);
          setLoading(false);
        } catch {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const displayedImage = controlled ? image : localImage;
  let content;
  if (loading) {
    content = (
      <svg className="animate-spin h-6 w-6" viewBox="0 0 24 24" fill="none">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
    );
  } else if (displayedImage) {
    content = <img src={displayedImage} alt="" style={{ height: '100%' }} className="object-cover w-full" />;
  } else {
    content = (
      <svg className="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
      </svg>
    );
  }
  return (
    <button onClick={handleChooseFileClicked} className="outline-none h-14 w-14 rounded-full bg-gray-200 overflow-hidden flex justify-center items-center">
      {content}
      <input type="file" className="hidden" ref={(fileInput) => { fileInputRef.current = fileInput; }} onChange={handleFileChanged} />
    </button>
  );
};

ProfilePicInput.defaultProps = {
  onSelectedLoaded: null,
  image: '',
  controlled: false,
};

ProfilePicInput.propTypes = {
  onSelectedLoaded: PropTypes.func,
  image: PropTypes.string,
  controlled: PropTypes.bool,
};

export default ProfilePicInput;
