import React from 'react';
import PropTypes from 'prop-types';
import ProfilePic from '../PeofilePic';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';

export default function TemplateContactSpokespeople({ spokespeople }) {
  const { primaryColor } = useGetTemplateTheme();
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-5 sm:space-y-4 mb-10">
          <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">Spokespeople</h2>
        </div>
        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 lg:grid-cols-3 sm:space-y-0 lg:gap-x-8">
          {spokespeople.map((spokesperson) => (
            <li key={spokesperson.id}>
              <div className="flex space-x-4 lg:space-x-6">
                <ProfilePic src={spokesperson.photo} additionalClasses="w-16 h-16 lg:w-20 lg:h-20 flex-shrink-0" />
                <div className="font-medium text-lg leading-6 space-y-1">
                  {spokesperson.name && <h3>{spokesperson.name}</h3>}
                  {spokesperson.title && <p style={{ color: primaryColor }}>{spokesperson.title}</p>}
                  {spokesperson.email && <p className="text-gray-500">{spokesperson.email}</p>}
                  {spokesperson.phone && <p className="text-gray-500">{spokesperson.phone}</p>}
                  {spokesperson.description && <p className="text-gray-500 pt-1">{spokesperson.description}</p>}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>

  );
}

TemplateContactSpokespeople.defaultProps = {
  spokespeople: [],
};

TemplateContactSpokespeople.propTypes = {
  spokespeople: PropTypes.array,
};
