import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import PublishedBlogPage from './PublishedBlogPage';
import PublishedPostPage from './PublishedPostPage';
import PublishedAssetsPage from './PublishedAssetsPage';
import PublishedAssetsFolderPage from './PublishedAssetsFolderPage';
import PublishedCoveragePage from './PublishedCoveragePage';
import { BlogProvider } from '../../contexts/BlogContext';
import { useSetTemplateWithArray } from '../../contexts/TemplateContext';
import PublishedNotFound from './PublishedNotFound';
import Template from '../Template/Template';
import { useLanguage } from '../../contexts/LanguageContext';
import PublishedHomePage from './PublishedHomePage';

export default function PublishedRoute({ site }) {
  const { language } = useLanguage();
  const { sections } = site.pages.home;
  const coveragePageEnabled = !!(site.pages.coverage && site.pages.coverage.enabled);
  const pressReleasesPageEnabled = !!(site.pages.pressReleases && site.pages.pressReleases.enabled);
  useSetTemplateWithArray(sections, site.meta, site.theme, site.ownerId, site.orgId, site.siteId, coveragePageEnabled, pressReleasesPageEnabled, language);
  return (

    <Switch>
      <Route exact path="/:lang/press-releases">
        <BlogProvider>
          <PublishedBlogPage />
        </BlogProvider>
      </Route>
      <Route exact path="/press-releases/:postId">
        <PublishedPostPage site={site} />
      </Route>
      <Route exact path="/:lang/press-releases/:postId">
        <PublishedPostPage site={site} />
      </Route>
      <Route exact path="/:lang/media-assets">
        <PublishedAssetsPage site={site} />
      </Route>
      <Route exact path="/:lang/media-assets/:assetId">
        <PublishedAssetsFolderPage site={site} />
      </Route>
      <Route exact path="/:lang/clippings">
        <PublishedCoveragePage />
      </Route>
      <Route exact path="/:lang">
        <Template />
      </Route>
      <Route exact path="/">
        <PublishedHomePage />
      </Route>
      <Route path="*">
        <PublishedNotFound />
      </Route>
    </Switch>
  );
}

PublishedRoute.propTypes = {
  site: PropTypes.object.isRequired,
};
