import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useFetchOrgSites, useOrg } from '../../contexts/OrgContext';
import useGetDomains from '../../hooks/useGetDomains';
import DefaultButton from '../DefaultButton';
import SitePreviewCard from '../SitePreviewCard';
import OrgPageTableSectionRow from './OrgPageTableSectionRow';
import OrgPageTableSection from './OrgPageTableSection';
import OrgPageTableSectionFormDomain from './OrgPageTableSectionFormDomain';
import OrgCreateNewSiteButton from './OrgCreateNewSiteButton';

export default function OrgPage() {
  const { url } = useRouteMatch();
  const { sites, org: { id, domain, name } } = useOrg();
  useFetchOrgSites(id);
  const { mainDomain } = useGetDomains(true);
  function handleVisitClicked() {
    let redirectMainDomain = mainDomain;
    if (mainDomain === 'newsroom.presshunt.co') {
      redirectMainDomain = 'presshuntnewsroom.com';
    }
    if (domain && mainDomain) {
      window.open(`${window.location.protocol}//${domain}.${redirectMainDomain}`);
    }
  }

  return (
    <div className="py-6 px-8">
      <div className="flex space-x-2">
        <DefaultButton onClick={handleVisitClicked} variant="light">Visit</DefaultButton>
      </div>
      <div className="max-w-lg mt-5">
        <h2 className="text-2xl">{name}</h2>
      </div>
      <OrgPageTableSection title="Settings">
        <OrgPageTableSectionRow title="Domain" content={`${domain}.presshuntnewsroom.com`}>
          <OrgPageTableSectionFormDomain orgDomain={domain} />
        </OrgPageTableSectionRow>
      </OrgPageTableSection>
      <div className="grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none mt-5">
        {sites.map(((site) => <SitePreviewCard key={site.id} site={site} />))}
        <Link to={`${url}/create-newsroom`}>
          <OrgCreateNewSiteButton />
        </Link>
      </div>
    </div>
  );
}
