import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';
import { useLanguage } from '../../contexts/LanguageContext';

const TagsStyled = styled.div`
  ul.tags-list {
    display: inline;
    list-style: none;
    padding-left: 0;
  }

  li {
    display: inline;
  }

  a {
    text-decoration: underline;
  }
`;

export default function PublishedTags({ post }) {
  const { language } = useLanguage();
  const { primaryColor } = useGetTemplateTheme();
  if (!post || !post.tags || post.tags.length === 0) {
    return null;
  }

  return (
    <div className="post-tags mt-5">
      <TagsStyled className="mt-2">
        <ul className="tags-list">
          {post.tags.map((tag) => (
            <li key={tag.id} className="px-1 py-4 text-lg font-bold">
              <Link
                to={{
                  pathname: `/${language}/press-releases`,
                  search: `?tag=${tag.text}`,
                  state: {
                    tag: tag.text,
                  },
                }}
                className="text-lg hover:underline"
                style={{ color: primaryColor }}
              >
                #
                {tag.text}
              </Link>
            </li>
          ))}
        </ul>
      </TagsStyled>
    </div>
  );
}

PublishedTags.defaultProps = {
  post: null,
};

PublishedTags.propTypes = {
  post: PropTypes.object,
};
