/** @typedef { import('firebase').default.firestore.DocumentReference } DocumentReference */
/** @typedef { import('firebase').default.firestore.DocumentSnapshot } DocumentSnapshot */

/**
 *
 * @param {DocumentSnapshot} sectionDoc Section DocumentSnapshot
 */
function processSection(sectionDoc) {
  return {
    id: sectionDoc.id,
    ...sectionDoc.data(),
  };
}

/**
 * Get sections with given list of section ids
 * @param {DocumentReference} siteRef Site Document Reference
 * @param {Array<String>} sectionIds List of section ids
 * @returns {Array<Object>} Array or processed sections
 */
export async function getSectionsWithIds(siteRef, sectionIds) {
  const sectionDocs = await Promise.all(sectionIds.map((sectionId) => siteRef.collection('sections').doc(sectionId).get()));
  return sectionDocs
    .filter((sectionDoc) => sectionDoc.exists)
    .map(processSection);
}

/**
 *
 * @param {DocumentReference} siteRef Site Document Reference
 * @param {Object} section New section
 */
export async function sectionsAddSectionAPI(siteRef, section) {
  const newSectionRef = await siteRef.collection('sections').add(section);
  const newSectionDoc = await newSectionRef.get();
  return processSection(newSectionDoc);
}
