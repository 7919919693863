import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import Transition from './Transition';

function getTabClasses(selected, first) {
  const defaultClasses = 'px-3 py-2 rounded-md text-sm font-medium leading-5';
  const focusedTabClasses = 'text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out';
  const unfocusedTabClasses = 'text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out';
  return classNames(defaultClasses, selected ? focusedTabClasses : unfocusedTabClasses, !first && 'ml-4');
}

function NavButton({
  to, selected, first, children,
}) {
  return <Link to={to} className={getTabClasses(selected, first)}>{children}</Link>;
}

NavButton.defaultProps = {
  selected: false,
  first: false,
};

NavButton.propTypes = {
  to: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  first: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

export default function () {
  const { logout } = useAuth();
  const [isOn, setIsOn] = useState(false);
  const [dropdownOn, setDropdown] = useState(false);

  const handleSignOutClicked = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <nav className="bg-white shadow-sm border-b border-gray-300 fixed w-full z-10 top-0">
      <div className="px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-12">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* -- Mobile menu button--> */}
            <button type="button" onClick={() => setIsOn(!isOn)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out" aria-label="Main menu" aria-expanded="false">
              <svg className={`${isOn ? 'inline' : 'hidden'} h-6 w-6`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
              <svg className={`${isOn ? 'hidden' : 'inline'} h-6 w-6`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <h2 className="text-lg font-bold leading-7 text-black sm:leading-9 sm:truncate">
                Newsroom
              </h2>
            </Link>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="ml-3 relative">
              <div>
                <button type="button" onClick={() => setDropdown(!dropdownOn)} className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-white transition duration-150 ease-in-out" id="user-menu" aria-label="User menu" aria-haspopup="true">
                  <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </button>
              </div>
              <Transition
                show={dropdownOn}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className={`${dropdownOn ? 'block' : 'hidden'} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50`}>
                  <div className="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                    <a href="/#" onClick={handleSignOutClicked} className="block px-4 py-2 mb-0 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out" role="menuitem">Sign out</a>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </nav>

  );
}
