import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function PublishedNotFound() {
  return (
    <>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <div className="w-full h-screen bg-gray-100 flex justify-center">
        <div className="mt-40">
          <h3 className="text-5xl text-center font-bold text-gray-900">Page not found</h3>
          <p className="text-center mt-6">Sorry, that page doesn't exist.</p>
        </div>
      </div>
    </>
  );
}
