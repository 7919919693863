import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DefaultButton from '../../../../DefaultButton';
import { fileUploadWithDataURL } from '../../../../../api/file';

export default function EditSiteEditorFormUIImageUpload({
  label, onChange, defaultValue, accetedTypes, storageRoute,
}) {
  const [imageURL, setImageURL] = useState();
  useEffect(() => {
    setImageURL(defaultValue);
  }, [defaultValue, setImageURL]);
  const fileInputRef = useRef();
  function handleFileChanged(e) {
    const reader = new FileReader();
    reader.onloadend = async () => {
      if (storageRoute) {
        const url = await fileUploadWithDataURL(storageRoute, reader.result);
        setImageURL(url);
        onChange(url);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  }
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 items-center">
      <label className="block text-sm font-medium leading-5 text-gray-700">
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        {imageURL ? (
          <button onClick={() => fileInputRef.current.click()}>
            <img src={imageURL} className="h-10 w-auto" alt="" />
          </button>
        )
          : (
            <DefaultButton size="small" variant="light" onClick={() => fileInputRef.current.click()}>
              Upload
            </DefaultButton>
          )}
        <input
          type="file"
          className="hidden"
          ref={(fileInput) => { fileInputRef.current = fileInput; }}
          onChange={handleFileChanged}
          accept={accetedTypes}
        />
      </div>
    </div>
  );
}

EditSiteEditorFormUIImageUpload.defaultProps = {
  defaultValue: '',
  accetedTypes: 'image/*',
};

EditSiteEditorFormUIImageUpload.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  accetedTypes: PropTypes.string,
  storageRoute: PropTypes.string.isRequired,
};
