import { presshuntAuth } from './firebase';

/**
 *
 * @param {String} email
 * @param {String} password
 */
export function authSignInAPI(email, password) {
  return presshuntAuth.signInWithEmailAndPassword(email, password);
}

export function authSignOutAPI() {
  return presshuntAuth.signOut();
}
