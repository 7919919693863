function getFont(displayName, key, fontFamily) {
  return {
    displayName, key, fontFamily,
  };
}

export const supportedFonts = {
  roboto: getFont('Roboto', 'roboto', "'Roboto', sans-serif"),
  opensans: getFont('Open Sans', 'opensans', "'Open Sans', sans-serif"),
  stixtwotext: getFont('STIX Two Text', 'stixtwotext', "'STIX Two Text', serif"),
};
