import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLocation, Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePublishedSite } from '../../contexts/Published/PublishedSiteContext';
import PublishedNotFound from './PublishedNotFound';
import { siteAdditionalPages } from '../SitePage/SitePage';
import PublishedBlogTagsSidebar from './PublishedBlogTagsSidebar';
import PublishedBlogPageLanguageModal from './PublishedBlogPageLanguageModal';
import { useFetchPosts, useBlog } from '../../contexts/BlogContext';
import Template from '../Template/Template';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';
import { KEY_ENABLE_LANGUAGE_SELECTOR } from '../SiteBlogPage/SiteBlogPage';
import { getI18N } from '../../config/i18n';
import { useLanguage } from '../../contexts/LanguageContext';
import SitePageBreadCrumbs from '../SitePage/SitePageBreadCrumbs';

function filterPostsByLanguage(posts, languagefilter) {
  return posts.filter((post) => post.language === languagefilter);
}

function filterPostsByTag(posts, tagFilter) {
  return posts.filter((post) => {
    if (!post.tags || post.tags.length === 0) {
      return false;
    }
    let matchTag = false;
    post.tags.map((tag) => {
      if (tag.text === tagFilter) {
        matchTag = true;
      }
      return tag;
    });
    return matchTag;
  });
}

function filterPostsByIsActive(posts) {
  return posts.filter((post) => post.published && !(post?.archived) && (post.scheduledReleaseTime === undefined || (moment().diff(moment(post.scheduledReleaseTime), 'seconds') >= 0)));
}

export default function PublishedBlogPage() {
  const { language: currentLanguage } = useLanguage();
  const i18n = getI18N(currentLanguage);
  const initShowPostCount = 12;
  const infiniteMorePostCount = 6;
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const tagFromQueryString = location.state?.tag ?? urlParams.get('tag');
  const [languageFilter, setLanguageFilter] = useState('');
  const [tagFilter, setTagFilter] = useState(tagFromQueryString);
  const { primaryColor } = useGetTemplateTheme();
  const { site } = usePublishedSite();
  const { ownerId: uid, orgId: oid, siteId: sid } = site;
  const localStorageLanguage = localStorage.getItem('pressReleasesLanguage');
  const [isLocalStorageLanguageModalOn, setIsLocalStorageLanguageModalOn] = useState(false);
  const [noFilterActivePublishedPosts, setNoFilterActivePublishedPosts] = useState([]);
  const [infintiePosts, setInfinitePosts] = useState([]);
  const [publishedPosts, setPublishedPosts] = useState([]);
  const [showAblePostCount, setShowAblePostCount] = useState(0);
  const [showPostCount, setShowPostCount] = useState(initShowPostCount);
  const crumbs = [{ name: i18n.home, path: '/:lang' }, { name: i18n.pressReleases }];

  useFetchPosts(uid, oid, sid);
  const { filteredPosts } = useBlog();

  const pageName = siteAdditionalPages.pressReleases.code;
  const displayLanguageSelector = (site && site.pages && site.pages[pageName] && site.pages[pageName][KEY_ENABLE_LANGUAGE_SELECTOR]) || false;

  function languageModalSubmitCallback(language) {
    localStorage.setItem('pressReleasesLanguage', language);
    setLanguageFilter(language);
  }

  function getMoreInfintePosts() {
    setTimeout(() => {
      setInfinitePosts(publishedPosts.slice(0, showPostCount + infiniteMorePostCount));
      setShowPostCount(showPostCount + infiniteMorePostCount);
    }, 1500);
  }

  function getFirstTextFromEditor(post) {
    if (!post || !post.editorStringified) {
      return null;
    }
    const editor = JSON.parse(post.editorStringified);
    if (!editor.blocks || editor.blocks.length === 0) {
      return null;
    }

    return editor.blocks[0].text;
  }

  useEffect(() => {
    setNoFilterActivePublishedPosts(filterPostsByIsActive(filteredPosts));
  }, [filteredPosts]);

  useEffect(() => {
    function filterPostsByTagOrLanguage() {
      let tmpPosts = noFilterActivePublishedPosts;
      if (languageFilter && languageFilter !== '') {
        tmpPosts = filterPostsByLanguage(tmpPosts, languageFilter);
      }

      if (tagFilter && tagFilter !== '') {
        tmpPosts = filterPostsByTag(tmpPosts, tagFilter);
      }

      setShowAblePostCount(tmpPosts.length);
      let tmpShowPostCount = 0;
      if (tmpPosts.length !== 0) {
        tmpShowPostCount = tmpPosts.length > initShowPostCount ? initShowPostCount : tmpPosts.length;
        setShowPostCount(tmpShowPostCount);
      }
      setPublishedPosts(tmpPosts);
    }
    filterPostsByTagOrLanguage();
  }, [noFilterActivePublishedPosts, tagFilter, languageFilter]);

  useEffect(() => {
    setInfinitePosts(publishedPosts.slice(0, showPostCount));
  }, [publishedPosts, showPostCount]);

  useEffect(() => {
    if (displayLanguageSelector && localStorageLanguage == null) {
      setIsLocalStorageLanguageModalOn(true);
    } else if (displayLanguageSelector) {
      setLanguageFilter(localStorageLanguage);
    }
  }, [setIsLocalStorageLanguageModalOn, setLanguageFilter, localStorageLanguage, displayLanguageSelector]);

  if (!(site.pages[pageName] && site.pages[pageName].enabled)) {
    return <PublishedNotFound />;
  }

  return (
    <Template>
      <div className="max-w-7xl mx-auto px-4">
        <SitePageBreadCrumbs crumbs={crumbs} />
      </div>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="max-w-7xl mx-auto px-4">
          <div className="mb-16 flex flex-wrap items-center">
            <div className="w-full lg:w-1/2">
              <span style={{ color: primaryColor }} className="text-green-600 font-bold">{i18n.newsArchive}</span>
              <h2 className="text-4xl lg:text-5xl font-bold font-heading">{i18n.pressReleases}</h2>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="mb-8 lg:mb-0 w-full lg:w-1/4 px-3">
              <div className="py-4 px-6 bg-white shadow rounded">
                <PublishedBlogTagsSidebar posts={noFilterActivePublishedPosts} onChange={setTagFilter} value={tagFilter} language={currentLanguage} />
              </div>
            </div>
            <div className="w-full lg:w-3/4 px-3 overflow-hidden">
              <InfiniteScroll
                dataLength={showPostCount} // This is important field to render the next data
                next={getMoreInfintePosts}
                hasMore={showAblePostCount > showPostCount}
                loader={(
                  <div className="flex justify-center h-6">
                    <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24" fill="none">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                  </div>
                )}
              >
                {infintiePosts.map((post) => (
                  <>
                    <div className="flex flex-wrap -mx-3 mb-8 lg:mb-6 w-full">
                      <div className="mb-4 lg:mb-0 w-full lg:w-1/4 px-3">
                        <img className="w-full h-full object-cover rounded" src={post.cover} alt="" />
                      </div>
                      <div className="w-full lg:w-3/4 px-3">
                        <Link
                          to={`/${currentLanguage}/press-releases/${post.id}`}
                          className="hover:underline"
                        >
                          <h3 className="mb-1 text-2xl font-bold font-heading">{post.title}</h3>
                        </Link>
                        <div className="mb-2 flex items-center text-sm">
                          <span style={{ color: primaryColor }} className="hover:underline cursor-pointer">
                            {post.tags?.map((tag) => `#${tag.text}`).join(' ')}
                          </span>
                          {post.tags?.length !== 0 && <span className="text-gray-400 mx-2">•</span>}
                          <span className="text-gray-400">{moment(post.scheduledReleaseTime ?? post.createTime).format('MMMM Do, YYYY')}</span>
                        </div>
                        <p className="w-3/4 truncate text-gray-500">{getFirstTextFromEditor(post)}</p>
                      </div>
                    </div>
                  </>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
      <PublishedBlogPageLanguageModal
        isOpen={isLocalStorageLanguageModalOn}
        closeModal={() => setIsLocalStorageLanguageModalOn(false)}
        submitCallback={languageModalSubmitCallback}
      />
    </Template>
  );
}
