import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AssetProvider } from '../../contexts/AssetContext';
import { useOrg } from '../../contexts/OrgContext';
import { useSite } from '../../contexts/SiteContext';
import { useFetchMediaAssets, useMediaAssets } from '../../contexts/MediaAssetsContext';
import SiteAdditionalPagesEnableToggle from '../SiteAdditionalPagesEnableToggle';
import { siteAdditionalPages } from '../SitePage/SitePage';
import SiteAssetsPageAssetCard from './SiteAssetsPageAssetCard';
import SiteAssetsPageFolderCard from './SiteAssetsPageFolderCard';
import SitePageNavbar, { SiteRoutePages } from '../SitePage/SitePageNavbar/SitePageNavbar';

export default function SiteAssetsPage() {
  const { site } = useSite();
  const { org } = useOrg();
  const { params: { assetId } } = useRouteMatch();
  useFetchMediaAssets(assetId);

  const { assets } = useMediaAssets();
  return (
    <>
      {assetId !== undefined && (
        <SitePageNavbar
          crumbs={[
            { name: org.name, path: `/organization/${org.id}` },
            { name: site.meta.title, path: `/organization/${org.id}/site/${site.id}` },
            { name: 'Media Assets', path: `/organization/${org.id}/site/${site.id}/media-assets` },
            { name: `${assetId}` },
          ]}
          page={SiteRoutePages.MEDIA_ASSETS}
        />
      )}
      <div className="p-8">
        <SiteAdditionalPagesEnableToggle page={siteAdditionalPages.mediaAssets} label="Enable Media Assets Page" />
        <div className="grid gap-5 w-full mx-auto md:grid-cols-4 lg:grid-cols-5 mb-10 mt-6">
          {assets.map((asset) => {
            if (asset && asset.isFolder !== undefined && asset.isFolder) {
              return (
                <AssetProvider key={asset.id}>
                  <SiteAssetsPageFolderCard asset={asset} />
                </AssetProvider>
              );
            }
            return (
              <AssetProvider key={asset.id}>
                <SiteAssetsPageAssetCard asset={asset} />
              </AssetProvider>
            );
          })}
        </div>
      </div>
    </>
  );
}
