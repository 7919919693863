import React from 'react';
import PropTypes from 'prop-types';
import { useEditSite, useSetEditSiteMetaProperty } from '../../../../contexts/EditSiteContext';
import EditSiteEditorFormUIImageUpload from './EditSiteEditorFormUI/EditSiteEditorFormUIImageUpload';
import EditSiteEditorFormUIInput from './EditSiteEditorFormUI/EditSiteEditorFormUIInput';

export default function EditSiteEditorFormPageMeta({ onInputChange }) {
  const { editSite: { meta, id: siteId } } = useEditSite();
  const setEditSiteMetaProperty = useSetEditSiteMetaProperty();
  function handleTitleChanged({ target: { value } }) {
    setEditSiteMetaProperty('title', value);
    onInputChange();
  }
  function handleFaviconUploaded(url) {
    setEditSiteMetaProperty('favicon', url);
    onInputChange();
  }
  return (
    <div className="space-y-5">
      <EditSiteEditorFormUIInput
        label="Title"
        value={meta.title}
        onChange={handleTitleChanged}
      />
      <EditSiteEditorFormUIImageUpload
        label="Favicon"
        onChange={handleFaviconUploaded}
        storageRoute={`sites/${siteId}/logos`}
        defaultValue={meta.favicon}
        accetedTypes=".ico"
      />
    </div>
  );
}

EditSiteEditorFormPageMeta.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};
