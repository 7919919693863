import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ABOUT, CONTACT, FOOTER, NAVBAR } from '../utils/templateTypes';

const defaultTemplate = {
  meta: {},
  theme: {},
  sectionIds: [],
  sectionsDict: {},
  navbarSectionId: '',
  footerSectionId: '',
  aboutSectionId: '',
  contactSectionId: '',
  userId: null,
  orgId: null,
  siteId: null,
  coveragePageEnabled: false,
  pressReleasesPageEnabled: false,
  language: '',
};

const TemplateContext = React.createContext({
  template: defaultTemplate,
  setTemplate: () => {},
});

export function useTemplate() {
  return useContext(TemplateContext);
}

function updateTemplate(sectionIds, sectionsDict, setTemplate, meta, theme, userId, orgId, siteId, coveragePageEnabled, pressReleasesPageEnabled, language) {
  const navbarSectionId = sectionIds.find((id) => sectionsDict[id].type === NAVBAR);
  const footerSectionId = sectionIds.find((id) => sectionsDict[id].type === FOOTER);
  const aboutSectionId = sectionIds.find((id) => sectionsDict[id].type === ABOUT);
  const contactSectionId = sectionIds.find((id) => sectionsDict[id].type === CONTACT);
  setTemplate((prev) => ({
    ...prev,
    sectionsDict,
    sectionIds,
    navbarSectionId,
    footerSectionId,
    aboutSectionId,
    contactSectionId,
    meta,
    theme,
    userId,
    orgId,
    siteId,
    coveragePageEnabled,
    pressReleasesPageEnabled,
    language,
  }));
}

export function useSetTemplateWithArray(sections, meta, theme, userId, orgId, siteId, coveragePageEnabled, pressReleasesPageEnabled, language = 'en') {
  const { setTemplate } = useContext(TemplateContext);
  useEffect(() => {
    const sectionIds = sections.map((section) => section.id);
    const sectionsDict = {};
    sections.forEach((section) => {
      sectionsDict[section.id] = section;
    });
    updateTemplate(sectionIds, sectionsDict, setTemplate, meta, theme, userId, orgId, siteId, coveragePageEnabled, pressReleasesPageEnabled, language);
  }, [sections, setTemplate, meta, theme, userId, orgId, siteId, coveragePageEnabled, pressReleasesPageEnabled, language]);
}

export function useSetTemplateWithIdsAndArrayDict(sectionIds, sectionsDict, meta, theme, userId, orgId, siteId, coveragePageEnabled, pressReleasesPageEnabled) {
  const { setTemplate } = useContext(TemplateContext);
  useEffect(() => {
    updateTemplate(sectionIds, sectionsDict, setTemplate, meta, theme, userId, orgId, siteId, coveragePageEnabled, pressReleasesPageEnabled);
  }, [sectionIds, sectionsDict, setTemplate, meta, theme, userId, orgId, siteId, coveragePageEnabled, pressReleasesPageEnabled]);
}

export function TemplateProvider({ children }) {
  const [template, setTemplate] = useState(defaultTemplate);
  return (
    <TemplateContext.Provider value={{ template, setTemplate }}>
      {children}
    </TemplateContext.Provider>
  );
}

const DEFAULT_PRIMARY_COLOR = '#5a67d8';

export function useGetTemplateTheme() {
  const { template: { theme } } = useTemplate();
  const primaryColor = (theme && theme.primaryColor) || DEFAULT_PRIMARY_COLOR;
  return {
    primaryColor,
  };
}

TemplateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
