import { find } from 'lodash';

const languages = [
  {
    name: 'English',
    value: 'en',
    countryCode: 'US',
  },
  {
    name: 'Russian',
    value: 'ru',
    countryCode: 'RU',
  },
  {
    name: 'Arabic',
    value: 'ar',
    countryCode: 'SA',
  },
  {
    name: 'French',
    value: 'fr',
    countryCode: 'FR',
  },
  {
    name: 'Swahili',
    value: 'sw',
    countryCode: 'KE',
  },
  {
    name: 'Vietnamese',
    value: 'vi',
    countryCode: 'VN',
  },
  {
    name: 'Indonesian',
    value: 'id',
    countryCode: 'ID',
  },
  {
    name: 'Hindi',
    value: 'hi',
    countryCode: 'IN',
  },
  {
    name: 'Turkish',
    value: 'tr',
    countryCode: 'TR',
  },
];

export function getAllLanguage() {
  return languages;
}

export function getLanguageByValue(lang) {
  return find(languages, { value: lang });
}
