import DOMPurify from 'dompurify';
import draftToHtml from 'draftjs-to-html';
import { useState, useEffect } from 'react';

function customEntityTransform(entity, text) {
  if (entity.type !== 'LINK') return;
  // eslint-disable-next-line consistent-return
  return `<a target="_blank" href="${entity.data.url}">${text}</a>`;
}

export default function useConvertFromEditorRawToHTML(editor) {
  const [markup, setMarkup] = useState(null);
  useEffect(() => {
    if (editor) {
      const dirtyHtml = draftToHtml(editor, null, true, customEntityTransform);
      setMarkup({
        __html: DOMPurify.sanitize(dirtyHtml, { ADD_TAGS: ['iframe'], ADD_ATTR: ['target'] }),
      });
    }
  }, [editor]);
  return markup;
}
