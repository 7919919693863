import React, { useCallback, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { useAuth } from '../../contexts/AuthContext';
import { useSite, useUpdateSitePageWithKeyValue } from '../../contexts/SiteContext';
import { useBlog, useFetchPosts } from '../../contexts/BlogContext';
import { siteAdditionalPages } from '../SitePage/SitePage';
import SiteAdditionalPagesEnableToggle from '../SiteAdditionalPagesEnableToggle';
import DefaultToggle from '../DefaultToggle';
import { sitesUpdateAdditionalPageAPI } from '../../api/sites';
import { tagsGetTagsAPI } from '../../api/tags';
import SiteBlogPagination from './SiteBlogPagination';
import SiteBlogPageTagSelector from './SiteBlogPageTagSelector';
import { useOrg } from '../../contexts/OrgContext';

export const KEY_ENABLE_LANGUAGE_SELECTOR = 'enableLanguageSelector';

const PAGE_SIZE = 10;

export default function SiteBlogPage() {
  const { user: { uid } } = useAuth();
  const { org: { id: oid } } = useOrg();
  const { url } = useRouteMatch();
  const { site, site: { id: sid }, siteRef } = useSite();
  const [paginationPosts, setPaginationPosts] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagFilter, setTagFilter] = useState();
  const [filterPosts, setFilterPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  useFetchPosts(uid, oid, sid);
  const { posts } = useBlog();
  const pageCode = siteAdditionalPages.pressReleases.code;
  const pageData = site.pages[pageCode];
  const updateSitePageWithKeyValue = useUpdateSitePageWithKeyValue();
  async function handleLanguageSelectorChange(enabled) {
    updateSitePageWithKeyValue(pageCode, KEY_ENABLE_LANGUAGE_SELECTOR, enabled);
    await sitesUpdateAdditionalPageAPI(siteRef, pageCode, KEY_ENABLE_LANGUAGE_SELECTOR, enabled);
  }

  function filterPostsByTag(posts, tagFilter) {
    return posts.filter((post) => {
      if (!post.tags || post.tags.length === 0) {
        return false;
      }
      let matchTag = false;
      post.tags.map((tag) => {
        if (tag.text === tagFilter) {
          matchTag = true;
        }
        return tag;
      });
      return matchTag;
    });
  }

  const setPostsByPagination = useCallback((page, tagFilter) => {
    let tmpPosts = posts;
    if (tagFilter && tagFilter !== '') {
      tmpPosts = filterPostsByTag(tmpPosts, tagFilter);
    }
    const tmpFilterPosts = tmpPosts;
    tmpPosts = tmpPosts.filter((post) => !(post?.archived))
      .slice(page * PAGE_SIZE, PAGE_SIZE + page * PAGE_SIZE);
    setPaginationPosts(tmpPosts);
    setFilterPosts(tmpFilterPosts);
  }, [posts]);

  useEffect(() => {
    const getTags = async () => {
      const allTags = await tagsGetTagsAPI(uid, oid, sid);
      setTags([{ id: '', text: 'All' }, ...allTags]);
    };
    getTags();
  }, [uid, sid, oid]);

  useEffect(() => {
    setPostsByPagination(0, tagFilter);
  }, [posts, setPostsByPagination, tagFilter]);
  return (
    <div className="flex justify-center">
      <div className="mt-10 w-full max-w-screen-md">
        <div className="mb-4">
          <SiteAdditionalPagesEnableToggle page={siteAdditionalPages.pressReleases} label="Enable press release page" />
        </div>
        <DefaultToggle
          checked={(pageData && pageData[KEY_ENABLE_LANGUAGE_SELECTOR]) || false}
          onChange={handleLanguageSelectorChange}
          label="Enable language auto-prompter"
        />
        <div className="mt-8 flex">
          <SiteBlogPageTagSelector onChange={setTagFilter} tagOptions={tags} />
          <SiteBlogPagination
            page={currentPage + 1}
            pageLimit={PAGE_SIZE}
            count={filterPosts.length}
            onPrevious={() => {
              if (currentPage <= 0) {
                return null;
              }

              const newCurrentPage = currentPage - 1;
              setCurrentPage(newCurrentPage);
              return setPostsByPagination(newCurrentPage);
            }}
            onNext={() => {
              if (currentPage * PAGE_SIZE >= filterPosts.length) {
                return null;
              }

              const newCurrentPage = currentPage + 1;
              setCurrentPage(newCurrentPage);
              return setPostsByPagination(newCurrentPage);
            }}
            style={{ display: (filterPosts.length > PAGE_SIZE) ? '' : 'none' }}
          />
        </div>
        <div className="border rounded my-6 mb-12">
          <ul className="divide-y divide-gray-200">
            {paginationPosts.map((post) => (
              <li key={post.id}>
                <Link to={`${url}/${post.id}`} className="py-4 flex hover:bg-gray-50 px-4 flex">
                  <div className="flex items-center">
                    {post.published && (post.scheduledReleaseTime === undefined || (moment().diff(moment(post.scheduledReleaseTime), 'seconds') >= 0)) ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    )}
                  </div>
                  <div className="ml-3 min-w-0">
                    <h3 className="text-lg font-semibold overflow-ellipsis whitespace-nowrap overflow-hidden flex-grow">{post.title}</h3>
                    <p className="text-sm text-gray-500">{moment(post.createTime).format('M/DD/YYYY, h:mm:ss a')}</p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
