import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import debounce from 'lodash.debounce';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './PostDraft.scss';

export default function SitePostPageDraft({ contentState, updatePost, setSaving }) {
  const [editorState, setEditorState] = useState(contentState ? EditorState.createWithContent(convertFromRaw(contentState)) : EditorState.createEmpty());
  const saveContent = useRef(debounce((contentStringified) => {
    updatePost({
      editorStringified: contentStringified,
    });
  }, 2000));

  function handleEditorStateChange(newEditorState) {
    if (newEditorState.getCurrentContent() !== editorState.getCurrentContent()) {
      const newContentState = newEditorState.getCurrentContent();
      const rawNewContentState = convertToRaw(newContentState);
      setSaving();
      saveContent.current(JSON.stringify(rawNewContentState));
      setEditorState(newEditorState);
    }
  }

  function addEmbeddedLink(embeddedLink) {
    if (embeddedLink.indexOf('youtube') >= 0) {
      return embeddedLink
        .replace('watch?v=', 'embed/')
        .replace('/watch/', '/embed/')
        .replace('youtu.be/', 'youtube.com/embed/');
    }
    return embeddedLink;
  }
  return (
    <div className="min-h-screen">
      <Editor
        defaultEditorState={editorState}
        editorClassName="post-editor"
        toolbarClassName="post-toolbar"
        toolbar={{
          options: ['inline', 'blockType', 'list', 'link', 'image', 'embedded'],
          inline: {
            options: ['bold', 'italic', 'underline', 'monospace'],
          },
          blockType: {
            inDropdown: false,
            options: ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
            h1: { className: 'font-bold' },
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          embedded: {
            embedCallback: addEmbeddedLink,
          },
        }}
        onEditorStateChange={handleEditorStateChange}
      />
    </div>
  );
}

SitePostPageDraft.defaultProps = {
  contentState: null,
};

SitePostPageDraft.propTypes = {
  contentState: PropTypes.object,
  updatePost: PropTypes.func.isRequired,
  setSaving: PropTypes.func.isRequired,
};
