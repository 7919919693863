import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { sitesPublishSiteAPI } from '../../api/sites';
import { useEditSite } from '../../contexts/EditSiteContext';
import { useOrg } from '../../contexts/OrgContext';
import { useSite } from '../../contexts/SiteContext';
import useGetDomains from '../../hooks/useGetDomains';
import DefaultButton from '../DefaultButton';

const EditSiteNavbar = () => {
  const { editSite } = useEditSite();
  const { org } = useOrg();
  const { siteRef } = useSite();
  const { mainDomain } = useGetDomains(true);
  const handleVisitButtonClicked = () => {
    const { domain } = org;
    let redirectMainDomain = mainDomain;
    if (mainDomain === 'newsroom.presshunt.co') {
      redirectMainDomain = 'presshuntnewsroom.com';
    }
    if (domain && mainDomain) {
      window.open(`${window.location.protocol}//${domain}.${redirectMainDomain}`);
    }
  };
  const [publishing, setPublishing] = useState(false);
  async function handlePublishClicked() {
    setPublishing(true);
    try {
      await sitesPublishSiteAPI(siteRef);
      setPublishing(false);
    } catch (e) {
      setPublishing(false);
    }
  }
  return (
    <nav className="bg-white fixed w-full z-10 top-0 border-b">
      <div className="px-5 flex">
        <div className="relative flex items-center h-12 flex-1 space-x-2">
          <Link to={`/organization/${org.id}/site/${editSite.id}`}>
            <DefaultButton variant="outline-light" size="small">
              <svg className="h-3 w-3 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back
            </DefaultButton>
          </Link>
        </div>
        <div className="flex items-center space-x-2">
          <DefaultButton
            size="small"
            onClick={handlePublishClicked}
            loading={publishing}
            disabled={publishing}
          >
            Publish
          </DefaultButton>
          <DefaultButton variant="outline-light" size="small" onClick={handleVisitButtonClicked}>
            Visit
            {' '}
            <svg className="h-3 w-3 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </DefaultButton>
        </div>
      </div>
    </nav>
  );
};

export default EditSiteNavbar;
