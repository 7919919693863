import React, { useState, useRef } from 'react';
import { Transition } from '@headlessui/react';
import DefaultButton from './DefaultButton';
import { siteUnpublishSiteAPI } from '../api/sites';
import { useSite } from '../contexts/SiteContext';
import { useOrg } from '../contexts/OrgContext';

import useOutsideAlerter from '../hooks/useOutsideAlerter';
import useGetDomains from '../hooks/useGetDomains';

export default function SitePageNavbarDropdown() {
  const { site, setSite, siteRef } = useSite();
  const { org } = useOrg();
  const [isOpen, setIsOpen] = useState(false);
  async function handleUnpublishClicked() {
    await siteUnpublishSiteAPI(siteRef);
    setSite({
      ...site,
      published: false,
    });
  }
  const { mainDomain } = useGetDomains(true);
  function handleVisitClicked() {
    const { domain } = org;
    let redirectMainDomain = mainDomain;
    if (mainDomain === 'newsroom.presshunt.co') {
      redirectMainDomain = 'presshuntnewsroom.com';
    }
    if (domain && mainDomain) {
      window.open(`${window.location.protocol}//${domain}.${redirectMainDomain}`);
    }
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setIsOpen(false));
  return (
    <div ref={wrapperRef} className="relative inline-block text-left">
      <DefaultButton variant="light" onClick={() => setIsOpen(!isOpen)}>
        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>

      </DefaultButton>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button onClick={handleVisitClicked} className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Visit</button>
          </div>
          {site.published && (
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button onClick={handleUnpublishClicked} className="w-full text-left block px-4 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700" role="menuitem">Unpublish</button>
            </div>
          )}
        </div>
      </Transition>
    </div>
  );
}
