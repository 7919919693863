import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { postsGetPostAPI } from '../api/posts';
import { useSite } from './SiteContext';
import { refsGetPostRef } from '../api/refs';

const PostContext = React.createContext({
  post: null,
  setPost: () => {},
  postRef: null,
  setPostRef() {},
  isArchiveModalOpen: false,
  setIsArchiveModalOpen: () => {},
});

export function usePost() {
  return useContext(PostContext);
}

/**
 *
 * @param {String} uid User ID
 * @param {String} sid Site ID
 * @param {String} pid Post ID
 */
export function useFetchPost(pid) {
  const { setPost, setPostRef } = useContext(PostContext);
  const { siteRef } = useSite();
  useEffect(() => {
    const postRef = refsGetPostRef(siteRef, pid);
    setPostRef(postRef);
    async function fetch() {
      const tmpPost = await postsGetPostAPI(postRef);
      setPost(tmpPost);
    }
    fetch();
  }, [pid, setPost, siteRef, setPostRef]);
}

export function useUpdatePost() {
  const { post, setPost } = useContext(PostContext);
  return function updatePost(fields) {
    const value = { ...post };
    Object.keys(fields).forEach((key) => {
      value[key] = fields[key];
    });
    setPost(value);
  };
}

export function PostProvider({ children }) {
  const [post, setPost] = useState();
  const [postRef, setPostRef] = useState();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  return (
    <PostContext.Provider value={{
      post, setPost, postRef, setPostRef, isArchiveModalOpen, setIsArchiveModalOpen,
    }}
    >
      {children}
    </PostContext.Provider>
  );
}

PostProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
