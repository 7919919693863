import React, { useState } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { postsPublishPostAPI } from '../../../api/posts';
import { refsGetPostRef } from '../../../api/refs';
import { usePost, useUpdatePost } from '../../../contexts/PostContext';
import { useSite } from '../../../contexts/SiteContext';
import DefaultButton from '../../DefaultButton';

export default function SitePageNavbarPostButtons() {
  const { params: { postId } } = useRouteMatch();
  const [publishing, setPublishing] = useState(false);
  const { siteRef } = useSite();
  const { post, setIsArchiveModalOpen } = usePost();
  const updatePost = useUpdatePost();
  const published = post && post.published;
  async function handlePublishClicked() {
    setPublishing(true);
    const postRef = refsGetPostRef(siteRef, postId);
    await postsPublishPostAPI(postRef, !published);
    updatePost({ published: !published });
    setPublishing(false);
  }
  return post ? (
    <>
      <DefaultButton
        onClick={() => setIsArchiveModalOpen(true)}
        variant="danger"
      >
        Archive
      </DefaultButton>
      <DefaultButton
        onClick={handlePublishClicked}
        loading={publishing}
        disabled={publishing}
      >
        {published ? 'Unpublish' : 'Publish'}
      </DefaultButton>
    </>
  ) : null;
}
