import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { Dialog, Transition } from '@headlessui/react';
import { useForm, Controller } from 'react-hook-form';
import DefaultButton from '../DefaultButton';
import { clippingsAddClippingAPI, clippingsRemoveClippingAPI, clippingsUpdateClippingAPI } from '../../api/clippings';
import { useSite } from '../../contexts/SiteContext';
import { useAuth } from '../../contexts/AuthContext';
import { useOrg } from '../../contexts/OrgContext';
import {
  useAppendClipping, useCoverage, useRemoveClipping, useUpdateClipping,
} from '../../contexts/CoverageContext';
import SiteCoveragePageTagInput from './SiteCoveragePageTagInput';
import {
  tagsCreateTagAPI, tagsGetTagAPI, tagsUpdateTagAPI, tagsDeleteTagAPI,
} from '../../api/clipping-tags';

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
  }
`;
export default function SiteCoveragePageClippingModal() {
  const { isModalOpen, setIsModalOpen, modalClipping: clipping } = useCoverage();
  const updateClipping = useUpdateClipping();
  const appendClipping = useAppendClipping();
  const removeClipping = useRemoveClipping();
  const closeModal = () => setIsModalOpen(false);
  const {
    handleSubmit, control, register, setValue, formState: { dirtyFields }, reset,
  } = useForm();
  const [localImage, setLocalImage] = useState();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState();
  useEffect(() => {
    if (isModalOpen) {
      register('photo', { required: !clipping });
      setLocalImage(null);
    }
  }, [register, isModalOpen, setLocalImage, clipping]);
  const { user: { uid } } = useAuth();
  const { siteRef, site: { id: sid } } = useSite();
  const { org: { id: oid } } = useOrg();
  const isEditing = !!clipping;
  useEffect(() => {
    if (isEditing) {
      setTags(clipping.tags);
    }
  }, [isEditing]);

  async function removeTag(tag, existTag) {
    if (existTag.exists) {
      if (existTag.count > 1) {
        await tagsUpdateTagAPI(uid, oid, sid, tag.id, { count: existTag.count - 1 });
      } else {
        await tagsDeleteTagAPI(uid, oid, sid, tag.id);
      }
    }
  }

  async function addTag(tag, existTag) {
    if (existTag.exists) {
      await tagsUpdateTagAPI(uid, oid, sid, tag.id, { count: existTag.count + 1 });
    } else {
      await tagsCreateTagAPI(uid, oid, sid, tag.id);
    }
  }

  async function handleTags(changeTags, method) {
    for (const [key, value] of changeTags.entries()) {
      const existTag = await tagsGetTagAPI(uid, oid, sid, value.id);
      if (method) {
        await addTag(value, existTag);
      } else {
        await removeTag(value, existTag);
      }
    }
  }

  async function onSubmit(data) {
    setLoading(true);
    if (isEditing) {
      const updates = {};
      Object.keys(dirtyFields).forEach((key) => {
        updates[key] = data[key];
      });
      updates.tags = tags;
      await clippingsUpdateClippingAPI(siteRef, clipping.id, updates);
      const removeTags = clipping.tags.filter((x) => !tags.includes(x));
      await handleTags(removeTags, false);
      const newTags = tags.filter((x) => !clipping.tags.includes(x));
      await handleTags(newTags, true);
      updateClipping(clipping.id, updates);
    } else {
      data.tags = tags;
      const newClipping = await clippingsAddClippingAPI(siteRef, data);
      await handleTags(tags, true);
      appendClipping(newClipping);
    }
    reset();
    setLoading(false);
    closeModal();
  }

  const title = isEditing ? 'Edit Clipping' : 'Add Clipping';
  const fileInputRef = useRef();
  const handleFileChanged = ({ target: { files } }) => {
    if (files.length === 0) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setLocalImage(reader.result);
      setValue('photo', reader.result, { shouldDirty: true });
    };
    reader.readAsDataURL(files[0]);
  };
  const image = localImage || (clipping && clipping.photo);
  async function handleRemoveClicked() {
    if (clipping) {
      await clippingsRemoveClippingAPI(siteRef, clipping.id);
      removeClipping(clipping.id);
    }
    closeModal();
  }
  async function updateTags(inputTags, tag, method) {
    await setTags(inputTags);
  }

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <form
              className="inline-block w-full max-w-md p-6 my-8 overflow-scroll text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              <div className="mt-2 border-b border-gray-200 pb-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Title
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      name="title"
                      autoComplete="off"
                      defaultValue={clipping && clipping.title}
                      className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Date
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      control={control}
                      name="date"
                      defaultValue={clipping && clipping.date}
                      render={({
                        onChange, onBlur, value,
                      }) => (
                        <DatePickerStyled>
                          <DatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            customInput={(
                              <input
                                type="text"
                                autoComplete="off"
                                className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                              />
                          )}
                          />
                        </DatePickerStyled>
                      )}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    External URL
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      name="externalURL"
                      autoComplete="off"
                      defaultValue={clipping && clipping.externalURL}
                      className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Outlet
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      name="outlet"
                      autoComplete="off"
                      defaultValue={clipping && clipping.outlet}
                      className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                      ref={register()}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:pt-5">
                  <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                    Photo
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="flex items-center">
                      {image && <img src={image} alt="" className="mr-5 object-cover h-20 w-20 rounded-full overflow-hidden" />}
                      <button
                        type="button"
                        className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => fileInputRef.current.click()}
                      >
                        Change
                      </button>
                      <input
                        type="file"
                        accept="image/*"
                        name="photo"
                        ref={(fileInput) => { fileInputRef.current = fileInput; }}
                        className="hidden"
                        onChange={handleFileChanged}
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Tags
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <SiteCoveragePageTagInput onChange={updateTags} defaultValue={(clipping && clipping.tags) ?? []} uid={uid} sid={sid} oid={oid} />
                  </div>
                </div>
              </div>

              <div className="mt-4 flex space-x-2 justify-end">
                {isEditing && <DefaultButton variant="danger" onClick={handleRemoveClicked} disabled={loading}>Remove</DefaultButton>}
                <DefaultButton variant="light" onClick={closeModal} disabled={loading}>Cancel</DefaultButton>
                <DefaultButton type="submit" loading={loading} disabled={loading}>Save</DefaultButton>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
