import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getSizeClasses = (size) => {
  switch (size) {
    case 'small':
      return 'px-3 py-1.5';
    case 'large':
      return 'px-6 py-4 text-base';
    case 'normal':
    default:
      return 'px-4 py-2';
  }
};

const getColorClasses = (variant) => {
  switch (variant) {
    case 'outline-light':
      return 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-100 focus:outline-none active:bg-gray-200';
    case 'light':
      return 'text-gray-800 bg-gray-50 border border-gray-300 hover:bg-gray-100 focus:outline-none active:bg-gray-200';
    case 'outline-danger':
      return 'text-red-500 bg-white border border-red-300 hover:text-white hover:bg-red-500 hover:border-none focus:outline-none active:bg-red-400';
    case 'danger':
      return 'text-red-700 bg-red-200 hover:bg-red-300 focus:outline-none active:bg-red-400';
    case 'primary':
    default:
      return 'text-white bg-blue-1000 hover:opacity-75 focus:outline-none transition ease-in-out duration-150';
  }
};

const defaultClasses = 'border border-transparent text-xs leading-4 font-medium rounded transition ease-in-out duration-150 flex items-center';
const disabledClasses = 'disabled:opacity-50 disabled:cursor-default dhover';
const fullBlockClass = 'w-full block text-center justify-center';

export default function DefaultButton({
  children, size, onClick, type, variant, disabled, loading, block, className,
}) {
  const classes = classNames(
    defaultClasses,
    disabledClasses,
    getSizeClasses(size),
    getColorClasses(variant),
    block && fullBlockClass,
    className,
  );
  return (
    <button
      onClick={onClick}
      onMouseDown={(e) => e.preventDefault()}
      type={type}
      className={classes}
      disabled={disabled}
    >
      {loading && (
      <svg className="animate-spin h-4 w-4 mr-3" viewBox="0 0 24 24" fill="none">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
      )}
      {children}
    </button>
  );
}

DefaultButton.defaultProps = {
  children: null,
  size: 'normal',
  onClick: null,
  type: 'button',
  variant: 'primary',
  disabled: false,
  loading: false,
  block: false,
  className: '',
};

DefaultButton.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
};
