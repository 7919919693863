import React, { useState, useRef } from 'react';
import { Transition } from '@headlessui/react';
import DefaultButton from '../DefaultButton';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import { useAsset } from '../../contexts/AssetContext';
import { useSite } from '../../contexts/SiteContext';
import { mediaAssetsRemoveAssetAPI } from '../../api/media-assets';
import { useRemoveMediaAsset } from '../../contexts/MediaAssetsContext';
import SiteAssetsPageRenameModal from './SiteAssetsPageRenameModal';

export default function SiteAssetsPageAssetCardDropDown() {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef();
  const { asset: { id: aid } } = useAsset();
  const { siteRef } = useSite();
  const removeMediaAsset = useRemoveMediaAsset();
  useOutsideAlerter(wrapperRef, () => setIsOpen(false));
  async function handleRemoveClicked() {
    const deleted = await mediaAssetsRemoveAssetAPI(siteRef, aid);
    if (deleted) {
      removeMediaAsset(aid);
    }
  }
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  function handleRenameClicked() {
    setIsOpen(false);
    setIsRenameModalOpen(true);
  }
  return (
    <div ref={wrapperRef} className="inline-block text-left">
      <DefaultButton variant="light" onClick={() => setIsOpen(!isOpen)}>
        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>

      </DefaultButton>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="z-10 origin-top-right absolute right-0 mt-1 w-32 mr-2 rounded-md shadow-lg bg-white divide-y divide-gray-100 border border-gray-200">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button onClick={handleRenameClicked} className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Rename</button>
          </div>
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button onClick={handleRemoveClicked} className="w-full text-left block px-4 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700" role="menuitem">Remove</button>
          </div>
        </div>
      </Transition>
      <SiteAssetsPageRenameModal
        isOpen={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
      />
    </div>
  );
}
