import React, { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import PropTypes from 'prop-types';
import {
  ABOUT, CONTACT, COVERAGES_PREVIEW, CTA, EMAIL_CAPTURE, FAQ, FEATURE, FOOTER, GALLERY, NAVBAR, PRESS_RELEASES_PREVIEW, TEAM, TESTIMONIALS,
} from '../utils/templateTypes';

export function getDefaultSections() {
  return {
    [NAVBAR]: {
      type: NAVBAR,
      title: 'Navbar',
      selected: true,
      order: 0,
      optional: false,
      config: {
        logo: {
          text: 'Your Title',
          image: '',
          display: 'text',
        },
        links: [],
      },
      fixed: true,
      enabled: true,
    },
    [PRESS_RELEASES_PREVIEW]: {
      type: PRESS_RELEASES_PREVIEW,
      order: 1,
      optional: false,
      fixed: true,
      config: {},
      enabled: true,
      selected: true,
    },
    [ABOUT]: {
      type: ABOUT,
      title: 'About',
      selected: false,
      order: 2,
      optional: true,
      config: {
        title: 'XYZ COMPANY NEWSROOM',
        content: convertToRaw(ContentState.createFromBlockArray(
          convertFromHTML('<p>xyz company is a cool company</p>'),
        )),
      },
      enabled: true,
    },
    [FEATURE]: {
      type: FEATURE,
      title: 'Feature',
      selected: true,
      order: 3,
      optional: false,
      config: {},
      enabled: false,
    },
    [TESTIMONIALS]: {
      type: TESTIMONIALS,
      title: 'Testimonials',
      selected: false,
      order: 4,
      optional: true,
      config: {
        title: 'You\'re in great company.',
        subtitle: 'See what your clients say about us',
        testimonials: [
          {
            id: uuidv4(),
            quote: 'You Miss 100% Of The Shots You Don\'t Take.',
            name: 'Wayne Gretzky',
            about: 'Greatest Hockey Player of All Time',
            photo: 'https://cdn.vox-cdn.com/thumbor/q5AXDtVdKnQeMbXYcfg4JYnT7nM=/0x1168:2409x2774/1200x800/filters:focal(0x1168:2409x2774)/cdn.vox-cdn.com/uploads/chorus_image/image/48498201/GettyImages-51016032.0.jpg',
            url: 'http://www.gretzky.com/',
          },
          {
            id: uuidv4(),
            quote: 'Whenever I\'m about to do something, I think, \'Would an idiot do that?\' And if they would, I do not do that thing.',
            name: 'Dwight Schrute',
            about: 'Assistant to the Regional Manager, Dunder Mifflin',
            photo: 'https://theofficeanalytics.files.wordpress.com/2017/11/dwight.jpeg',
          },
        ],
      },
      enabled: true,
    },
    [TEAM]: {
      type: TEAM,
      title: 'Team',
      selected: false,
      order: 5,
      optional: true,
      config: {
        title: 'Meet our leadership',
        members: [
          {
            id: uuidv4(),
            name: 'Aaron Rodgers',
            title: 'Quarter Back / Mr. discount double-check',
            description: 'Aaron Charles Rodgers (born December 2, 1983) is an American football quarterback for the Green Bay Packers of the National Football League (NFL)',
            photo: 'https://packerswire.usatoday.com/wp-content/uploads/sites/57/2020/10/9b39c7b76ea94f91be62ceec441b22eb.jpg?w=1000&h=600&crop=1',
          },
          {
            id: uuidv4(),
            name: 'Justin Tucker',
            title: 'Kicker / Opera Singer',
            description: 'Justin Paul Tucker (born November 21, 1989) is an American football placekicker for the Baltimore Ravens of the National Football League (NFL)',
            photo: 'https://www.chicagotribune.com/resizer/8nOWGTxRlUbxL0HYaakqPbrJYJ4=/750x505/filters:quality(80)/arc-goldfish-tronc-thumbnails.s3.amazonaws.com/01-04-2019/t_15b08b0a39984ad88da95cbb01871f11_name_bal_ravens_justin_tucker_on_kicking_at_mt_bank_stadium_20150804',
          },
          {
            id: uuidv4(),
            name: 'Mr. White',
            title: 'Chemistry Teacher',
            description: 'A graduate of the California Institute of Technology (Caltech), Walter co-founded the company Gray Matter Technologies with his then-girlfriend Gretchen Schwartz and his close friend Elliott Schwartz.',
            photo: 'https://i.insider.com/5dade9bc045a3139e8686c33?width=1136&format=jpeg',
          },
          {
            id: uuidv4(),
            name: 'Michael Scott',
            title: 'Regional Manager',
            description: 'Michael Gary Scott was born on March 15, 1965 in Scranton, Pennsylvania. He came from a relatively difficult childhood of loneliness.',
            photo: 'https://img1.looper.com/img/gallery/the-offices-michael-scott-was-almost-a-murderer/intro-1591207215.jpg',
          },
        ],
      },
      enabled: true,
    },
    [FAQ]: {
      type: FAQ,
      title: 'FAQ',
      selected: false,
      order: 6,
      optional: true,
      config: {},
      enabled: false,
    },
    [COVERAGES_PREVIEW]: {
      type: COVERAGES_PREVIEW,
      selected: true,
      order: 7,
      config: {},
      enabled: true,
      fixed: true,
    },
    [CONTACT]: {
      type: CONTACT,
      title: 'Contact',
      selected: false,
      order: 8,
      optional: true,
      config: {
        phone: '+1 (555) 123-4567',
        email: 'support@example.com',
        description: 'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
        addressFirstLine: '742 Evergreen Terrace',
        addressSecondLine: 'Springfield, OR 12345',
        address: {
          street: '742 Evergreen Terrace',
          city: 'Springfield',
          state: 'OR',
          zip: '12345',
        },
      },
      enabled: true,
    },
    [GALLERY]: {
      type: GALLERY,
      title: 'Gallery',
      selected: false,
      order: 9,
      optional: true,
      config: {},
      enabled: false,
    },
    [EMAIL_CAPTURE]: {
      type: EMAIL_CAPTURE,
      title: 'Email Capture',
      selected: false,
      order: 10,
      optional: true,
      config: {},
      enabled: false,
    },
    [CTA]: {
      type: CTA,
      title: 'CTA',
      selected: true,
      order: 11,
      optional: false,
      config: {},
      enabled: false,
    },
    [FOOTER]: {
      type: FOOTER,
      title: 'Footer',
      selected: true,
      order: 12,
      optional: false,
      config: {},
      fixed: true,
      enabled: true,
    },
  };
}

const CreateSiteContext = React.createContext({
  availableSections: {},
  setAvailableSections: () => {},
});

export function useCreateSite() {
  return useContext(CreateSiteContext);
}

export function useToggleCreateSiteSection() {
  const { setAvailableSections } = useContext(CreateSiteContext);
  return function toggleCreateSiteSection(key) {
    setAvailableSections((prevSections) => ({
      ...prevSections,
      [key]: {
        ...prevSections[key],
        selected: !prevSections[key].selected,
      },
    }));
  };
}

export function CreateSiteProvider({ children }) {
  const [availableSections, setAvailableSections] = useState(getDefaultSections());
  return (
    <CreateSiteContext.Provider value={{ availableSections, setAvailableSections }}>
      {children}
    </CreateSiteContext.Provider>
  );
}

CreateSiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
