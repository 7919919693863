import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EditorBoxButton from './CreateSiteContentPickerButton';
import DefaultButton from '../DefaultButton';
import { useCreateSite, useToggleCreateSiteSection } from '../../contexts/CreateSiteContext';
import { sitesCreateSiteAPI, sitesGetPublishedSitesAPI } from '../../api/sites';
import { useOrg } from '../../contexts/OrgContext';
import { getAllLanguage } from '../../config/language';
import CreateSitePageLanguage from './CreateSitePageLanguage';

export default function CreateSiteSectionSelector() {
  const { orgRef, org } = useOrg();
  const { availableSections } = useCreateSite();
  const [loading, setLoading] = useState(false);
  const [defaultLanguages, setDefaultLanguages] = useState('en');
  const [leftLanguages, setLeftLanguages] = useState([]);
  const languages = getAllLanguage();
  useEffect(() => {
    async function getPublishSites() {
      const leftLanguage = [];
      const publishedSites = await sitesGetPublishedSitesAPI(orgRef);
      for (const [key, language] of languages.entries()) {
        const isUsed = publishedSites.some(
          (item) => item.language === language.value,
        );
        if (!isUsed) {
          leftLanguage.push(language.value);
        }
      }
      if (leftLanguage.length > 0) {
        setDefaultLanguages(leftLanguage[0]);
        setLeftLanguages(leftLanguage);
      }
    }
    getPublishSites();
  }, []);

  const allContents = Object.keys(availableSections)
    .filter((key) => availableSections[key].optional && availableSections[key].enabled)
    .map((key) => ({
      ...availableSections[key],
      key,
    }))
    .sort((a, b) => a.order - b.order);
  const toggleCreateSiteSection = useToggleCreateSiteSection();
  const handleBoxButtonClicked = (key) => () => {
    toggleCreateSiteSection(key);
  };

  const history = useHistory();
  const handleCreateClicked = async () => {
    setLoading(true);
    const selectedSectionTypes = Object.keys(availableSections)
      .filter((key) => availableSections[key].selected && availableSections[key].enabled)
      .sort((a, b) => availableSections[a].order - availableSections[b].order);
    const sections = selectedSectionTypes.map((type) => ({
      type,
      config: availableSections[type].config || {},
    }));
    const newSite = await sitesCreateSiteAPI(orgRef, sections, defaultLanguages);
    setLoading(false);
    history.push(`/organization/${org.id}/site/${newSite.id}`);
  };
  return (
    <div className="py-20">
      <div className="text-left mb-12">
        <div className="grid grid-cols-7">
          <div className="col-span-2">
            <h3 className="text-2xl leading-6 font-medium text-gray-900">
              Language
            </h3>
          </div>
          <div className="col-span-3">
            <CreateSitePageLanguage defaultValue={defaultLanguages} onChange={(test) => { setDefaultLanguages(test); }} languages={languages} leftLanguages={leftLanguages} />
          </div>
        </div>
      </div>
      <div className="text-center">
        <h3 className="text-2xl leading-6 font-medium text-gray-900 text-center">
          Content
        </h3>
        <p className="mt-2 text-lg leading-7 text-gray-500">
          Select at least one section. You can add more later.
        </p>
      </div>
      <div className="py-5 ">
        <ul className="grid grid-cols-3 gap-x-8 gap-y-6">
          {allContents.map((section) => (
            <li key={section.key}>
              <EditorBoxButton
                title={section.title}
                selected={availableSections[section.key].selected}
                onClick={handleBoxButtonClicked(section.key)}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-center gap-4">
        <DefaultButton variant="primary" onClick={handleCreateClicked} loading={loading} disabled={loading}>Create</DefaultButton>
      </div>
    </div>
  );
}
