import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import { storage } from './firebase';

/** @typedef { import('firebase').default.firestore.DocumentSnapshot } DocumentSnapshot */
/** @typedef { import('firebase').default.firestore.DocumentReference } DocumentReference */

/**
 *
 * @param {DocumentSnapshot} postDoc
 */
function processPost(postDoc) {
  const postData = postDoc.data();
  return {
    ...postData,
    id: postDoc.id,
    createTime: postData.createTime.toDate(),
    scheduledReleaseTime: postData.scheduledReleaseTime ? postData.scheduledReleaseTime.toDate() : new Date(),
  };
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 * @param {Object} post New post to be created
 */
export async function postsCreatePostAPI(siteRef, post) {
  const siteDoc = await siteRef.get();
  if (!siteDoc.exists) {
    throw new Error('Site doesn\'t exists.');
  }
  const newPostRef = await siteRef.collection('posts').add({
    ...post,
    createTime: firebase.firestore.FieldValue.serverTimestamp(),
    scheduledReleaseTime: firebase.firestore.FieldValue.serverTimestamp(),
  });
  const newPostDoc = await newPostRef.get();
  return processPost(newPostDoc);
}

/**
 *
 * @param {DocumentReference} postRef Post Doc Ref
 * @param {Object} fields Fields of post to be updated
 */
export async function postsUpdatePostAPI(postRef, fields) {
  const postDoc = await postRef.get();
  if (!postDoc.exists) {
    throw new Error('Post doesn\' exist.');
  }

  await postRef.update(fields);
}

/**
 * Publish or unpublish a post.
 * @param {DocumentReference} postRef Post Doc Ref
 * @param {Boolean} published Indicate whether the post should be published
 */
export async function postsPublishPostAPI(postRef, published) {
  const postDoc = await postRef.get();
  if (!postDoc.exists) {
    throw new Error('Post doesn\' exist.');
  }

  const updates = {
    published,
  };

  if (published) {
    updates.lastPublished = firebase.firestore.FieldValue.serverTimestamp();
  }

  await postRef.update(updates);
}

/**
 * Archive a post.
 * @param {DocumentReference} postRef Post Doc Ref
 * @param {Boolean} archive Indicate whether the post should be archive
 */
export async function postsArchivePostAPI(postRef, archived) {
  const postDoc = await postRef.get();
  if (!postDoc.exists) {
    throw new Error('Post doesn\' exist.');
  }

  const updates = {
    archived,
  };

  if (archived) {
    updates.lastArchived = firebase.firestore.FieldValue.serverTimestamp();
  }

  await postRef.update(updates);
}

/**
 *
 * @param {String} pid Post iD
 * @param {String} image image
 * @return {String} Uploaded image url
 */
export async function postUploadCoverPhotoAPI(pid, image) {
  const ref = storage.ref().child(`posts/${pid}/cover/${uuidv4()}`);
  await ref.putString(image, 'data_url');
  return ref.getDownloadURL();
}

/**
 *
 * @param {DocumentReference} siteRef Site Doc Ref
 */
export async function postsGetPostsAPI(siteRef, limit) {
  const postsQuerySnapshot = await siteRef.collection('posts').limit(limit).get();
  return postsQuerySnapshot.docs
    .map(processPost)
    .sort((b, a) => a.scheduledReleaseTime - b.scheduledReleaseTime);
}

/**
 *
 * @param {DocumentReference} postRef Post Doc Ref
 */
export async function postsGetPostAPI(postRef) {
  const postDoc = await postRef.get();
  if (!postDoc.exists) {
    throw new Error('Post doesn\'t exist.');
  }

  return processPost(postDoc);
}
