import React from 'react';
import PropTypes from 'prop-types';

export default function EditSiteEditorFormUISelect({
  label, options, onChange, defaultValue,
}) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
      <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <select
          className="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={defaultValue}
          onChange={({ target: { value } }) => onChange(value)}
        >
          {options.map((option) => <option key={option.value} value={option.value}>{option.name}</option>)}
        </select>
      </div>
    </div>
  );
}

EditSiteEditorFormUISelect.defaultProps = {
  options: [],
  defaultValue: null,
};

EditSiteEditorFormUISelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
};
