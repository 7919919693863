import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { postsCreatePostAPI } from '../../../api/posts';
import { useSite } from '../../../contexts/SiteContext';
import DefaultButton from '../../DefaultButton';

export default function SitePageNavbarBlogButtons() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { siteRef } = useSite();
  async function handleCreatePostClicked() {
    const boilerplatePost = {
      title: 'My Press Release',
      language: 'english',
    };
    const newPost = await postsCreatePostAPI(siteRef, boilerplatePost);
    history.push(`${url}/${newPost.id}`);
  }
  return (
    <DefaultButton onClick={handleCreatePostClicked}>Create Press Release +</DefaultButton>
  );
}
