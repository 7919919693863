import React from 'react';
import PropTypes from 'prop-types';

export default function PublishedAssetCard({ asset }) {
  const contentType = asset.fileMeta && asset.fileMeta.contentType;
  const preview = (contentType && contentType.startsWith('image/')) ? (
    <img src={asset.thumbnailURL || asset.originalURL} alt="" className="w-full h-48 object-contain" />
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-full h-48">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
    </svg>
  );
  return (
    <div className="mb-8 w-full md:w-1/2 lg:w-1/4 px-4">
      <div className="bg-white rounded">
        <a href={asset.originalURL}>
          {preview}
        </a>
        <div className="p-6">
          <h3 className="overflow-ellipsis whitespace-nowrap overflow-hidden flex-grow mb-4 font-bold font-heading">{asset.fileName}</h3>
        </div>
      </div>
    </div>
  );
}

PublishedAssetCard.propTypes = {
  asset: PropTypes.object.isRequired,
};
