import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import DefaultButton from '../DefaultButton';
import PublishedBlogPageLanguage from './PublishedBlogPageLanguage';

export default function PublishedBlogPageLanguageModal({ isOpen, closeModal, submitCallback }) {
  const [language, setLanguage] = useState('');

  async function onSubmit() {
    submitCallback(language);
  }

  const { handleSubmit } = useForm();
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <form
              className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Choose Your Language
              </Dialog.Title>

              <div className="mt-4">
                <PublishedBlogPageLanguage onChange={setLanguage} />
              </div>

              <div className="mt-4 flex space-x-2 justify-end">
                <DefaultButton type="submit" onClick={closeModal}>Save</DefaultButton>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

PublishedBlogPageLanguageModal.defaultProps = {
  isOpen: false,
  closeModal: () => {},
};

PublishedBlogPageLanguageModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};
