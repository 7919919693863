import React from 'react';
import PropTypes from 'prop-types';

export default function EditSiteEditorFormUIInput({ label, value, onChange }) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
      <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type="text"
          className="block w-full border-gray-300 rounded-md sm:text-sm sm:leading-5"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

EditSiteEditorFormUIInput.defaultProps = {
  label: '',
  value: '',
  onChange: null,
};

EditSiteEditorFormUIInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
