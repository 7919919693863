import React from 'react';
import TemplateNavbar from '../Template/TemplateNavbar';
import TemplateAbout from '../Template/TemplateAbout';
// import TemplateFeature from '../Template/TemplateFeature';
// import TemplateCTA from '../Template/TemplateCTA';
import TemplateTestimonials from '../Template/TemplateTestimonials';
import TemplateTeam from '../Template/TemplateTeam';
// import TemplateFAQ from '../Template/TemplateFAQ';
import TemplateContact from '../Template/TemplateContact';
// import TemplateGallery from '../Template/TemplateGallery';
import TemplateEmailCapture from '../Template/TemplateEmailCapture';
import TemplateFooter from '../Template/TemplateFooter';
import { useCreateSite } from '../../contexts/CreateSiteContext';

export default function CreateSitePreview() {
  const { availableSections } = useCreateSite();

  return (
    <>
      <TemplateNavbar config={availableSections.navbar.config} disabled />
      {availableSections.about.selected && <TemplateAbout config={availableSections.about.config} />}

      {/* <TemplateFeature /> */}
      {availableSections.testimonials.selected && <TemplateTestimonials config={availableSections.testimonials.config} />}
      {availableSections.team.selected && <TemplateTeam config={availableSections.team.config} />}
      {/* {availableSections.faq.selected && <TemplateFAQ config={availableSections.faq.config} />} */}
      {availableSections.contact.selected && <TemplateContact config={availableSections.contact.config} />}
      {/* {availableSections.gallery.selected && <TemplateGallery config={availableSections.gallery.config} />} */}
      {availableSections.emailCapture.selected && <TemplateEmailCapture config={availableSections.emailCapture.config} />}
      {/* <TemplateCTA /> */}
      <TemplateFooter socialMedias={[]} />
    </>
  );
}
