import React from 'react';
import PropTypes from 'prop-types';

export default function EditSiteEditorAddSectionModalButton({ children, onClick }) {
  return (
    <button
      className="border border-gray-300 hover:border-gray-400 w-full grid items-center h-16 focus:outline-none rounded"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

EditSiteEditorAddSectionModalButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
