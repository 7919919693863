import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function SitePageBreadCrumbs({ crumbs }) {
  return (
    <ol className="flex-1 flex">
      {crumbs.filter((crumb) => !!crumb.name).map((crumb, idx) => {
        const body = crumb.path ? (
          <Link to={crumb.path} className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">{crumb.name}</Link>
        ) : (
          <span className="text-sm leading-5 font-medium text-gray-700">{crumb.name}</span>
        );
        return (
          <div key={crumb.name} className="flex items-center">
            {idx > 0 && (
            <svg className="flex-shrink-0 h-5 w-5 text-gray-400 mx-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
            )}
            {body}
          </div>
        );
      })}
    </ol>
  );
}

SitePageBreadCrumbs.defaultProps = {
  crumbs: [],
};

SitePageBreadCrumbs.propTypes = {
  crumbs: PropTypes.array,
};
