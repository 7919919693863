import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import EditSiteEditorFormUITextArea from './EditSiteEditorFormUI/EditSiteEditorFormUITextArea';
import EditSiteEditorFormUIInput from './EditSiteEditorFormUI/EditSiteEditorFormUIInput';
import EditSiteEditorFormUIAddress from './EditSiteEditorFormUI/EditSiteEditorFormUIAddress';
import EditSiteEditorFormUIProfile from './EditSiteEditorFormUI/EditSiteEditorFormUIProfile/EditSiteEditorFormUIProfile';
import EditSiteEditorFormGroup from './EditSiteEditorFormGroup';
import { sitesUploadConfigGroupItemImageAPI } from '../../../../api/sites';
import { useEditSite } from '../../../../contexts/EditSiteContext';

const EditSiteEditorFormContact = ({ section, onInputChange }) => {
  const { config, id: sectionId } = section;
  const { editSite: { id: siteId } } = useEditSite();
  const spokespeople = (config && config.spokespeople) || [];
  const socialMedias = (config && config.socialMedias) || [];
  const handleUpdateSocialMediaField = (socialMediaId) => (fieldKey) => ({ target: { value } }) => {
    const configSocialMediaValue = [...socialMedias];
    const index = configSocialMediaValue
      .map((socialMedia) => socialMedia.id)
      .indexOf(socialMediaId);
    configSocialMediaValue[index][fieldKey] = value;
    onInputChange('socialMedias')({ target: { value: configSocialMediaValue } });
  };
  const handleAddSocialMediaClicked = () => {
    const value = [...socialMedias, {
      id: uuidv4(),
      url: '',
      media: 'facebook',
    }];
    onInputChange('socialMedias')({ target: { value } });
  };
  const handleRemoveSocialMedia = (socialMediaId) => () => {
    const value = [...socialMedias].filter((clipping) => clipping.id !== socialMediaId);
    onInputChange('socialMedias')({ target: { value } });
  };
  const handleAddClicked = () => {
    const value = [...spokespeople, {
      id: uuidv4(),
      name: '',
      title: '',
      email: '',
      phone: '',
      description: '',
    }];
    onInputChange('spokespeople')({ target: { value } });
  };
  const handleUpdateSpokespeopleField = (spokepersonId) => (fieldKey) => ({ target: { value } }) => {
    const configSpokepeopleValue = [...spokespeople];
    const index = configSpokepeopleValue.map((testimonial) => testimonial.id).indexOf(spokepersonId);
    configSpokepeopleValue[index][fieldKey] = value;
    onInputChange('spokespeople')({ target: { value: configSpokepeopleValue } });
  };
  const handleSpokespersonImageLoaded = (spokepersonId) => async (image) => {
    const url = await sitesUploadConfigGroupItemImageAPI(siteId, sectionId, spokepersonId, image);
    handleUpdateSpokespeopleField(spokepersonId)('photo')({ target: { value: url } });
  };
  const removeLink = (spokepersonId) => () => {
    const value = [...spokespeople].filter((spokeperson) => spokeperson.id !== spokepersonId);
    onInputChange('spokespeople')({ target: { value } });
  };
  return (
    <>
      <div className="mb-5">
        <EditSiteEditorFormUITextArea
          label="Description"
          value={config && config.description}
          onChange={onInputChange('description')}
        />
      </div>
      <div className="mb-5">
        <EditSiteEditorFormUIAddress
          value={config && config.address}
          onChange={onInputChange('address')}
        />
      </div>
      <div className="mb-5">
        <EditSiteEditorFormUIInput
          label="Phone Number"
          value={config && config.phone}
          onChange={onInputChange('phone')}
        />
      </div>
      <div className="mb-5">
        <EditSiteEditorFormUIInput
          label="Email Address"
          value={config && config.email}
          onChange={onInputChange('email')}
        />
      </div>
      <EditSiteEditorFormGroup title="Spokespeople" addButtonText="+ Add Spokesperson" onAddClick={handleAddClicked}>
        {spokespeople.map(((spokeperson) => (
          <EditSiteEditorFormUIProfile
            key={spokeperson.id}
            fields={[
              {
                name: 'name',
                value: spokeperson.name,
                placeholder: 'Name',
                type: 'text',
              }, {
                name: 'title',
                value: spokeperson.title,
                placeholder: 'Title',
                type: 'text',
              }, {
                name: 'email',
                value: spokeperson.email,
                placeholder: 'Email',
                type: 'text',
              }, {
                name: 'phone',
                value: spokeperson.phone,
                placeholder: 'Phone Number',
                type: 'text',
              }, {
                name: 'description',
                value: spokeperson.description,
                placeholder: 'Description',
                type: 'textarea',
              },
            ]}
            onInputChange={handleUpdateSpokespeopleField(spokeperson.id)}
            onImageLoaded={handleSpokespersonImageLoaded(spokeperson.id)}
            photo={spokeperson.photo}
            onRemove={removeLink(spokeperson.id)}
          />
        )))}
      </EditSiteEditorFormGroup>
      <EditSiteEditorFormGroup title="Social Media" addButtonText="+ Social Media" onAddClick={handleAddSocialMediaClicked}>
        {socialMedias.map((socialMedia) => (
          <EditSiteEditorFormUIProfile
            key={socialMedia.id}
            fields={[
              {
                name: 'media',
                value: socialMedia.media,
                type: 'select',
                options: [
                  {
                    value: 'facebook',
                    name: 'Facebook',
                  },
                  {
                    value: 'twitter',
                    name: 'Twitter',
                  },
                  {
                    value: 'linkedin',
                    name: 'LinkedIn',
                  },
                  {
                    value: 'youtube',
                    name: 'YouTube',
                  },
                  {
                    value: 'instagram',
                    name: 'Instagram',
                  },
                  {
                    value: 'officialWebsite',
                    name: 'Official Website',
                  },
                ],
              },
              {
                name: 'url',
                value: socialMedia.url,
                placeholder: 'URL',
                type: 'text',
              }]}
            onInputChange={handleUpdateSocialMediaField(socialMedia.id)}
            onRemove={handleRemoveSocialMedia(socialMedia.id)}
            photoEnabled={false}
          />
        ))}
      </EditSiteEditorFormGroup>
    </>
  );
};

EditSiteEditorFormContact.propTypes = {
  section: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default EditSiteEditorFormContact;
