import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const LanguageContext = React.createContext({
  language: '',
  setLanguage: () => {},
});

export function useUpdateLang(language) {
  const { setLanguage } = useContext(LanguageContext);
  useEffect(() => {
    const set = async () => {
      setLanguage(language);
    };
    set();
  }, [language]);
  return language;
}

export function useLanguage() {
  return useContext(LanguageContext);
}

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState();
  return (
    <LanguageContext.Provider value={{
      language, setLanguage,
    }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
