import React, { useState, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import PropTypes from 'prop-types';
import {
  tagsGetTagsAPI,
} from '../../api/clipping-tags';

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const inputClass = 'flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300';
const tagClass = 'bg-blue-200 inline-flex items-center text-sm rounded pl-2 mx-2 mt-2 overflow-hidden ';
const removeTagClass = 'ml-2 w-6 h-8 inline-block align-middle text-gray-500 bg-blue-300 focus:outline-none';

export default function SiteCoveragePageTagInput({
  onChange, defaultValue, uid, oid, sid,
}) {
  const [tags, setTags] = useState(defaultValue);
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    const getSuggestions = async () => {
      const allTags = await tagsGetTagsAPI(uid, oid, sid);
      setSuggestions(allTags);
    };
    getSuggestions();
  }, [tags, uid, sid, oid]);

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, index) => index !== i);
    onChange(newTags, tags[i], false);
    setTags(newTags);
  };

  const handleAddition = (tag) => {
    const newTags = [...tags, tag];
    onChange(newTags, tag, true);
    setTags(newTags);
  };

  return (
    <ReactTags
      classNames={{
        tagInputField: inputClass,
        tag: tagClass,
        remove: removeTagClass,
      }}
      suggestions={suggestions}
      tags={tags}
      delimiters={delimiters}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      allowDragDrop={false}
      inputFieldPosition="top"
      autofocus={false}
      allowDeleteFromEmptyInput={false}
    />
  );
}

SiteCoveragePageTagInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.instanceOf(Array).isRequired,
  uid: PropTypes.string.isRequired,
  sid: PropTypes.string.isRequired,
  oid: PropTypes.string.isRequired,
};
