import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import DefaultButton from '../DefaultButton';
import { mediaAssetsFolderUploadFolderAPI } from '../../api/media-assets';
import { useSite } from '../../contexts/SiteContext';
import { useAppendMediaAsset } from '../../contexts/MediaAssetsContext';

export default function SiteAssetsFolderNameModal({ isOpen, closeModal }) {
  const [loading, setLoading] = useState(false);
  const { siteRef } = useSite();
  const appendMediaAsset = useAppendMediaAsset();
  async function onSubmit({ folderName }) {
    if (loading) return;
    setLoading(true);
    const newAsset = await mediaAssetsFolderUploadFolderAPI(siteRef, folderName);
    appendMediaAsset(newAsset);
    closeModal();
    setLoading(false);
  }
  const { register, handleSubmit } = useForm();
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <form
              className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Add Folder
              </Dialog.Title>
              <div className="mt-2">
                <input
                  type="text"
                  name="folderName"
                  autoComplete="off"
                  // defaultValue={folderName}
                  className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                  ref={register({ required: { value: true } })}
                />
              </div>

              <div className="mt-4 flex space-x-2 justify-end">
                <DefaultButton variant="light" onClick={closeModal}>Cancel</DefaultButton>
                <DefaultButton
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </DefaultButton>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

SiteAssetsFolderNameModal.defaultProps = {
  isOpen: false,
  closeModal: () => {},
};

SiteAssetsFolderNameModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};
