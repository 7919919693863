import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

export default function SitePostPageTitle({ title, updatePost, setSaving }) {
  const saveContent = useRef(debounce((newTitle) => {
    updatePost({
      title: newTitle,
    });
  }, 2000));

  function handleInputChanged({ target: { value } }) {
    setSaving();
    saveContent.current(value);
  }
  return (
    <input
      defaultValue={title}
      onChange={handleInputChanged}
      type="text"
      className="block w-full shadow-sm outline-none border-none shadow-none text-2xl px-0 font-medium"
    />
  );
}

SitePostPageTitle.defaultProps = {
  title: '',
};

SitePostPageTitle.propTypes = {
  title: PropTypes.string,
  updatePost: PropTypes.func.isRequired,
};
