import React from 'react';
import PropTypes from 'prop-types';

const EditSiteEditorFormUIAddress = ({ value, onChange }) => {
  const handleInputChanged = (key) => ({ target: { value: newValue } }) => {
    onChange({
      target: {
        value: {
          ...value,
          [key]: newValue,
        },
      },
    });
  };
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
      <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
        Address
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type="text"
          className="block w-full border-gray-300 rounded-md sm:text-sm sm:leading-5 mb-3"
          placeholder="Street"
          value={(value && value.street) || ''}
          onChange={handleInputChanged('street')}
        />

        <div className="flex grid grid-cols-3 gap-2">
          <input
            type="text"
            className="border-gray-300 rounded-md sm:text-sm sm:leading-5"
            placeholder="City"
            value={(value && value.city) || ''}
            onChange={handleInputChanged('city')}
          />
          <input
            type="text"
            className="border-gray-300 rounded-md sm:text-sm sm:leading-5"
            placeholder="State"
            value={(value && value.state) || ''}
            onChange={handleInputChanged('state')}
          />
          <input
            type="text"
            className="border-gray-300 rounded-md sm:text-sm sm:leading-5"
            placeholder="ZIP"
            value={(value && value.zip) || ''}
            onChange={handleInputChanged('zip')}
          />
        </div>
      </div>
    </div>
  );
};

EditSiteEditorFormUIAddress.defaultProps = {
  value: {},
  onChange: null,
};

EditSiteEditorFormUIAddress.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default EditSiteEditorFormUIAddress;
