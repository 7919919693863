import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTemplate } from '../../contexts/TemplateContext';
import TemplateItem from './TemplateItem';
import { supportedFonts } from '../../utils/supportedFonts';

export default function Template({ disabled, children, dependTemplate }) {
  const isContainer = !!children;
  const {
    template: {
      sectionIds, navbarSectionId, footerSectionId, theme,
    },
  } = useTemplate();
  let content = '';
  if (dependTemplate) {
    content = (
      <>
        {sectionIds.map((id) => <TemplateItem key={id} sectionId={id} disabled={disabled} />)}
        {children}
      </>
    );
  } else {
    content = !isContainer ? (
      <>
        {sectionIds.map((id) => <TemplateItem key={id} sectionId={id} disabled={disabled} />)}
      </>
    ) : (
      <>
        {navbarSectionId && <TemplateItem sectionId={navbarSectionId} />}
        {children}
        {footerSectionId && <TemplateItem sectionId={footerSectionId} aboutSection={{ config: {} }} />}
      </>
    );
  }

  const primaryFont = (theme && theme.primaryFont) || 'roboto';
  const fontFamily = (primaryFont && supportedFonts[primaryFont].fontFamily) || 'inherit';
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&family=STIX+Two+Text&display=swap" rel="stylesheet" />
      </Helmet>
      <div style={{ fontFamily }}>
        {content}
      </div>
    </>
  );
}

Template.defaultProps = {
  disabled: false,
  children: null,
  dependTemplate: false,
};

Template.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dependTemplate: PropTypes.bool,
};
