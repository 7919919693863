import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';

export default function DefaultToggle({
  checked, onChange, label, disabled,
}) {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-blue-1000' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-900">{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
}

DefaultToggle.defaultProps = {
  label: '',
  disabled: false,
  onChange: () => {},
};

DefaultToggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
