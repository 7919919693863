import firebase from 'firebase/app';
import { db } from './firebase';

/** @typedef { import('firebase').default.firestore.DocumentSnapshot } DocumentSnapshot */

/**
 *
 * @param {DocumentSnapshot} tagDoc
 */
function processTag(tagDoc) {
  const tagData = tagDoc.data();
  return {
    exists: true,
    ...tagData,
    id: tagDoc.id,
    createTime: tagData.createTime.toDate(),
    scheduledReleaseTime: tagData.scheduledReleaseTime ? tagData.scheduledReleaseTime.toDate() : new Date(),
  };
}

/**
 *
 * @param {String} uid User ID
 * @param {String} oid Organizations ID
 * @param {String} sid Site ID
 * @param {Object} tag New tag to be created
 */
export async function tagsCreateTagAPI(uid, oid, sid, tag) {
  const siteRef = db.collection('users').doc(uid)
    .collection('organizations').doc(oid)
    .collection('sites')
    .doc(sid);
  const siteDoc = await siteRef.get();
  if (!siteDoc.exists) {
    throw new Error('Site doesn\'t exists.');
  }

  const newTagRef = siteRef.collection('tags').doc(tag);

  await newTagRef.set({
    id: tag,
    text: tag,
    count: 1,
    createTime: firebase.firestore.FieldValue.serverTimestamp(),
    scheduledReleaseTime: firebase.firestore.FieldValue.serverTimestamp(),
  });
  const newTagDoc = await newTagRef.get();
  return processTag(newTagDoc);
}

/**
 *
 * @param {String} uid User ID
 * @param {String} oid Organizations ID
 * @param {String} sid Site ID
 * @param {String} tid Tag ID
 * @param {Object} fields Fields of tag to be updated
 */
export async function tagsUpdateTagAPI(uid, oid, sid, tid, fields) {
  const postRef = db
    .collection('users').doc(uid)
    .collection('organizations').doc(oid)
    .collection('sites')
    .doc(sid)
    .collection('tags')
    .doc(tid);
  const postDoc = await postRef.get();
  if (!postDoc.exists) {
    throw new Error('Post doesn\' exist.');
  }

  await postRef.update(fields);
}

/**
 *
 * @param {String} uid User ID
 * @param {String} oid Organizations ID
 * @param {String} sid Site ID
 */
export async function tagsGetTagsAPI(uid, oid, sid, limit) {
  const siteRef = db.collection('users').doc(uid)
    .collection('organizations').doc(oid)
    .collection('sites')
    .doc(sid);
  const tagsQuerySnapshot = await siteRef.collection('tags').limit(limit).get();
  return tagsQuerySnapshot.docs
    .map(processTag)
    .sort((b, a) => a.scheduledReleaseTime - b.scheduledReleaseTime)
    .filter((tag) => (tag.scheduledReleaseTime || tag.createTime) <= new Date());
}

/**
 *
 * @param {String} uid User ID
 * @param {String} oid Organizations ID
 * @param {String} sid Site ID
 * @param {String} tid tag ID
 */
export async function tagsGetTagAPI(uid, oid, sid, tid) {
  const tagRef = db
    .collection('users').doc(uid)
    .collection('organizations').doc(oid)
    .collection('sites')
    .doc(sid)
    .collection('tags')
    .doc(tid);
  const tagDoc = await tagRef.get();
  if (!tagDoc.exists) {
    return {
      exists: false,
    };
  }

  return processTag(tagDoc);
}

/**
 *
 * @param {String} uid User ID
 * @param {String} oid Organizations ID
 * @param {String} sid Site ID
 * @param {String} tid tag ID
 */
export async function tagsDeleteTagAPI(uid, oid, sid, tid) {
  const tagRef = db
    .collection('users').doc(uid)
    .collection('organizations').doc(oid)
    .collection('sites')
    .doc(sid)
    .collection('tags')
    .doc(tid);
  await tagRef.delete();
  return 'true';
}
