import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import useFetchPost from '../../hooks/useFetchPost';
import PublishedNotFound from './PublishedNotFound';
import './PublishedPostPage.scss';
import useConvertFromEditorRawToHTML from '../../hooks/useConvertFromEditorRawToHTML';
import Template from '../Template/Template';
import PublishedTags from './PublishedTags';
import SitePageBreadCrumbs from '../SitePage/SitePageBreadCrumbs';
import { useLanguage } from '../../contexts/LanguageContext';
import { getI18N } from '../../config/i18n';

export default function PublishedPostPage({ site }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { postId } = useParams();
  const { post, loading } = useFetchPost(site.ownerId, site.orgId, site.siteId, postId);
  const [editor, setEditor] = useState();
  const { language: currentLanguage } = useLanguage();
  const i18n = getI18N(currentLanguage);

  useEffect(() => {
    try {
      setEditor(post && (post.editorStringified ? JSON.parse(post.editorStringified) : post.editor));
    } catch {
      setEditor(null);
    }
  }, [post]);

  const markup = useConvertFromEditorRawToHTML(editor);
  if ((!post && !loading) || (post?.archived)) {
    return <PublishedNotFound />;
  } if (!post) {
    return null;
  }

  const downloadImage = (url) => {
    setDropdownOpen(false);
    saveAs(url, 'image.jpg');
  };

  return (
    <Template>

      <div className="max-w-7xl mx-auto px-4">
        <SitePageBreadCrumbs crumbs={[{ name: i18n.home, path: '/:lang' }, { name: i18n.pressReleases, path: '/:lang/press-releases' }, { name: post.title }]} />
      </div>

      <div className="max-w-6xl container mx-auto px-4 mt-5">
        {post.cover && (
          <div className="mb-10">
            <div className="flex justify-between">
              <div />
              <div className="relative mb-1">
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="relative p-1 z-10 block rounded-md bg-white focus:outline-none shadow-sm border border-gray-300 hover:border-gray-500 cursor-pointer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                  </svg>
                </button>

                <div className={`${dropdownOpen ? '' : 'hidden'} absolute right-0 py-2 w-48 bg-white rounded-md shadow-xl z-20`}>
                  <Link
                    onClick={() => downloadImage(post.cover)}
                    className="block px-2 py-1 text-sm capitalize text-gray-700"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <p className="px-2">Download</p>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <img src={post.cover} className="w-full h-80 object-cover rounded-lg" alt="" />
          </div>
        )}

        <div className="mb-10 max-w-2xl mx-auto text-center">
          <span className="text-base lg:text-xl text-gray-400">{moment(post.scheduledReleaseTime ?? post.createTime).format('DD MMMM, YYYY')}</span>
          <div className="mt-2">
            <h2 className="mb-6 text-3xl lg:text-4xl font-medium font-heading">{post.title}</h2>
            <div className="flex justify-center">
              <div className="text-left">
                <PublishedTags post={post} />
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-2xl mx-auto">
          <div className="post-container" dangerouslySetInnerHTML={markup} />
        </div>
      </div>
    </Template>
  );
}

PublishedPostPage.propTypes = {
  site: PropTypes.object.isRequired,
};
