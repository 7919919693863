import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { landingPageFetchSiteFromDomainAPI } from '../../api/landing-page';

const PublishedSiteContext = React.createContext({
  site: null,
  setSite: () => {},
});

export function useFetchSiteFromDomain(subdomain, language) {
  const [pageFound, setPageFound] = useState(true);
  const { setSite } = useContext(PublishedSiteContext);
  useEffect(() => {
    const fetch = async () => {
      try {
        const fetchedSite = await landingPageFetchSiteFromDomainAPI(subdomain, language);
        setSite(fetchedSite);
        setPageFound(true);
      } catch {
        setPageFound(false);
      }
    };
    fetch();
  }, [subdomain, setSite]);
  return pageFound;
}

export function usePublishedSite() {
  return useContext(PublishedSiteContext);
}

export function PublishedSiteProvider({ children }) {
  const [site, setSite] = useState();
  return (
    <PublishedSiteContext.Provider value={{
      site, setSite,
    }}
    >
      {children}
    </PublishedSiteContext.Provider>
  );
}

PublishedSiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
