import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useCoverage, useFetchClippings } from '../../contexts/CoverageContext';
import SiteCoveragePageClippingModal from './SiteCoveragePageClippingModal';
import SiteAdditionalPagesEnableToggle from '../SiteAdditionalPagesEnableToggle';
import { siteAdditionalPages } from '../SitePage/SitePage';
import SiteCoveragePageTagSelector from './SiteCoveragePageTagSelector';
import { useAuth } from '../../contexts/AuthContext';
import { useOrg } from '../../contexts/OrgContext';
import { useSite } from '../../contexts/SiteContext';
import { tagsGetTagsAPI } from '../../api/clipping-tags';

export default function SiteCoveragePage() {
  useFetchClippings();
  const [tagFilter, setTagFilter] = useState('');
  const [tags, setTags] = useState([]);
  const [selectTags, setSelectTags] = useState([]);
  const { clippings, setIsModalOpen, setModalClipping } = useCoverage();
  const [clippingItems, setClippingItems] = useState([]);
  const { user: { uid } } = useAuth();
  const { org: { id: oid } } = useOrg();
  const { site: { id: sid } } = useSite();
  function filterPostsByTag(items, filterTag) {
    const filterClippingItems = items.filter((item) => {
      if (!item.tags || item.tags.length === 0) {
        return false;
      }
      let matchTag = false;
      item.tags.map((tag) => {
        if (tag.text === filterTag) {
          matchTag = true;
        }
        return tag;
      });
      return matchTag;
    });
    setClippingItems(filterClippingItems);
  }
  useEffect(() => {
    setClippingItems(clippings);
  }, [clippings]);

  useEffect(() => {
    const getTags = async () => {
      const allTags = await tagsGetTagsAPI(uid, oid, sid);
      setTags([{ id: '', text: 'All' }, ...allTags]);
    };
    getTags();
  }, [uid, sid, oid]);

  useEffect(() => {
    setSelectTags(tagFilter);
    if (tagFilter) {
      filterPostsByTag(clippings, tagFilter);
    } else {
      setClippingItems(clippings);
    }
  }, [tagFilter]);
  return (
    <div className="flex justify-center">
      <div className="mt-10 w-full max-w-screen-md">
        <SiteAdditionalPagesEnableToggle page={siteAdditionalPages.coverage} label="Enable Coverage Page" />
        <SiteCoveragePageTagSelector onChange={setTagFilter} tagOptions={tags} />
        <ul className="divide-y divide-gray-200 divide-y mt-6 shadow rounded-md">
          {clippingItems.map((clipping) => (
            <li key={clipping.id}>
              <button
                className="w-full text-left flex hover:bg-gray-50 p-4 focus:outline-none"
                onClick={() => {
                  setModalClipping(clipping);
                  setIsModalOpen(true);
                }}
              >
                <img className="h-10 w-10 rounded-full object-cover" src={clipping.photo} alt="" />
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">{clipping.title}</p>
                  {clipping.outlet && <p className="text-sm text-gray-500">{clipping.outlet}</p>}
                  <p className="text-sm text-gray-500">{moment(clipping.date).format('L')}</p>
                  <p className="text-sm text-gray-500">{clipping.externalURL}</p>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <SiteCoveragePageClippingModal />
    </div>
  );
}
