import React from 'react';
import PropTypes from 'prop-types';
import ProfilePic from '../PeofilePic';
import { useGetTemplateTheme } from '../../contexts/TemplateContext';

export default function TemplateTestimonials({ config }) {
  const testimonials = (config && config.testimonials) || [];
  const handleNameClicked = (url) => (e) => {
    e.preventDefault();
    if (url) {
      window.open(url);
    }
  };
  const { primaryColor } = useGetTemplateTheme();
  return (
    <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-bold text-gray-800">
            {config && config.title}
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:text-xl md:max-w-3xl">
            {config && config.subtitle}
          </p>
        </div>
        {testimonials.map((testimonial) => (
          <blockquote className="mt-10" key={testimonial.id}>
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>
                {testimonial.quote}
              </p>
            </div>
            <footer className="mt-2">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <ProfilePic
                    src={testimonial.photo}
                    additionalClasses="h-10 w-10"
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  {testimonial.url ? (
                    <a
                      href="/"
                      onClick={handleNameClicked(testimonial.url)}
                      className="text-base leading-6 font-medium text-gray-900 hover:underline"
                    >
                      {testimonial.name}
                    </a>
                  ) : (
                    <div className="text-base leading-6 font-medium text-gray-900">{testimonial.name}</div>
                  )}

                  {testimonial.name && testimonial.about && (
                    <svg className="hidden md:block mx-1 h-5 w-5" style={{ color: primaryColor }} fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>
                  )}
                  <div className="text-base leading-6 font-medium text-gray-500">{testimonial.about}</div>
                </div>
              </div>
            </footer>
          </blockquote>
        ))}
      </div>
    </section>
  );
}

TemplateTestimonials.defaultProps = {
  config: null,
};

TemplateTestimonials.propTypes = {
  config: PropTypes.object,
};
