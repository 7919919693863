import React from 'react';
import { useParams } from 'react-router-dom';
import { AddSectionProvider } from '../../contexts/AddSectionContext';
import { useAuth } from '../../contexts/AuthContext';
import { useEditSite, useFetchEditSite } from '../../contexts/EditSiteContext';
import EditSiteEditorColumn from './EditSiteEditor/EditSiteEditor';
import EditSiteNavbar from './EditSiteNavbar';
import EditSitePreviewColumn from './EditSitePreviewColumn';

export default function EditSitePage() {
  const { user: { uid } } = useAuth();
  const { siteId } = useParams();
  useFetchEditSite(uid, siteId);

  const { editSite } = useEditSite();

  return editSite ? (
    <>
      <EditSiteNavbar />
      <div className="flex py-3 bg-gray-50">
        <AddSectionProvider>
          <EditSiteEditorColumn />
        </AddSectionProvider>
        <EditSitePreviewColumn />
      </div>
    </>
  ) : null;
}
