import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const defaultContainerClasses = 'rounded-md px-3 py-1 transition ease-in-out duration-150';

const getContainerColor = (variant) => {
  switch (variant) {
    case 'danger':
      return 'bg-red-100';
    case 'success':
    default:
      return 'bg-green-100';
  }
};

const defaultTextClasses = 'text-sm font-medium';

const getTextColor = (variant) => {
  switch (variant) {
    case 'warning':
      return 'text-red-400';
    case 'danger':
      return 'text-red-500';
    case 'success':
    default:
      return 'text-green-600';
  }
};

const EditSiteEditorCellAlert = ({ variant, text }) => {
  const containerClasses = classNames(defaultContainerClasses, getContainerColor(variant));
  const textClasses = classNames(defaultTextClasses, getTextColor(variant));
  return (
    <div className={containerClasses}>
      <h3 className={textClasses}>
        {text}
      </h3>
    </div>
  );
};

EditSiteEditorCellAlert.defaultProps = {
  variant: 'success',
  text: null,
};

EditSiteEditorCellAlert.propTypes = {
  variant: PropTypes.oneOf(['success', 'danger']),
  text: PropTypes.string,
};

export default EditSiteEditorCellAlert;
