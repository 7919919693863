import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { postsGetPostsAPI } from '../api/posts';
import { refsGetSiteRefByPath } from '../api/refs';

const BlogContext = React.createContext({
  posts: [],
  setPosts: () => {},
  filteredPosts: [],
  setFilteredPosts: () => {},
});

export function useBlog() {
  return useContext(BlogContext);
}

/**
 *
 * @param {String} uid User ID
 * @param {String} oid Organization ID
 * @param {String} sid Site ID
 */
export function useFetchPosts(uid, oid, sid) {
  const { setPosts, setFilteredPosts } = useContext(BlogContext);
  useEffect(() => {
    async function fetch() {
      const siteRef = refsGetSiteRefByPath(uid, oid, sid);
      const tmpPosts = await postsGetPostsAPI(siteRef);
      setPosts(tmpPosts);
      setFilteredPosts(tmpPosts.filter((post) => (post.scheduledReleaseTime || post.createTime) <= new Date()));
    }
    fetch();
  }, [uid, oid, sid, setPosts, setFilteredPosts]);
}

function filterPostsByLanguage(posts, languagefilter) {
  return posts.filter((post) => post.language === languagefilter);
}

function filterPostsByTag(posts, tagFilter) {
  return posts.filter((post) => {
    if (!post.tags || post.tags.length === 0) {
      return false;
    }
    let matchTag = false;
    post.tags.map((tag) => {
      if (tag.text === tagFilter) {
        matchTag = true;
      }
      return tag;
    });
    return matchTag;
  });
}

/**
 *
 * @param {String} language Language that posts should be filtered by.
 * @returns {Array}
 */
export function useFilterPosts(filter) {
  let { posts } = useBlog();
  if (filter.language && filter.language !== '') {
    posts = filterPostsByLanguage(posts, filter.language);
  }

  if (filter.tag && filter.tag !== '') {
    posts = filterPostsByTag(posts, filter.tag);
  }

  return posts;
}

export function BlogProvider({ children }) {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  return (
    <BlogContext.Provider value={{ posts, setPosts, filteredPosts, setFilteredPosts }}>
      {children}
    </BlogContext.Provider>
  );
}

BlogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
