import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { sitesUploadConfigGroupItemImageAPI } from '../../../../api/sites';
import EditSiteEditorFormGroup from './EditSiteEditorFormGroup';
import EditSiteEditorFormUIProfile from './EditSiteEditorFormUI/EditSiteEditorFormUIProfile/EditSiteEditorFormUIProfile';
import EditSiteEditorFormUIInput from './EditSiteEditorFormUI/EditSiteEditorFormUIInput';
import { useEditSite } from '../../../../contexts/EditSiteContext';

const EditSiteEditorFormTeam = ({ section, onInputChange }) => {
  const { config, id: sectionId } = section;
  const { editSite: { id: siteId } } = useEditSite();
  const members = (config && config.members) || [];
  const handleUpdateMemberField = (memberId) => (fieldKey) => ({ target: { value } }) => {
    const configMembersValue = [...members];
    const index = configMembersValue.map((testimonial) => testimonial.id).indexOf(memberId);
    configMembersValue[index][fieldKey] = value;
    onInputChange('members')({ target: { value: configMembersValue } });
  };
  const handleAddClicked = () => {
    const value = [...members, {
      id: uuidv4(),
      quote: '',
      title: '',
      description: '',
      photo: '',
    }];
    onInputChange('members')({ target: { value } });
  };
  const handleMemberImageLoaded = (memberId) => async (image) => {
    const url = await sitesUploadConfigGroupItemImageAPI(siteId, sectionId, memberId, image);
    handleUpdateMemberField(memberId)('photo')({ target: { value: url } });
  };
  const removeLink = (memberId) => () => {
    const value = [...members].filter((member) => member.id !== memberId);
    onInputChange('members')({ target: { value } });
  };
  return (
    <>
      <div className="mb-5">
        <EditSiteEditorFormUIInput
          label="Title"
          value={config && config.title}
          onChange={onInputChange('title')}
        />
      </div>
      <EditSiteEditorFormGroup title="Members" addButtonText="+ Add Member" onAddClick={handleAddClicked}>
        {members.map((member) => (
          <EditSiteEditorFormUIProfile
            key={member.id}
            fields={[
              {
                name: 'name',
                value: member.name,
                placeholder: 'Name',
                type: 'text',
              }, {
                name: 'title',
                value: member.title,
                placeholder: 'Title',
                type: 'text',
              }, {
                name: 'description',
                value: member.description,
                placeholder: 'Description',
                type: 'textarea',
              }]}
            onInputChange={handleUpdateMemberField(member.id)}
            onImageLoaded={handleMemberImageLoaded(member.id)}
            photo={member.photo}
            onRemove={removeLink(member.id)}
          />
        ))}
      </EditSiteEditorFormGroup>
    </>
  );
};

EditSiteEditorFormTeam.propTypes = {
  section: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default EditSiteEditorFormTeam;
