import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { HexColorPicker } from 'react-colorful';

export default function EditSiteEditorFormUIColorPicker({ label, value, onChange }) {
  const inputRef = useRef();
  function handleInputChange(e) {
    const inputColor = e.target.value;
    if (/^#[0-9A-F]{6}$/i.test(inputColor)) {
      onChange(inputColor);
    }
  }
  function handlePickerChange(color) {
    onChange(color);
    inputRef.current.value = color;
  }
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
      <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="mb-4">
          <label className="block text-gray-700 text-xs mb-1" htmlFor="username">
            6 Digit Hex Color
          </label>
          <input
            type="text"
            className="block w-full border-gray-300 rounded-md sm:text-sm sm:leading-5"
            defaultValue={value}
            onChange={handleInputChange}
            ref={(input) => { inputRef.current = input; }}
          />
        </div>
        <HexColorPicker color={value} onChange={handlePickerChange} />
      </div>
    </div>
  );
}

EditSiteEditorFormUIColorPicker.defaultProps = {
  value: '#5a67d8',
};

EditSiteEditorFormUIColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
