import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { mediaAssetsGetAssetsAPI } from '../api/media-assets';
import { useSite } from './SiteContext';

const MediaAssetsContext = React.createContext({
  assets: [],
  setAssets: () => {},
});

export function useMediaAssets() {
  return useContext(MediaAssetsContext);
}

/**
 *
 * @param {String} folderId folder type asset ID
 */
export function useFetchMediaAssets(folderId) {
  const { setAssets } = useContext(MediaAssetsContext);
  const { siteRef } = useSite();
  useEffect(() => {
    async function fetch() {
      const assets = await mediaAssetsGetAssetsAPI(siteRef, folderId);
      setAssets(assets);
    }
    fetch();
  }, [setAssets, siteRef, folderId]);
}

export function useAppendMediaAsset() {
  const { setAssets } = useContext(MediaAssetsContext);
  return function appendMediaAsset(asset) {
    setAssets((prevAssets) => [...prevAssets, asset]);
  };
}

export function useRemoveMediaAsset() {
  const { assets, setAssets } = useContext(MediaAssetsContext);
  return function removeMediaAsset(aid) {
    const values = [...assets].filter((asset) => asset.id !== aid);
    setAssets(values);
  };
}

export function useUpdateMediaAsset() {
  const { assets, setAssets } = useContext(MediaAssetsContext);
  return function updateMediaAsset(aid, key, value) {
    const values = [...assets];
    const index = values.map((asset) => asset.id).indexOf(aid);
    values[index][key] = value;
    setAssets(values);
  };
}

export function MediaAssetsProvider({ children }) {
  const [assets, setAssets] = useState([]);
  return (
    <MediaAssetsContext.Provider value={{ assets, setAssets }}>
      {children}
    </MediaAssetsContext.Provider>
  );
}

MediaAssetsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
