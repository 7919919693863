import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useEditSite } from '../../contexts/EditSiteContext';
import { useOrg } from '../../contexts/OrgContext';
import { useSetTemplateWithIdsAndArrayDict } from '../../contexts/TemplateContext';
import Template from '../Template/Template';

const EditSitePreview = () => {
  const { editSite } = useEditSite();
  const { user: { uid } } = useAuth();
  const { org: { id: oid } } = useOrg();
  const { sectionIds, sectionsDict } = editSite.pages.home.sections;
  const coveragePageEnabled = !!(editSite.pages.coverage && editSite.pages.coverage.enabled);
  const pressReleasesPageEnabled = !!(editSite.pages.pressReleases && editSite.pages.pressReleases.enabled);
  useSetTemplateWithIdsAndArrayDict(sectionIds, sectionsDict, editSite.meta, editSite.theme, uid, oid, editSite.id, coveragePageEnabled, pressReleasesPageEnabled);
  return (
    <Template disabled />
  );
};

export default EditSitePreview;
