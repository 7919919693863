import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EditSiteEditorFormUIInput from './EditSiteEditorFormUI/EditSiteEditorFormUIInput';
import EditSiteEditorFormUISubtitle from './EditSiteEditorFormUI/EditSiteEditorFormUISubtitle';
import EditSiteEditorFormUIImageUpload from './EditSiteEditorFormUI/EditSiteEditorFormUIImageUpload';
import { useEditSite } from '../../../../contexts/EditSiteContext';

const defaultButtonClasses = 'relative inline-flex items-center px-4 py-1 text-xs border border-gray-300 bg-white font-medium text-gray-700 hover:bg-gray-200 focus:outline-none';
const selectedClasses = 'bg-gray-100';

export const TEXT_KEY = 'text';
export const IMAGE_KEY = 'image';
export default function EditSiteEditorFormNavbarLogo({ logo, onLogoChange }) {
  function handleInputChange(key, value) {
    const newLogo = {
      ...logo,
      [key]: value,
    };
    onLogoChange(newLogo);
  }
  function handleImageChange(url) {
    handleInputChange(IMAGE_KEY, url);
  }
  const isTextSelected = logo.display === TEXT_KEY;
  const isImageSelected = logo.display === IMAGE_KEY;
  const { editSite: { id: siteId } } = useEditSite();

  return (
    <>
      <EditSiteEditorFormUISubtitle>Logo</EditSiteEditorFormUISubtitle>
      <div className="space-y-4">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center">
          <label className="block text-sm font-medium leading-5 text-gray-700">
            Display
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className={classNames(defaultButtonClasses, 'rounded-l-md', { [selectedClasses]: isTextSelected })}
                onClick={() => handleInputChange('display', TEXT_KEY)}
              >
                Text
              </button>
              <button
                type="button"
                className={classNames(defaultButtonClasses, '-ml-px rounded-r-md ', { [selectedClasses]: isImageSelected })}
                onClick={() => handleInputChange('display', IMAGE_KEY)}
              >
                Image
              </button>
            </span>
          </div>
        </div>
        {isTextSelected && (
        <EditSiteEditorFormUIInput
          label="Text"
          value={logo.text}
          onChange={({ target: { value } }) => { handleInputChange('text', value); }}
        />
        )}
        {isImageSelected && (
        <EditSiteEditorFormUIImageUpload
          label="Image"
          defaultValue={logo.image}
          onChange={handleImageChange}
          storageRoute={`sites/${siteId}/logos`}
        />
        )}
        <EditSiteEditorFormUIInput
          label="Link"
          value={logo.link}
          onChange={({ target: { value } }) => { handleInputChange('link', value); }}
        />
      </div>
    </>
  );
}

EditSiteEditorFormNavbarLogo.defaultProps = {
  logo: {
    text: 'Your Title',
    image: '',
    display: 'text',
    link: '',
  },
};

EditSiteEditorFormNavbarLogo.propTypes = {
  logo: PropTypes.object,
  onLogoChange: PropTypes.func.isRequired,
};
