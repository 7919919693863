import React, { useRef } from 'react';
import useGetPreviewScale from '../../hooks/useGetPreviewScale';
import PreviewFrame from '../PreviewFrame';

const CreateSitePreviewColumn = () => {
  const ref = useRef(null);
  const scale = useGetPreviewScale(ref);
  return (
    <div
      className="flex-1 shadow-xl border border-gray-200 rounded"
      style={{ height: 'calc(100vh - 1.5rem)', overflow: 'hidden' }}
    >
      <div ref={ref} style={{ height: '100%' }}>
        <PreviewFrame mode="create" scale={scale} />
      </div>
    </div>
  );
};

export default CreateSitePreviewColumn;
