import React, { useState } from 'react';
import { sitesSaveMetaAPI, sitesSaveThemeAPI } from '../../../api/sites';
import { useEditSite } from '../../../contexts/EditSiteContext';
import { useSite } from '../../../contexts/SiteContext';
import { FOOTER, PRESS_RELEASES_PREVIEW, COVERAGES_PREVIEW } from '../../../utils/templateTypes';
import EditSiteEditorAddSectionButton from './EditSiteEditorAddSectionButton';
import EditSiteEditorAddSectionModal from './EditSiteEditorAddSectionModal/EditSiteEditorAddSectionModal';
import EditSiteEditorCell from './EditSiteEditorCell';
import EditSiteEditorFormPageMeta from './EditSiteEditorForm/EditSiteEditorFormPageMeta';
import EditSiteEditorFormTheme from './EditSiteEditorForm/EditSiteEditorFormTheme';
import EditSiteEditorItem from './EditSiteEditorItem';

export default function EditSiteEditor() {
  const [metaChangeNotSave, setMetaChangeNotSave] = useState(false);
  const [themeChangeNotSave, setThemeChangeNotSave] = useState(false);
  const { editSite } = useEditSite();
  const { siteRef } = useSite();
  if (!editSite || !editSite.pages.home) {
    return null;
  }
  const page = editSite.pages.home;
  const { sectionIds, sectionsDict } = page.sections;
  const sections = sectionIds
    .map((sid) => sectionsDict[sid])
    .filter((section) => [FOOTER, PRESS_RELEASES_PREVIEW, COVERAGES_PREVIEW].indexOf(section.type) === -1);

  function handlePageMetaSave() {
    const { meta } = editSite;
    sitesSaveMetaAPI(siteRef, meta);
    setMetaChangeNotSave(false);
  }
  function handleThemeSave() {
    const { theme } = editSite;
    sitesSaveThemeAPI(siteRef, theme);
    setThemeChangeNotSave(false);
  }
  return (
    <div className="px-5" style={{ width: 650 }}>
      <div className="mb-5 space-y-5">
        <EditSiteEditorCell title="Page Meta" onSave={handlePageMetaSave} changeNotSave={metaChangeNotSave}>
          <EditSiteEditorFormPageMeta onInputChange={() => setMetaChangeNotSave(true)} />
        </EditSiteEditorCell>
        <EditSiteEditorCell title="Theme" onSave={handleThemeSave} changeNotSave={themeChangeNotSave}>
          <EditSiteEditorFormTheme onInputChange={() => setThemeChangeNotSave(true)} />
        </EditSiteEditorCell>
      </div>
      {sections.map((section, index) => (
        <React.Fragment key={section.id}>
          <EditSiteEditorItem section={section} />
          <EditSiteEditorAddSectionButton index={index + 1} />
        </React.Fragment>
      ))}
      <EditSiteEditorAddSectionModal />
    </div>
  );
}
