import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

const AssetContext = React.createContext({
  asset: {},
  setAsset: () => {},
});

export function useAsset() {
  return useContext(AssetContext);
}

export function AssetProvider({ children }) {
  const [asset, setAsset] = useState({});
  return (
    <AssetContext.Provider value={{ asset, setAsset }}>
      {children}
    </AssetContext.Provider>
  );
}

AssetProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
