import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { sitesGetSiteAPI } from '../api/sites';
import { useOrg } from './OrgContext';
import { refsGetSiteRef } from '../api/refs';

const SiteContext = React.createContext({
  site: null,
  setSite: () => {},
  siteRef: null,
  setSiteRef() {},
});

export function useSite() {
  return useContext(SiteContext);
}

/**
 *
 * @param {String} uid User ID
 * @param {String} sid Site ID
 */
export function useFetchSite(sid) {
  const { setSite, setSiteRef } = useContext(SiteContext);
  const { pathname } = useLocation();
  const { orgRef } = useOrg();
  useEffect(() => {
    const siteRef = refsGetSiteRef(orgRef, sid);
    setSiteRef(siteRef);
    async function fetch() {
      try {
        const site = await sitesGetSiteAPI(siteRef);
        setSite(site);
      } catch (e) {
        setSite(null);
      }
    }
    fetch();
  }, [sid, setSite, pathname, orgRef, setSiteRef]);
}

export function useUpdateSitePageWithKeyValue() {
  const { setSite } = useContext(SiteContext);
  return function updateSitePageWithKeyValue(page, key, value) {
    setSite((prevSite) => ({
      ...prevSite,
      pages: {
        ...prevSite.pages,
        [page]: {
          ...prevSite.pages[page],
          [key]: value,
        },
      },
    }));
  };
}

export function useUpdateSiteDomain() {
  const { setSite } = useContext(SiteContext);
  return function updateSiteDomain(domain) {
    setSite((prevSite) => ({
      ...prevSite,
      domain,
    }));
  };
}

export function SiteProvider({ children }) {
  const [site, setSite] = useState();
  const [siteRef, setSiteRef] = useState();
  return (
    <SiteContext.Provider value={{
      site, setSite, siteRef, setSiteRef,
    }}
    >
      {children}
    </SiteContext.Provider>
  );
}

SiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
